<template>
  <v-snackbar
    timeout="6000"
    dark
    right
    multi-line
    v-model="$store.state.alert.dialog"
  >
    <h3>{{ $store.state.alert.title }}</h3>
    <br />
    {{ $store.state.alert.msg }}
    <template v-slot:action="{ attrs }">
      <v-btn
        color="blue"
        text
        v-bind="attrs"
        @click="$store.commit('hideAlert')"
        >Close</v-btn
      >
    </template>
  </v-snackbar>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
