<template>
    <div>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-container>
                        <v-card-title>
                            {{ name }}
                        </v-card-title>
                        <v-card-subtitle>
                            [Id]<br/> {{ id }}
                            <v-divider></v-divider>
                            [Email]<br/> {{ email }}
                            <v-divider></v-divider>
                            [CompanyId]<br/> {{ company_id }}
                            <v-divider></v-divider>
                            [Status]<br/>
                            <v-data-table
                                :headers="headers"
                                :items="items"
                            ></v-data-table>
                            <v-divider></v-divider>
                            <v-row>
                                <v-col
                                    cols="10"
                                    offset="1"
                                >
                                    <vue-qr-reader
                                        v-on:code-scanned="codeArrived"
                                        :responsive="true"
                                    />
                                </v-col>
                            </v-row>
                        </v-card-subtitle>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import VueQrReader from 'vue-qr-reader/dist/lib/vue-qr-reader.umd.js';

export default {
    components: {
        VueQrReader
    },
    data() {
        return {
            overlay: false,
            loading: false,
            qr_code: null,
            id: null,
            name: null,
            email: null,
            company_id: null,
            headers: [
                {
                    text: "Name",
                    value: "employee_code",
                    sortable: false,
                },
                {
                    text: "Date",
                    value: "date",
                    sortable: false,
                },
                {
                    text: "In",
                    value: "in",
                    sortable: false,
                },
                {
                    text: "Out",
                    value: "out",
                    sortable: false,
                },
            ],
            items: [],
        }
    },
    mounted() {
        if( this.$store.state.user.role !== 'swipe' ) {
            alert('You have no access right to this page...');
            this.$router.push('/transactions');
        }

        // console.log(moment().format('YYYY-MM-DD'));

         this.id = this.$store.state.currentUser.id;
        this.name = this.$store.state.currentUser.name;
        this.email = this.$store.state.currentUser.email;
        this.company_id = this.$store.state.currentUser.company_id;
        this.qr_code = null;
        this.getLatestAttendanceData();

    },
    methods: {
        codeArrived: function(value) {
            this.qr_code = value;
            if( this.qr_code ) {
                this.getCurrentUser();
            }
        },
        getCurrentUser: function() {
            this.overlay = true;
            this.loading = true;
            axios.post(
                '/api/get_current_user',
                {
                    id: JSON.parse(this.qr_code).employee_code,
                }
            ).then(response => {
                // console.log(response.data);
                this.$store.commit('assignCurrentUserInfo', response.data);
                this.id = this.$store.state.currentUser.id;
                this.name = this.$store.state.currentUser.name;
                this.email = this.$store.state.currentUser.email;
                this.company_id = this.$store.state.currentUser.company_id;
                this.getLatestAttendanceData();
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },
        getLatestAttendanceData: function() {
            this.overlay = true;
            this.loading = true;
            axios.post(
                '/api/get_latest_attendance_data',
                {
                    id: this.id,
                }
            ).then(response => {
                // console.log(response.data);
                this.items = [];
                if( response.data && (moment().format('YYYY-MM-DD') == response.data.date) ) {
                    this.items.push(response.data);
                    // console.log(moment().diff(moment(this.items[0].in)) / ( 1000 ));
                    if( this.qr_code ) {
                        if( !this.items[0].out ) {
                        // if(moment().diff(moment(this.items[0].in)) / 1000 < 60 ) {
                            // this.$router.go({path: '/swipe_screen', force: true});
                        // } else {
                            this.updateOut(this.items[0]);
                        // }
                        } else {
                            this.$router.go({path: '/swipe_screen', force: true});
                        }
                    }
                } else {
                    // TimeIn
                    this.insertNewTimeInRecord();
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
                this.qr_code = null;
            });
        },
        updateOut: function(item) {
            if(!confirm('Is it ok to update of Out　?')) {
                this.$router.go({path: '/swipe_screen', force: true});
                return false;
            }
            this.overlay = true;
            this.loading = true;
            this.axios.post(
                '/api/update_out',
                {
                    id: item.id,
                }
            ).then(response => {
                    // console.log(response.data);
                    if(response.status === 200) {
                        this.getLatestAttendanceData();
                    }
                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                    this.overlay = false;
                    this.loading = false;
                    this.$router.go({path: '/swipe_screen', force: true});
                });
        },
        insertNewTimeInRecord: function() {
            this.overlay = true;
            this.loading = true;
            this.axios.post(
                '/api/insert_new_time_in_record',
                {
                    employee_code: JSON.parse(this.qr_code).employee_code,
                }
            ).then(response => {
                    if(response.status === 200) {
                        this.items = response.data;
                    }
                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                    this.overlay = false;
                    this.loading = false;
                    this.$router.go({path: '/swipe_screen', force: true});
                });
        },
    },
}
</script>
