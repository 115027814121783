<template>
    <v-container>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-card class="mx-auto px-2 pt-2">
            <!-- <v-btn @click="test">test</v-btn> -->
            <v-toolbar flat dark class="indigo lighten-3">
                <v-toolbar-title>New Attendance Data *For Testing</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <div class="text-center pt-2">
                    <v-pagination
                        v-model="page"
                        :length="pageCount"
                        total-visible="10"
                        dark
                        @input="changePage()"
                    ></v-pagination>
                </div>   

                <v-data-table
                    :headers="headers"
                    :items="newAttendanceData"
                    :loading="loading"
                    :search="search"
                    hide-default-footer
                >
                    <template v-slot:top>                     
                        <v-text-field
                            outlined
                            dense
                            prepend-inner-icon="mdi-magnify"
                            label="Search by text..."
                            v-model="search"
                        ></v-text-field>
                        <!-- <v-btn
                            block
                            dark
                            color="green"
                            @click="generateAttendanceDataExcel()"
                            class="my-2"
                        >
                            <v-icon>mdi-microsoft-excel</v-icon>
                            Generate Report
                        </v-btn>                         -->
                    </template>
                    <template v-slot:[`item.day`]="{ item }">
                        {{ item.in | getDay }}
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-icon
                            color="orange"
                            @click="openEditRecordDialog(item)"
                        >
                            mdi-pencil
                        </v-icon>
                        <v-icon
                            color="red"
                            @click="deleteRecordForNewAttendanceData(item)"
                        >
                            mdi-delete
                        </v-icon>
                    </template>
                </v-data-table>
                <div class="text-center pt-2">
                    <v-pagination
                        v-model="page"
                        :length="pageCount"
                        total-visible="10"
                        dark
                        @input="changePage()"
                    ></v-pagination>
                </div>
            </v-card-text>
        </v-card>
        <!--editRecordDialog start-->        
        <v-dialog v-model="editRecordDialog" fullscreen>
            <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
            <v-card flat>
                <v-container>
                    <v-toolbar flat dark class="indigo lighten-3">
                        <v-toolbar-title
                        >{{ editRecordDialogTitle }}</v-toolbar-title
                        >
                        <v-spacer></v-spacer>
                        <v-btn
                            icon
                            dark
                            @click="editRecordDialog = false"
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <v-menu
                                    v-model="menu0"
                                    :close-on-content-click="false"
                                    max-width="290"
                                >
                                    <template v-slot:activator="{ on, attrs }">                                  
                                        <v-text-field
                                            dense
                                            outlined
                                            label="Date"
                                            placeholder="Date"
                                            v-model="date"
                                            v-on="on"
                                            prepend-icon="mdi-calendar"
                                        ></v-text-field>   
                                    </template>
                                    <v-date-picker
                                        v-model="date"
                                        @change="menu0 = false"
                                    ></v-date-picker>
                                </v-menu>                                                             
                            </v-col>
                        </v-row>
                        <v-row>                   
                            <v-col cols="3">
                                <v-menu
                                    v-model="menu1"
                                    :close-on-content-click="false"
                                    max-width="290"
                                >
                                    <template v-slot:activator="{ on, attrs }">                                
                                        <v-text-field
                                            dense
                                            outlined
                                            label="In(Date)"
                                            placeholder="In(Date)"
                                            v-model="in_date"
                                            prepend-icon="mdi-calendar"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="in_date"
                                        @change="menu1 = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="3">
                                <v-menu
                                    v-model="menu2"
                                    :close-on-content-click="false"
                                    max-width="290"
                                >
                                    <template v-slot:activator="{ on, attrs }">                                
                                        <v-text-field
                                            dense
                                            outlined
                                            label="In(Time)"
                                            placeholder="In(Time)"
                                            v-model="in_time"
                                            prepend-icon="mdi-clock-time-eight-outline"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-time-picker
                                        v-model="in_time"
                                        use-seconds
                                        @change="menu2 = false"
                                    ></v-time-picker>
                                </v-menu>
                            </v-col>                                      
                            <v-col cols="3">
                                <v-menu
                                    v-model="menu3"
                                    :close-on-content-click="false"
                                    max-width="290"
                                >
                                    <template v-slot:activator="{ on, attrs }">                                
                                        <v-text-field
                                            dense
                                            outlined
                                            label="Out(Date)"
                                            placeholder="Out(Date)"
                                            v-model="out_date"
                                            prepend-icon="mdi-calendar"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="out_date"
                                        @change="menu3 = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="3">
                                <v-menu
                                    v-model="menu4"
                                    :close-on-content-click="false"
                                    max-width="290"
                                >
                                    <template v-slot:activator="{ on, attrs }">                                
                                        <v-text-field
                                            dense
                                            outlined
                                            label="Out(Time)"
                                            placeholder="Out(Time)"
                                            v-model="out_time"
                                            prepend-icon="mdi-clock-time-eight-outline"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-time-picker
                                        v-model="out_time"
                                        use-seconds
                                        @change="menu4 = false"
                                    ></v-time-picker>
                                </v-menu>
                            </v-col>                              
                        </v-row>                                                                                                
                        <v-row>                   
                            <v-col cols="3">
                                <v-menu
                                    v-model="menu5"
                                    :close-on-content-click="false"
                                    max-width="290"
                                >
                                    <template v-slot:activator="{ on, attrs }">                                
                                        <v-text-field
                                            dense
                                            outlined
                                            label="In2(Date)"
                                            placeholder="In2(Date)"
                                            v-model="in_date2"
                                            prepend-icon="mdi-calendar"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="in_date2"
                                        @change="menu5 = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="3">
                                <v-menu
                                    v-model="menu6"
                                    :close-on-content-click="false"
                                    max-width="290"
                                >
                                    <template v-slot:activator="{ on, attrs }">                                
                                        <v-text-field
                                            dense
                                            outlined
                                            label="In2(Time)"
                                            placeholder="In2(Time)"
                                            v-model="in_time2"
                                            prepend-icon="mdi-clock-time-eight-outline"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-time-picker
                                        v-model="in_time2"
                                        use-seconds
                                        @change="menu6 = false"
                                    ></v-time-picker>
                                </v-menu>
                            </v-col>                                      
                            <v-col cols="3">
                                <v-menu
                                    v-model="menu7"
                                    :close-on-content-click="false"
                                    max-width="290"
                                >
                                    <template v-slot:activator="{ on, attrs }">                                
                                        <v-text-field
                                            dense
                                            outlined
                                            label="Out2(Date)"
                                            placeholder="Out2(Date)"
                                            v-model="out_date2"
                                            prepend-icon="mdi-calendar"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="out_date2"
                                        @change="menu7 = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="3">
                                <v-menu
                                    v-model="menu8"
                                    :close-on-content-click="false"
                                    max-width="290"
                                >
                                    <template v-slot:activator="{ on, attrs }">                                
                                        <v-text-field
                                            dense
                                            outlined
                                            label="Out2(Time)"
                                            placeholder="Out2(Time)"
                                            v-model="out_time2"
                                            prepend-icon="mdi-clock-time-eight-outline"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-time-picker
                                        v-model="out_time2"
                                        use-seconds
                                        @change="menu8 = false"
                                    ></v-time-picker>
                                </v-menu>
                            </v-col>                              
                        </v-row>                                                                                                
                    </v-card-text>
                    <v-card-actions>
                        <v-btn 
                            block 
                            color="red" 
                            class="lighten-2"
                            dark
                            @click="updateNewAttendanceData()"
                        >
                        <v-icon>mdi-update</v-icon>
                        Update</v-btn>
                    </v-card-actions>
                </v-container>
            </v-card>
        </v-dialog>
        <!--editRecordDialog end-->        
    </v-container>
</template>
<script>
export default {
    data() {
        return {
            overlay: false,
            date: null,
            in_date: null,
            in_time: null,
            out_date: null,
            out_time: null,
            in_date2: null,
            in_time2: null,
            out_date2: null,
            out_time2: null,
            menu0: false,
            menu1: false,
            menu2: false,
            menu3: false,
            menu4: false,
            menu5: false,
            menu6: false,
            menu7: false,
            menu8: false,
            currentId: null,
            headers: [
                {
                    text: "Id",
                    value: "id",
                    sortable: false,
                },
                {
                    text: "Date",
                    value: "date",
                    sortable: false,
                },
                {
                    text: "Day",
                    value: "day",
                    sortable: false,
                },
                {
                    text: "UserName",
                    value: "user.name",
                    sortable: false,
                },
                {
                    text: "In",
                    value: "in",
                    sortable: false,
                },
                {
                    text: "Out",
                    value: "out",
                    sortable: false,
                },
                {
                    text: "In2",
                    value: "in2",
                    sortable: false,
                },
                {
                    text: "Out2",
                    value: "out2",
                    sortable: false,
                },
                {
                    text: "Hrs Work",
                    value: "hrs_work",
                    sortable: false,
                },
                {
                    text: "Actions",
                    value: "actions",
                    sortable: false,
                },
            ],
            editRecordDialog: false,
            newAttendanceData: [],
            loading: false,
            search: null,
            page: 1,
            pageCount: 0,
            itemsPerPage: 5,
            totalNumberOfRecords: null,
            editRecordDialogTitle: null,
        }
    },
    mounted() {
        if( this.$store.state.user.role !== 'su' && this.$store.state.user.role !== 'hrsu' && this.$store.state.user.role !== 'hrassistant' ) {
            alert('You have no access right to this page...');
            this.$router.push('/transactions');
        }
        this.loadNewAttendanceData();
    },
    methods: {
        loadNewAttendanceData: function() {
            this.overlay = true;
            this.loading = true;
            this.axios.post(
                '/api/get_new_attendance_data',
                {
                    user: this.$store.state.user,
                    page: this.page,
                    itemsPerPage: this.itemsPerPage,
                    search: this.search,
                }
            ).then(response => {
                    // console.log(response.data);
                    if(response.status === 200) {
                        this.newAttendanceData = response.data.data;
                        this.totalNumberOfRecords = response.data.total;
                        this.pageCount = Math.ceil(response.data.total / this.itemsPerPage);
                    } else {
                        this.$store.commit('logOut');
                    }

                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                    this.overlay = false;
                    this.loading = false;
                });
        },
        changePage: function() {
            this.loadNewAttendanceData();
        },
        insertNewTimeInRecordForNewAttendanceData: function() {
            this.overlay = true;
            this.loading = true;
            this.axios.post(
                '/api/insert_new_time_in_record_for_new_attendance_data',
                {
                    employee_code: this.$store.state.user.id,
                }
            ).then(response => {
                    // console.log(response.data);
                    if(response.status === 200) {
                        this.loadNewAttendanceData();
                    } else {

                    }

                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                    this.overlay = false;
                    this.loading = false;
                });
        },
        deleteRecordForNewAttendanceData: function(item) {
            if(!confirm('Is it ok to delete the record: ' + item.id + '?')) {
                return false;
            }
            this.overlay = true;
            this.loading = true;
            this.axios.post(
                '/api/delete_record_for_new_attendance_data',
                {
                    id: item.id,
                }
            ).then(response => {
                    console.log(response.data);
                    if(response.status === 200) {
                        this.loadAttendanceData();
                    } else {

                    }

                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                    this.overlay = false;
                    this.loading = false;
                });            
        },
        generateAttendanceDataExcel: function() {
            axios.post(
                '/api/generate_attendance_data_excel',
                {
                }
            ).then(response => {
                // console.log(response.data['file'])
                // console.log(this.dates)
                // return false;
                this.downloading = false;
                this.overlay = false;
                const fileLink = document.createElement("a");
                fileLink.href = response.data['file'];
                fileLink.download = 'AttendanceData' + moment().format('YYYYMMDD') + '.xlsx';
                fileLink.target = "_blank";
                document.body.appendChild(fileLink);
                fileLink.click();
                document.body.removeChild(fileLink);
            }).catch(error => {
                console.log(error);
            })
        },
        getDate: function(value) {
            if(value) {
                return value.substring(0, 10);
            }
        },
        openEditRecordDialog: function(item) {
            this.editRecordDialog = true;
            this.editRecordDialogTitle = 'Edit Record: ' + item.id; 
            this.currentId = item.id;
            this.date = item.date;
            this.in_date = item.in ? item.in.substring(0, 10): null;
            this.in_time = item.in ? item.in.substring(11, 19): null;
            this.out_date = item.out ? item.out.substring(0, 10): null;
            this.out_time = item.out ? item.out.substring(11, 19): null;
            this.in_date2 = item.in2 ? item.in2.substring(0, 10): null;
            this.in_time2 = item.in2 ? item.in2.substring(11, 19): null;
            this.out_date2 = item.out2 ? item.out2.substring(0, 10): null;
            this.out_time2 = item.out2 ? item.out2.substring(11, 19): null;
        },
        updateNewAttendanceData: function() {
            this.overlay = true;
            this.loading = true;
            this.axios.post(
                '/api/update_new_attendance_data',
                {
                    id: this.currentId,
                    date: this.date,
                    in_date: this.in_date,
                    in_time: this.in_time,
                    out_date: this.out_date,
                    out_time: this.out_time,
                    in_date2: this.in_date2,
                    in_time2: this.in_time2,
                    out_date2: this.out_date2,
                    out_time2: this.out_time2,
                }
            ).then(response => {
                    // console.log(response.data);
                    this.editRecordDialog = false;
                    this.loadNewAttendanceData();
                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                    this.overlay = false;
                    this.loading = false;
                });             
        }
    },
    filters: {
        getDay: function(value) {
            const date = new Date(value);
            // console.log(date)
            const dayOfWeek = date.getDay() * 1;
            return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][dayOfWeek];
        }
    }
}
</script>
