<template>
    <v-container>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-card class="mx-auto px-2 pt-2">
            <v-toolbar flat dark class="indigo lighten-3">
                <v-toolbar-title>Payroll</v-toolbar-title>
            </v-toolbar>
            <v-card-text>  
                <!--Data Picker start-->
                <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-radio-group
                            v-model="radios"
                        >
                            <v-radio label="Raw Data" value="rawData"></v-radio>
                            <v-radio label="Aggregate Results" value="aggregateResults"></v-radio>
                        </v-radio-group>
                        <v-text-field
                            v-if="$store.state.user.role == 'su' || $store.state.user.role == 'surveyor' || $store.state.user.role == 'admin'"
                            v-model="dateRangeText"
                            label="Date Rnage"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            @click="dates = []"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="dates"
                        range
                    ></v-date-picker>
                </v-menu>
                <!--Data Picker finish-->                           
                <v-btn
                    block
                    dark
                    color="green"
                    @click="generatePayslipExcel()"
                    class="my-2"
                >
                    <v-icon>mdi-microsoft-excel</v-icon>
                    Generate Payslip
                </v-btn>               
                <v-tabs
                    v-model="tab"
                    center-active
                    dark
                >
                    <v-tabs-slider color="yellow"></v-tabs-slider>
                    <v-tab
                        v-for="(remark, i) in remarks"
                        :key="i"
                    >
                        {{remark}}
                    </v-tab>
                </v-tabs>          
                <!-- Child Comoponents start -->           
                <summary-component 
                    v-if="tab == 0"
                    v-bind="{
                        items, 
                        headers, 
                        loading, 
                        search, 
                        itemsPerPage, 
                        totalNumberOfRecords,
                        pageCount,
                        page,
                    }">
                </summary-component>       
                <management-component 
                    v-if="tab == 1"
                    v-bind="{
                        items, 
                        headers, 
                        loading, 
                        search, 
                        itemsPerPage, 
                        totalNumberOfRecords,
                        pageCount,
                        page,
                    }">
                </management-component>       
                <supervisors-component 
                    v-if="tab == 2"
                    v-bind="{
                        items, 
                        headers, 
                        loading, 
                        search, 
                        itemsPerPage, 
                        totalNumberOfRecords,
                        pageCount,
                        page,
                    }">
                </supervisors-component>       
                <rank-and-file-component 
                    v-if="tab == 3"
                    v-bind="{
                        items, 
                        headers, 
                        loading, 
                        search, 
                        itemsPerPage, 
                        totalNumberOfRecords,
                        pageCount,
                        page,
                    }">
                </rank-and-file-component>   
                <new-hires-component 
                    v-if="tab == 4"
                    v-bind="{
                        items, 
                        headers, 
                        loading, 
                        search, 
                        itemsPerPage, 
                        totalNumberOfRecords,
                        pageCount,
                        page,
                    }">
                </new-hires-component>   
                <consultant-component 
                    v-if="tab == 5"
                    v-bind="{
                        items, 
                        headers, 
                        loading, 
                        search, 
                        itemsPerPage, 
                        totalNumberOfRecords,
                        pageCount,
                        page,
                    }">
                </consultant-component>   
                <bilt-component 
                    v-if="tab == 6"
                    v-bind="{
                        items, 
                        headers, 
                        loading, 
                        search, 
                        itemsPerPage, 
                        totalNumberOfRecords,
                        pageCount,
                        page,
                    }">
                </bilt-component>   
                <ojt-component 
                    v-if="tab == 7"
                    v-bind="{
                        items, 
                        headers, 
                        loading, 
                        search, 
                        itemsPerPage, 
                        totalNumberOfRecords,
                        pageCount,
                        page,
                    }">
                </ojt-component>   
                <payslip-component 
                    v-if="tab == 8"
                    v-bind="{
                        items, 
                        headers, 
                        loading, 
                        search, 
                        itemsPerPage, 
                        totalNumberOfRecords,
                        pageCount,
                        page,
                    }">
                </payslip-component>   
                <!-- Child Components end -->    
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
import SummaryComponent from './PayrollComponents/Summary.vue';
import ManagementComponent from './PayrollComponents/Management.vue';
import SupervisorsComponent from './PayrollComponents/Supervisors.vue';
import RankAndFileComponent from './PayrollComponents/RankAndFile.vue';
import NewHiresComponent from './PayrollComponents/NewHires.vue';
import ConsultantComponent from './PayrollComponents/Consultant.vue';
import BILTComponent from './PayrollComponents/BILT.vue';
import OJTComponent from './PayrollComponents/OJT.vue';
import PayslipComponent from './PayrollComponents/Payslip.vue';

export default {
    components: {
        'summary-component': SummaryComponent,
        'management-component': ManagementComponent,
        'supervisors-component': SupervisorsComponent,
        'rank-and-file-component': RankAndFileComponent,
        'new-hires-component': NewHiresComponent,
        'consultant-component': ConsultantComponent,
        'bilt-component': BILTComponent,
        'ojt-component': OJTComponent,
        'payslip-component': PayslipComponent,
    },
    data() {
        return {
            overlay: false,
            loading: false,
            radios: 'rawData',
            page: 1,
            pageCount: 0,
            itemsPerPage: 3000,
            search: null,
            totalNumberOfRecords: null,
            menu: false,
            tab: 0,
            remarks: [
                'Summary',
                'Management',
                'Supervisors',
                'Rank&File',
                'NewHires',
                'Consultant',
                'BILT',
                'OJT',
                'Payslip',
            ],
            dates: [
                `${moment().format('YYYY')}-${moment().format('MM')}-01`,
                `${moment().format('YYYY')}-${moment().format('MM')}-${moment().daysInMonth()}`
                // '2024-09-01', '2024-09-30'
            ],            
            items: [],
            headers: [],
        }
    },
    mounted() {
        if( this.$store.state.user.role !== 'su' && this.$store.state.user.role !== 'hrsu' && this.$store.state.user.role !== 'hrassistant' ) {
            alert('You have no access right to this page...');
            this.$router.push('/transactions');
        }    
        this.loadAttendanceData(this.tab, this.radios);
    },
    methods: {
        loadAttendanceData: function(tab, radios) {
            if( this.radios == 'rawData' ) {
                this.headers = 
                [
                    {
                        text: "EmployeeCode",
                        value: "employee_code",
                        sortable: false,
                    },
                    {
                        text: "EmployeeName",
                        value: "user.name",
                        sortable: false,
                    },
                    {
                        text: "Position",
                        value: "user_for_payroll.position",
                        sortable: false,
                    },
                    {
                        text: "Date",
                        value: "date",
                        sortable: false,
                    },
                    {
                        text: "In",
                        value: "in",
                        sortable: false,
                    },
                    {
                        text: "Out",
                        value: "out",
                        sortable: false,
                    },
                    {
                        text: "Interval",
                        value: "interval",
                        sortable: true, 
                        sort: (a, b) => {
                            if( a && b ) {
                                a = a.replace(/[^0-9]/g, '');
                                b = b.replace(/[^0-9]/g, '');
                                return a-b;
                            } else {
                                return '';
                            }
                        }                
                    },
                    {
                        text: "Remark",
                        value: "remark",
                        sortable: false,
                    },
                ];                
            } else {
                switch(this.tab) {
                    case 1: // Management
                        this.headers = [
                            {
                                text: "EmployeeCode",
                                value: "employee_code",
                                sortable: false,
                            }, 
                            {
                                text: "EmployeeName",
                                value: "user_name",
                                sortable: false,
                            },
                            {
                                text: "Position",
                                value: "position",
                                sortable: false,
                            },
                            {
                                text: "DateHired",
                                value: "date_hired",
                                sortable: false,
                            },
                            {
                                text: "Rate/Mo.",
                                value: "rate",
                                sortable: false,
                            },
                            {
                                text: "Attendance",
                                value: "attendance",
                                sortable: false,
                            },
                            {
                                text: "Gross Pay",
                                value: "gross_pay",
                                sortable: false,
                            },
                            {
                                text: "Deductions",
                                value: "deductions",
                                sortable: false,
                            },
                            {
                                text: "Total Deductions",
                                value: "total_deductions",
                                sortable: false,
                            },
                            {
                                text: "Advances",
                                value: "advances",
                                sortable: false,
                            },
                            {
                                text: "Load Allowance",
                                value: "load_allowance",
                                sortable: false,
                            },
                            {
                                text: "Food Allow",
                                value: "food_allow",
                                sortable: false,
                            },          
                            {
                                text: "Net Pay",
                                value: "net_pay",
                                sortable: false,
                            },          
                        ];                        
                        break;    
                    case 2: // Supervisors
                        this.headers = [
                            {
                                text: "EmployeeCode",
                                value: "employee_code",
                                sortable: false,
                            }, 
                            {
                                text: "EmployeeName",
                                value: "user_name",
                                sortable: false,
                            },
                            {
                                text: "Position",
                                value: "position",
                                sortable: false,
                            },
                            {
                                text: "DateHired",
                                value: "date_hired",
                                sortable: false,
                            },
                            {
                                text: "Rate/Mo.",
                                value: "rate",
                                sortable: false,
                            },
                            {
                                text: "Attendance",
                                value: "attendance",
                                sortable: false,
                            },
                            {
                                text: "Gross Pay",
                                value: "gross_pay",
                                sortable: false,
                            },
                            {
                                text: "Deductions",
                                value: "deductions",
                                sortable: false,
                            },
                            {
                                text: "Total Deductions",
                                value: "total_deductions",
                                sortable: false,
                            },
                            {
                                text: "Adjustment",
                                value: "adjustment",
                                sortable: false,
                            },
                            {
                                text: "Advances",
                                value: "advances",
                                sortable: false,
                            },
                            {
                                text: "Net Pay",
                                value: "net_pay",
                                sortable: false,
                            },       
                        ];                        
                        break;
                    case 3: // Rank&File
                        this.headers = [
                            {
                                text: "EmployeeCode",
                                value: "employee_code",
                                sortable: false,
                            }, 
                            {
                                text: "EmployeeName",
                                value: "user_name",
                                sortable: false,
                            },
                            {
                                text: "Position",
                                value: "position",
                                sortable: false,
                            },
                            {
                                text: "DateHired",
                                value: "date_hired",
                                sortable: false,
                            },
                            {
                                text: "Rate/Mo.",
                                value: "rate",
                                sortable: false,
                            },
                            {
                                text: "Attendance",
                                value: "attendance",
                                sortable: false,
                            },
                            {
                                text: "Gross Pay",
                                value: "gross_pay",
                                sortable: false,
                            },
                            {
                                text: "Deductions",
                                value: "deductions",
                                sortable: false,
                            },
                            {
                                text: "Total Deductions",
                                value: "total_deductions",
                                sortable: false,
                            },
                            {
                                text: "Advances",
                                value: "advances",
                                sortable: false,
                            },
                            {
                                text: "Net Pay",
                                value: "net_pay",
                                sortable: false,
                            },
                            {
                                text: "Remarks",
                                value: "remarks",
                                sortable: false,
                            },           
                        ];
                        break;
                    case 4: // NewHires
                        this.headers = [
                            {
                                text: "EmployeeCode",
                                value: "employee_code",
                                sortable: false,
                            }, 
                            {
                                text: "EmployeeName",
                                value: "user_name",
                                sortable: false,
                            },
                            {
                                text: "Position",
                                value: "position",
                                sortable: false,
                            },
                            {
                                text: "DateHired",
                                value: "date_hired",
                                sortable: false,
                            },
                            {
                                text: "Rate/Mo.",
                                value: "rate",
                                sortable: false,
                            },
                            {
                                text: "Attendance",
                                value: "attendance",
                                sortable: false,
                            },
                            {
                                text: "Gross Pay",
                                value: "gross_pay",
                                sortable: false,
                            },
                            {
                                text: "Deductions",
                                value: "deductions",
                                sortable: false,
                            },
                            {
                                text: "Total Deductions",
                                value: "total_deductions",
                                sortable: false,
                            },
                            {
                                text: "Advances",
                                value: "advances",
                                sortable: false,
                            },
                            {
                                text: "Food Allowance",
                                value: "food_allowance",
                                sortable: false,
                            },
                            {
                                text: "Net Pay",
                                value: "net_pay",
                                sortable: false,
                            },
                            {
                                text: "Remarks",
                                value: "remarks",
                                sortable: false,
                            },           
                        ];                        
                        break;
                    case 5: // Consultant
                        this.headers = [
                            {
                                text: "EmployeeCode",
                                value: "employee_code",
                                sortable: false,
                            }, 
                            {
                                text: "EmployeeName",
                                value: "user_name",
                                sortable: false,
                            },
                            {
                                text: "Position",
                                value: "position",
                                sortable: false,
                            },
                            {
                                text: "DateHired",
                                value: "date_hired",
                                sortable: false,
                            },
                            {
                                text: "Rate/Mo.",
                                value: "rate",
                                sortable: false,
                            },
                            {
                                text: "Attendance",
                                value: "attendance",
                                sortable: false,
                            },
                            {
                                text: "Gross Pay",
                                value: "gross_pay",
                                sortable: false,
                            },
                            {
                                text: "Deductions",
                                value: "deductions",
                                sortable: false,
                            },
                            {
                                text: "Total Deductions",
                                value: "total_deductions",
                                sortable: false,
                            },
                            {
                                text: "Food",
                                value: "food",
                                sortable: false,
                            },
                            {
                                text: "Net Pay",
                                value: "net_pay",
                                sortable: false,
                            },         
                        ];                        
                        break;
                    case 6: // BILT
                        this.headers = [
                            {
                                text: "EmployeeCode",
                                value: "employee_code",
                                sortable: false,
                            }, 
                            {
                                text: "EmployeeName",
                                value: "user_name",
                                sortable: false,
                            },
                            {
                                text: "Position",
                                value: "position",
                                sortable: false,
                            },
                            {
                                text: "DateHired",
                                value: "date_hired",
                                sortable: false,
                            },
                            {
                                text: "Rate/Mo.",
                                value: "rate",
                                sortable: false,
                            },
                            {
                                text: "Attendance",
                                value: "attendance",
                                sortable: false,
                            },
                            {
                                text: "Gross Pay",
                                value: "gross_pay",
                                sortable: false,
                            },
                            {
                                text: "Deductions",
                                value: "deductions",
                                sortable: false,
                            },
                            {
                                text: "Total Deductions",
                                value: "total_deductions",
                                sortable: false,
                            },
                            {
                                text: "Advances",
                                value: "advances",
                                sortable: false,
                            },
                            {
                                text: "Net Pay",
                                value: "net_pay",
                                sortable: false,
                            },
                            {
                                text: "Remarks",
                                value: "remarks",
                                sortable: false,
                            },           
                        ];                        
                        break;
                    case 7: // OJT
                        this.headers = [
                            {
                                text: "EmployeeCode",
                                value: "employee_code",
                                sortable: false,
                            }, 
                            {
                                text: "EmployeeName",
                                value: "user_name",
                                sortable: false,
                            },
                            {
                                text: "Position",
                                value: "position",
                                sortable: false,
                            },
                            {
                                text: "DateHired",
                                value: "date_hired",
                                sortable: false,
                            },
                            {
                                text: "Rate/Mo.",
                                value: "rate",
                                sortable: false,
                            },
                            {
                                text: "Attendance",
                                value: "attendance",
                                sortable: false,
                            },
                            {
                                text: "Gross Pay",
                                value: "gross_pay",
                                sortable: false,
                            },
                            {
                                text: "Deductions",
                                value: "deductions",
                                sortable: false,
                            },
                            {
                                text: "Total Deductions",
                                value: "total_deductions",
                                sortable: false,
                            },
                            {
                                text: "Advances",
                                value: "advances",
                                sortable: false,
                            },
                            {
                                text: "Net Pay",
                                value: "net_pay",
                                sortable: false,
                            },
                            {
                                text: "Remarks",
                                value: "remarks",
                                sortable: false,
                            },           
                        ];                        
                        break;                                      
                }
            }
            this.overlay = true;
            this.loading = true;
            // console.log(this.dates)
            axios.post(
                '/api/load_attendance_data',
                {
                    user: this.$store.state.user,
                    page: this.page,
                    itemsPerPage: this.itemsPerPage,
                    search: this.search,
                    dates: this.dates,
                    tab: tab,
                    radios: radios,
                }                
            ).then(response => {
                console.log(response.data);
                if(response.status === 200) {
                        this.items = response.data.data;
                        this.totalNumberOfRecords = response.data.total;
                        this.pageCount = Math.ceil(response.data.total / this.itemsPerPage);
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });            
        },
        changePage: function() {
            this.loadAttendanceData(this.tab, this.radios);
        },
        generatePayslipExcel: function() {
            this.overlay = true;
            this.loading = true;
            axios.post(
                '/api/generate_payslip_excel',
                {
                    items: this.items,
                    dates: this.dates,
                }
            ).then(response => {
                // console.log(response.data);
                const fileLink = document.createElement("a");
                fileLink.href = response.data['file'];
                fileLink.download = 'Payslip' + moment().format('YYYYMMDD') + '.xlsx';
                fileLink.target = "_blank";
                document.body.appendChild(fileLink);
                fileLink.click();
                document.body.removeChild(fileLink);                    
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;                    
            });
        },
    },
    computed: {
        dateRangeText() {
            return this.dates.join(' ~ ');
        }
    },    
    watch: {
        dates: function() {
            if (this.dates.length == 0 && this.dates.length == 1) {
                return false;
            } else if (this.dates.length == 2) {
                if ( this.dates[0] > this.dates[1] ) {
                    alert('Invalid date selection');
                    this.dates = [];
                    return false;
                } else {
                    this.page = 1;
                    this.loadAttendanceData(this.tab, this.radios);
                }
            }
        },
        tab: function() {
            this.loadAttendanceData(this.tab, this.radios);
        },
        radios: function() {
            this.loadAttendanceData(this.tab, this.radios);
        }
    },    
}
</script>