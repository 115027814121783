<template>
  <v-container fluid>
      <v-overlay :value="overlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-card>
          <v-toolbar flat dark class="indigo lighten-3">
              <v-toolbar-title>Users for Payroll</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
              <v-data-table
                  :headers="headers"
                  :items="users_for_payroll"
                  :loading="loading"
                  :search="search"
                  :items-per-page="50"
                  fixed-header
                  height="50vh"
                  hide-default-footer
                  :page.sync="page"
                  @page-count="pageCount = $event"
              >
                  <template v-slot:top>
                      <v-text-field
                          class="my-2"
                          prepend-inner-icon="mdi-magnify"
                          v-model="search"
                          outlined
                          dense
                      ></v-text-field>
                      <v-btn block color="red" class="lighten-2" dark @click="addNewUser()"><v-icon>mdi-plus</v-icon> Add a new user</v-btn>
                      # of records: <span style="color: red; font-weight: bold;">{{users_for_payroll.length}}</span>
                  </template>
                  <template v-slot:[`item.created_at`]="{ item }">
                    {{ item.created_at | formatDate }}
                  </template>
                  <template v-slot:[`item.updated_at`]="{ item }">
                    {{ item.updated_at | formatDate }}
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          @click="editUser(item)"
                          color="orange"
                          v-on="on"
                        >mdi-pencil</v-icon>
                      </template>
                      <span>Edit User</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          @click="deleteUser(item)"
                          color="red"
                          v-on="on"
                        >mdi-delete</v-icon>
                      </template>
                      <span>Delete User</span>
                    </v-tooltip>
                  </template>
              </v-data-table>
              <div class="text-center pt-2">
                  <v-pagination
                      v-model="page"
                      :length="pageCount"
                      total-visible="10"
                  ></v-pagination>
              </div>
          </v-card-text>
      </v-card>
        <!--editUserDialog start-->
        <v-dialog v-model="editUserDialog" fullscreen>
          <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
          <v-card flat>
            <v-container>
              <v-toolbar flat dark class="indigo lighten-3">
                <v-toolbar-title
                v-if="!addUser"
                >Edit User for Payroll</v-toolbar-title
                >
                <v-toolbar-title
                v-else
                >Create User for Payroll</v-toolbar-title
                >
                <v-spacer></v-spacer>
                <v-btn
                  icon
                  dark
                  @click="editUserDialog = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar>
              <v-form ref="form">
              <v-card-text>
                <v-text-field
                  outlined
                  dense
                  label="UserID"
                  v-model="editUserId"
                  :rules="rules"
                ></v-text-field>
                <v-text-field
                  v-if="!addUser"
                  outlined
                  dense
                  label="EmployeeCode"
                  v-model="editEmployeeCode"
                  type="number"
                  readonly
                  :rules="rules"
                ></v-text-field>
                <v-text-field
                  v-else
                  outlined
                  dense
                  label="EmployeeCode"
                  v-model="editEmployeeCode"
                  :rules="rules"
                  type="number"
                ></v-text-field>
                <v-text-field
                  outlined
                  dense
                  label="EmployeeName"
                  v-model="editEmployeeName"
                  :rules="rules"
                ></v-text-field>
                <v-select
                  outlined
                  dense
                  label="Position"
                  v-model="editPosition"
                  :items="positions"
                  :rules="rules"
                ></v-select>

                <v-menu
                    v-model="menu0"
                    :close-on-content-click="false"
                    max-width="290"
                >
                    <template v-slot:activator="{ on, attrs }">                                  
                        <v-text-field
                            dense
                            outlined
                            label="Date"
                            placeholder="Date"
                            v-model="editDateHired"
                            v-on="on"
                            prepend-icon="mdi-calendar"
                            :rules="rules"
                        ></v-text-field>   
                    </template>
                    <v-date-picker
                        v-model="editDateHired"
                        @change="menu0 = false"
                    ></v-date-picker>
                </v-menu>                                              



                <v-text-field
                  outlined
                  dense
                  label="Rate/Mo."
                  v-model="editRatePerMonth"
                  type="number"
                  :rules="rules"
                ></v-text-field>
                <v-text-field
                  outlined
                  dense
                  label="SSS"
                  v-model="editSss"
                  type="number"
                ></v-text-field>
                <v-text-field
                  outlined
                  dense
                  label="PhilHealth"
                  v-model="editPhilhealth"
                  type="number"
                ></v-text-field>
                <v-text-field
                  outlined
                  dense
                  label="Pagibig"
                  v-model="editPagibig"
                  type="number"
                ></v-text-field>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  v-if="addUser"
                  block
                  dark
                  color="red"
                  class="lighten-2"
                  @click="createUser()"
                >
                  <v-icon>mdi-plus</v-icon>Create user
                </v-btn>
                <v-btn
                  v-if="!addUser"
                  block
                  dark
                  color="red"
                  class="lighten-2"
                  @click="updateUser"
                >
                  <v-icon>mdi-content-save</v-icon>update
                </v-btn>
              </v-card-actions>
              </v-form>
            </v-container>
          </v-card>
        </v-dialog>
        <!--editUserDialog finish-->
    </v-container>
</template>
<script>
export default {
  data() {
    return {
      overlay: false,
      headers: [
        {
          text: "UserID *`id` in `users` table",
          value: "user_id",
          sortable: true,
        },
        {
          text: "EmployeeCode",
          value: "employee_code",
          sortable: true,
        },
        {
          text: "EmployeeName",
          value: "employee_name",
          sortable: true,
        },
        {
          text: "Position",
          value: "position",
          sortable: true,
        },
        {
          text: "Date Hired",
          value: "date_hired",
          sortable: true,
        },
        {
          text: "Rate/Mo.",
          value: "rate_per_month",
          sortable: true,
        },
        {
          text: "SSS",
          value: "sss",
          sortable: true,
        },
        {
          text: "PhilHealth",
          value: "philhealth",
          sortable: true,
        },
        {
          text: "Pagibig",
          value: "pagibig",
          sortable: true,
        },
        {
          text: "CreatedAt",
          value: "created_at",
          sortable: true,
        },
        {
          text: "UpdatedAt",
          value: "updated_at",
          sortable: true,
        },
        {
          text: "Actions",
          value: "actions",
          sortable: true,
        },
      ],
      users_for_payroll: [],
      loading: false,
      search: null,
      page: 1,
      pageCount: 50,
      editUserDialog: false,
      positions: [],
      date_hired: null,
      rate_per_month: null,
      sss: null,
      philhealth: null,
      pagibig: null,
      editUserId: null,
      editEmployeeCode: null,
      editEmployeeName: null,
      editPosition: null,
      editDateHired: null,
      editRatePerMonth: null,
      editSss: null,
      editPhilhealth: null,
      editPagibig: null,

      positions: [],
      menu0: false,
      addUser: false,
      rules: [(v) => !!v || "Required"],
    }
  },
  mounted() {

    if( this.$store.state.user.role !== 'su' ) {
      alert('You have no access right to this page...');
      this.$router.push('/login');
    }

    this.overlay = true;
    this.loading = true;
    this.getUsersForPayroll();
    this.getPositions();
  },
  methods: {
  getPositions: function() {
    this.axios.post('/api/get_positions')
      .then(response => {
        // console.log(response.data);
        this.positions = response.data;
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.overlay = false;
        this.loading = false;
      });
  },
    getUsersForPayroll: function() {
      this.axios.post('/api/get_users_for_payroll')
      .then(response => {
        this.users_for_payroll = response.data;
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.overlay = false;
        this.loading = false;
      });
    },
    editUser: function(item) {
      this.addUser = false;
      this.editUserDialog = true;
      this.editUserId = item.user_id;
      this.editEmployeeCode = item.employee_code;
      this.editEmployeeName = item.employee_name;
      this.editPosition = item.position;
      this.editDateHired = item.date_hired;
      this.editRatePerMonth = item.rate_per_month;
      this.editSss = item.sss;
      this.editPhilhealth = item.philhealth;
      this.editPagibig = item.pagibig;
    },
    updateUser: function() {
      if(!confirm('Is it ok to edit the user?')) {
        return false;
      }

      this.overlay = true;
      this.loading = true;

      this.axios.post(
        '/api/update_user_for_payroll',
        {
          editUserId: this.editUserId,
          editEmployeeCode: this.editEmployeeCode,
          editEmployeeName: this.editEmployeeName,
          editPosition: this.editPosition,
          editDateHired: this.editDateHired,
          editRatePerMonth: this.editRatePerMonth,
          editSss: this.editSss,
          editPhilhealth: this.editPhilhealth,
          editPagibig: this.editPagibig,
        }
      ).then(response => {
        if(response.status === 200) {
          // console.log(response.data);
          this.editUserDialog = false;
          const payload = {
              title: "User Record Updated!",
              msg: "You have successfully updated the user record...",
          };
          this.$store.commit("showAlert", payload);
        }
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.overlay = false;
        this.loading = false;
        this.getUsersForPayroll();
      });
    },
    deleteUser: function(item) {
      if(!confirm('Is it ok to delete the user?')) {
        return false;
      }

      this.overlay = true;
      this.loading = true;

      this.axios.post(
        '/api/delete_user_for_payroll',
        {
          id: item.id,
        }
      ).then(response => {
        if(response.status === 200) {
          // console.log(response.data);
          this.editUserDialog = false;
          const payload = {
              title: "User Record Deleted!",
              msg: "You have successfully deleted the user record...",
          };
          this.$store.commit("showAlert", payload);
        }
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.overlay = false;
        this.loading = false;
        this.getUsersForPayroll();
      });
    },
    addNewUser: function() {
      this.addUser = true;
      this.editUserDialog = true;
      this.editUserId = null;
      this.editEmployeeCode = null;
      this.editEmployeeName = null;
      this.editPosition = null;
      this.editDateHired = null;
      this.editRatePerMonth = null;
      this.editSss = null;
      this.editPhilhealth = null;
      this.editPagibig = null;
    },
    createUser:function() {
      if(!confirm('Is it ok to create the user?')) {
        return false;
      }

      if (!this.$refs.form.validate()) {
        return false;
      }      

      this.overlay = true;
      this.loading = true;

      this.axios.post(
        '/api/create_user_for_payroll',
        {
          editUserId: this.editUserId,
          editEmployeeCode: this.editEmployeeCode,
          editEmployeeName: this.editEmployeeName,
          editPosition: this.editPosition,
          editDateHired: this.editDateHired,
          editRatePerMonth: this.editRatePerMonth,
          editSss: this.editSss,
          editPhilhealth: this.editPhilhealth,
          editPagibig: this.editPagibig,
        }
      ).then(response => {
        if(response.status === 200) {
          // console.log(response.data)
          this.editUserDialog = false;
          const payload = {
              title: "User Record Createdd!",
              msg: "You have successfully created the user record...",
          };
          this.$store.commit("showAlert", payload);
        }
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.overlay = false;
        this.loading = false;
        this.getUsersForPayroll();
      });      
    },    
  },

  filters: {
      formatDate: function (date) {
          return date !== null ? moment(date).format("MM-DD-YYYY hh:mm:ss A") : '';
      },
  },
}
</script>
