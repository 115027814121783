<template>
    <v-container>
      <v-overlay :value="overlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-card class="mx-auto px-2 pt-2">
          <!-- <v-btn @click="test">test</v-btn> -->
        <v-toolbar flat dark class="indigo lighten-3">
            <v-toolbar-title>Surveys</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="items"
            :loading="loading"
            :search="search"
          >
          <template v-slot:top>
            <v-tabs
                v-model="tab"
                center-active
                dark
            >
                <v-tabs-slider color="yellow"></v-tabs-slider>
                <v-tab
                    v-for="(statusCode, i) in statusCodes"
                    :key="i"
                >
                {{statusCode}}
            </v-tab>
            </v-tabs>
            <v-text-field
              outlined
              dense
              prepend-inner-icon="mdi-magnify"
              label="Search by text..."
              v-model="search"
            ></v-text-field>
            <!-- <v-btn
              dark
              block
              color="red"
              class="lighten-2"
              @click="createSurvey"
            >Create Survey</v-btn> -->
          </template>
          <template v-slot:[`item.created_at`]="{ item }">
            {{ item.created_at | formatDate }}
          </template>
          <template v-slot:[`item.status_code`]="{ item }">
            <!-- <div v-if="!item.job_order_id"> -->
            <div v-if="tab != 2">
                <v-btn
                    :color=statusColor(item.status_code)
                    class="lighten-2"
                    dark
                    small
                    @click="openDamageDialog(item)"
                    >
                    {{item.status_code}}
                </v-btn>
            </div>
            <div v-else>
                {{ item.status_code }}
            </div>
          </template>
          <template v-slot:[`item.progress`]="{ item }">
            <v-badge
                color="red"
                :content="item.not_yet_started ? item.not_yet_started : '0'"
                overlap
                bordered
              >
              <v-chip color="red" dark x-small>Not Yet Started</v-chip>
            </v-badge>
            <v-badge
                color="red"
                :content="item.ongoing ? item.ongoing : '0'"
                overlap
                bordered
              >
              <v-chip color="blue" dark x-small>Ongoing</v-chip>
            </v-badge>
            <v-badge
                color="red"
                :content="item.finished ? item.finished : '0'"
                overlap
                bordered
              >
              <v-chip color="green" dark x-small>Finished</v-chip>
            </v-badge>
          </template>
            <template v-slot:[`item.job_order`]="{ item }">
                <div v-if="!item.job_order_id">
                    <v-btn
                        v-if="item.not_yet_started == 0 && item.ongoing == 0"
                        color="orange"
                        dark
                        small
                        @click="createJobOrder(item)"
                    >
                        <v-icon>mdi-hammer-wrench</v-icon> Job Order
                    </v-btn>
                </div>
                <div v-else>
                    {{item.job_order_id}}
                </div>
            </template>
        </v-data-table>
        </v-card-text>
      </v-card>
      <!--damageDialog Start -->
      <v-dialog v-model="damageDialog" fullscreen>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-card flat>
          <v-container fluid>
            <v-toolbar flat dark class="indigo lighten-3">
              <v-toolbar-title
              >Damages: {{currentContainerNumber}}({{currentTransactionId}})</v-toolbar-title
              >
              <v-spacer></v-spacer>
              <v-btn
                  icon
                  dark
                  @click="damageDialog = false"
                >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <v-btn
                block
                dark
                color="orange"
                class="lighten-2 my-2"
                @click="openDestinationSelectDialog()"
            >
                <v-icon>mdi-update</v-icon> Update Destination
              </v-btn>
              <v-expansion-panels>
                <v-expansion-panel
                    v-for="(picture, i) in pictures"
                    :key="i"
                >
                    <v-expansion-panel-header
                        color="primary"
                        class="lighten-3 white--text"
                        dark
                    >
                        {{ picture.file_name }}
                        <template v-slot:actions>
                            <v-chip
                                :color=statusColor(picture.status)
                                dark
                            >{{ picture.status }}</v-chip>
                        </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-card flat>
                            <v-container>
                                <v-card-title>AreaCode: {{ picture.area_code }}</v-card-title>
                                <v-card-text>
                                    <p>AreaType: {{ picture.area_type }}</p>
                                    <p>AreaName: {{ picture.area_name }}</p>
                                    <p><v-img :src="`/images/locations/loc-${currentContainerSize}-${picture.area_code}.png`" width="200"></v-img></p>
                                </v-card-text>
                                <v-card-actions>
                                    <v-btn
                                        color=""
                                        class="lighten-2"
                                        dark
                                        block
                                        @click="openCheckDamageDialog(picture)"
                                        >
                                            <v-icon>mdi-open-in-app</v-icon>
                                            Check Damage Window
                                    </v-btn>
                                </v-card-actions>
                            </v-container>
                        </v-card>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
              <!-- <v-data-table
                :headers="picturesHeaders"
                :items="pictures"
                :loading="loading"
                :items-per-page="-1"
              >
              <template v-slot:[`item.image`]="{ item }">
                <v-img :src="`/images/locations/loc-${currentContainerSize}-${item.area_code}.png`"></v-img>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                <v-chip
                  :color=statusColor(item.status)
                  dark
                  @click="openCheckDamageDialog(item)"
                >{{item.status}}</v-chip>
              </template>
            </v-data-table> -->
            <v-btn
                block
                dark
                color="orange"
                class="lighten-2 my-2"
                @click="openDestinationSelectDialog()"
            >
                <v-icon>mdi-update</v-icon> Change all statuses to "Finished"
              </v-btn>
            </v-card-text>
          </v-container>
        </v-card>
      </v-dialog>
      <!--damageDialog Finish -->
      <!--checkDamageDialog Start -->
      <v-dialog v-model="checkDamageDialog" fullscreen>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-card flat>
          <v-container fluid>
            <v-toolbar flat dark class="indigo lighten-3">
              <v-toolbar-title
              >Check Damage(s): {{currentPicture}}({{currentContainerNumber}})</v-toolbar-title
              >
              <v-spacer></v-spacer>
              <v-btn
                  icon
                  dark
                  @click="checkDamageDialog = false"
                >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-row>
              <v-col cols="6">
                <v-form ref="form">
                <v-card-text>
                  <v-row>
                    <v-col>
                      <v-img
                        :src="`/images/locations/loc-${currentContainerSize}-${currentAreaCode}.png`"
                        width="200"
                      ></v-img>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4">
                      <v-select
                        outlined
                        dense
                        v-model="currentLocationCode"
                        label="FromLocation"
                        :items="locationCodes"
                        :rules="rules"
                        :disabled="currentPictureStatus == 'Finished'"
                      ></v-select>
                    </v-col>
                    <v-col cols="4">
                      <v-select
                        outlined
                        dense
                        v-model="currentToLocationCode"
                        label="ToLocation"
                        :items="toLocationCodes"
                        :rules="rules"
                        :disabled="currentPictureStatus == 'Finished'"
                      ></v-select>
                    </v-col>
                    <v-col cols="4">
                      <v-select
                        outlined
                        dense
                        v-model="currentDamageCode"
                        :items="damageCodes"
                        item-text="damage_name"
                        item-value="damate_code_id"
                        label="DamageCode"
                        :rules="rules"
                        :disabled="currentPictureStatus == 'Finished'"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    color="red"
                    class="lighten-2"
                    dark
                    block
                    @click="addDamage"
                    :disabled="currentPictureStatus == 'Finished'"
                  >
                    <v-icon>mdi-plus</v-icon> ADD
                  </v-btn>
                </v-card-actions>
                </v-form>
                <v-card-text>
                  <v-data-table
                    :headers="damagesHeaders"
                    :items="damages"
                  >
                  <template v-slot:top>
                    <v-toolbar flat dark class="indigo lighten-3">
                      <v-toolbar-title>Damages to be registered</v-toolbar-title>
                    </v-toolbar>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-icon
                      color="red"
                      @click="deleteDamage(item)"
                      :disabled="currentPictureStatus == 'Finished'"
                    >mdi-delete</v-icon>
                  </template>
                </v-data-table>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                      :color=statusColor2(currentPictureStatus)
                      class="lighten-2"
                      block
                      dark
                      @click="changeStatusOfPicture"
                  >
                      <v-icon>mdi-reload</v-icon> Change status from "{{currentPictureStatus}}"
                  </v-btn>
                </v-card-actions>
              </v-col>
              <v-col cols="6">
                <v-card-text>
                  <v-toolbar flat dark class="indigo lighten-3">
                      <v-toolbar-title>Picture: {{currentPicture}}</v-toolbar-title>
                    </v-toolbar>
                  <v-img
                    lazy-src="/images/blank.jpg"
                    :src="imgData"
                  >
                    <template v-slot:placeholder>
                        <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                        >
                        <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                        ></v-progress-circular>
                        </v-row>
                    </template>
                  </v-img>
                </v-card-text>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
      <!--checkDamageDialog Finish -->
      <!--destinationSelectDialog Start -->
      <v-dialog v-model="destinationSelectDialog" fullscreen>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-card flat>
          <v-container fluid>
            <v-toolbar flat dark class="indigo lighten-3">
              <v-toolbar-title
              >Select Destination: {{currentContainerNumber}}</v-toolbar-title
              >
              <v-spacer></v-spacer>
              <v-btn
                  icon
                  dark
                  @click="destinationSelectDialog = false"
                >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
                <v-card-actions>
                    <v-select
                        label="Repair Destination"
                        :items="destinations"
                        item-text="destination_type"
                        item-value="destination_id"
                        outlined
                        dense
                        v-model="destination"
                        block
                    ></v-select>
                </v-card-actions>
                <v-card-actions>
                    <v-text-field
                        outlined
                        dense
                        v-model="brokerName"
                        label="BrokerName"
                        block
                    ></v-text-field>
                </v-card-actions>
                <v-card-actions>
                    <v-text-field
                        outlined
                        dense
                        v-model="descriptionOfRepair"
                        label="Description of repair"
                        block
                    ></v-text-field>
                </v-card-actions>
                <v-card-actions>
                    <v-btn
                    block
                    color="green"
                    dark
                    v-if="destination && brokerName && descriptionOfRepair"
                    @click="updateStatusesOfAllPictures()"
                    >
                        <v-icon>mdi-update</v-icon>
                        Update Status of Pictures
                    </v-btn>
                </v-card-actions>
          </v-container>
        </v-card>
      </v-dialog>
      <!--destinationSelectDialog Finish -->
    </v-container>
</template>
<script>
import moment from "moment";

export default {
  data() {
    return {
      overlay: false,
      loading: false,
      search: null,
      tab: 0,
      statusCodes: ['Not Yet Started', 'Ongoing', 'Finished'],
      damageDialog: false,
      checkDamageDialog: false,
      currentContainerNumber: null,
      currentTransactionId: null,
      currentSurveyId: null,
      currentPicture: null,
      currentAreaCode: null,
      currentLocationCode: null,
      currentToLocationCode: null,
      currentDamageCode: null,
      currentContainerSize: null,
      currentPictureStatus: null,
      currentDamageItem: null,
      imgData: null,
      damageCodes: [],
      rules: [(v) => !!v || "Required"],
      headers: [
        {
          text: "SurveyId",
          value: "survey_id",
          sortable: true,
        },
        {
          text: "TransactionId",
          value: "transaction_id",
          sortable: true,
        },
        {
          text: "Container#",
          value: "container_number",
          sortable: true,
        },
        {
          text: "ContainerSize",
          value: "container_size",
          sortable: true,
        },
        {
          text: "CreatedAt",
          value: "created_at",
          sortable: true,
        },
        {
          text: "StatusCode",
          value: "status_code",
          sortable: true,
        },
        {
          text: "Progress",
          value: "progress",
          sortable: true,
        },
        {
          text: "JobOrder",
          value: "job_order",
          sortable: false,
        },
      ],

      items: [],
      picturesHeaders: [
      {
          text: "Image",
          value: "image",
          sortable: true
        },
        {
          text: "FileName",
          value: "file_name",
          sortable: true
        },
        {
          text: "AreaCode",
          value: "area_code",
          sortable: true
        },
        {
          text: "AreaType",
          value: "area_type",
          sortable: true
        },
        {
          text: "AreaName",
          value: "area_name",
          sortable: true
        },
        {
          text: "Status",
          value: "status",
          sortable: true
        },
      ],
      pictures: [],
      locationCodes: [],
      toLocationCodes: [],
      screenSizeToggleIcon: 'mdi-fullscreen',
      damagesHeaders: [
        {
          text: "AreaCode",
          value: "area_code",
          sortable: true,
        },
        {
          text: "FromLocation",
          value: "location_code",
          sortable: true,
        },
        {
          text: "ToLocation",
          value: "to_location_code",
          sortable: true,
        },
        {
          text: "DamageCode",
          value: "damage_code",
          sortable: true,
        },
        {
          text: "Actions",
          value: "actions",
          sortable: true,
        },
      ],
      damages: [],
      destinationSelectDialog: false,
      destinations: [],
      destination: null,
      brokerName: null,
      descriptionOfRepair: null,
    }
  },
  mounted() {
    if(this.$store.state.user.role === 'admin') {
        location.href = '/transactions';
    }

    if( this.$route.params.transaction_id && this.$route.params.tab ) {
        this.search = this.$route.params.transaction_id;
        this.tab = parseInt(this.$route.params.tab);
    } else {
        this.search = null;
        this.tab = 0;
    }

    this.getSurveys(this.tab);
    this.loadDestinations();
  },
  methods: {
    getSurveys: function(tab) {
      this.overlay = true;
      this.loading = true;

    this.axios.post(
        '/api/get_surveys',
        {
            tab: this.tab,
        }
    ).then(response => {
        console.log(response.data)
        if(response.status == 200 ) {
          this.items = response.data;
        }
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.overlay = false;
        this.loading = false;
      });
    },
    openDamageDialog: function(item) {
      this.damageDialog = true;
      this.currentContainerNumber = item.container_number;
      this.currentTransactionId = item.transaction_id;
      this.currentSurveyId = item.survey_id;
      this.currentContainerSize = item.container_size;
      this.currentPicture = item.file_name;
      this.overlay = true;
      this.loading = true;

      this.getPicturesByTransactionId(item.transaction_id);
    },
    getPicturesByTransactionId: function(transaction_id) {
      this.axios.post(
        '/api/get_pictures_by_transaction_id',
        {
          transaction_id: transaction_id,
        }
      ).then(response => {
        // console.log(response.data);
        this.pictures = response.data;
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.overlay = false;
        this.loading = false;
      });
    },
    openCheckDamageDialog: function(item) {
      // console.log(item);
      this.checkDamageDialog = true;
      this.currentPicture = item.file_name;
      this.currentAreaCode = item.area_code;
      this.currentPictureStatus = item.status;
      this.currentLocationCode = null;
      this.currentToLocationCode = null;
      this.currentDamageCode = null;
      this.currentPictureItem = item;
      this.imgData = null;
    //   this.overlay = true;
      this.downloading = true;

      this.axios.post(
        '/api/get_area_configuration',
        {
          container_size: this.currentContainerSize,
          area_code: this.currentAreaCode,
        }
      ).then(response => {
        this.locationCodes = [];
        this.toLocationCodes = [];
        const config = JSON.parse(response.data[0].config);

        for(var n = config[0][1]; n < config[0][2]; n++) {
          this.locationCodes.push(
            config[0][0] + n
          );
        }

        for(var n = config[1][1]; n < config[1][2]; n++) {
          this.toLocationCodes.push(
            config[1][0] + n
          );
        }
      }).catch(error => {
        console.log(error);
      });

      this.getDamages(item);

      this.axios.post('/api/get_damage_codes')
        .then(response => {
          this.damageCodes = response.data;
        }).catch(error => {
          console.log(error);
        });

      axios
        .post(
            "/api/view_picture",
            {
                transaction_id: item.transaction_id,
                container_number: item.container_number,
                file_name: item.file_name,
            },
            {
                responseType: "blob",
            }
        )
        .then(response => {
          this.imgData = window.URL.createObjectURL(
            new Blob([response.data], { type: "image/png" })
          );
        }).catch(error => {
            console.log(error);
        }).finally(() => {
            this.downloading = false;
            // this.overlay = false;
        });
    },
    getDamages: function(item) {
      this.axios.post(
        '/api/get_damages',
        {
          transaction_id: item.transaction_id,
          survey_id: this.currentSurveyId,
          container_number: item.container_number,
          area_code: this.currentAreaCode,

        }
      ).then(response => {
        this.damages = response.data;
      }).catch(error => {
        console.log(error);
      });
    },
    addDamage: function() {
      if (!this.$refs.form.validate()) {
        return false;
      }

      this.overlay = true;
      this.loading = true;

      this.axios.post(
        '/api/add_damage',
        {
          transaction_id: this.currentTransactionId,
          survey_id: this.currentSurveyId,
          container_number: this.currentContainerNumber,
          area_code: this.currentAreaCode,
          picture_id: this.currentPictureItem.id,
          location_code: this.currentLocationCode,
          to_location_code: this.currentToLocationCode,
          damage_code: this.currentDamageCode,
          user_id: this.$store.state.user.id,
        }
      ).then(response => {
        // console.log(response.data);
        this.damages = response.data;
        this.getPicturesByTransactionId(this.currentTransactionId);
        this.getSurveys(this.tab);
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.overlay = false;
        this.loading = false;
      });
    },
    changeStatusOfPicture: function() {
      if(!confirm('Is it ok to change the status?')) {
        return false;
      }
      if(this.currentPictureStatus == 'Finished') {
        if(this.damages.length > 0) {
          this.currentPictureStatus = 'Ongoing';
        } else {
          this.currentPictureStatus = 'Not Yet Started';
        }

      } else {
        this.currentPictureStatus = 'Finished';
      }

      this.overlay = true;
      this.loading = true;
      this.axios.post(
        '/api/change_status_of_picture',
        {
          container_number: this.currentContainerNumber,
          transaction_id: this.currentTransactionId,
          area_code: this.currentAreaCode,
          new_status: this.currentPictureStatus,
        }
      ).then(response => {
        this.getPicturesByTransactionId(this.currentTransactionId);
        this.getSurveys(this.tab);
        this.getDamages(this.currentPictureItem);
        this.checkDamageDialog = false;
      }).catch(error => {

      }).finally(() => {
        this.overlay = false;
        this.loading = false;
      });

    },
    statusColor: function(status) {
      switch(status) {
        case 'Not Yet Started':
          return 'red';
          break;
        case 'Ongoing':
          return 'blue';
          break;
        case 'Finished':
          return 'green';
          break;
      }
    },
    statusColor2: function(status) {
      switch(status) {
        case 'Not Yet Started':
          return 'green';
          break;
        case 'Ongoing':
          return 'blue';
          break;
        case 'Finished':
          return 'red';
          break;
      }
    },
    deleteDamage: function(item) {
      if(!confirm('Is it ok to delete the damage information?')) {
        return false;
      }

      this.overlay = true;
      this.loading = true;

      this.axios.post(
        '/api/delete_damage',
        {
          id: item.id,
          transaction_id: this.currentTransactionId,
          survey_id: this.currentSurveyId,
          container_number: this.currentContainerNumber,
          area_code: this.currentAreaCode,
        }
      ).then(response => {
        if(response.status === 200) {
          this.getDamages(item);
          this.getPicturesByTransactionId(this.currentTransactionId);
          this.getSurveys(this.tab);
        }
      }).catch(error => {
        console.log(error);
      }).finally(() => {
        this.overlay = false;
        this.loading = false;
      });
    },
    createJobOrder: function(item) {
        if(!confirm('Is it ok to create a job order for the survey result?')) {
                return false;
            }
        this.overlay = true;
        this.loading = true;

        this.axios.post(
            '/api/create_job_order',
            {
                transaction_id: item.transaction_id,
                survey_id: item.survey_id,
                container_number: item.container_number,
                user_id: this.$store.state.user.id,
            }
        ).then(response => {
            // console.log(response.data);
            this.getSurveys(this.tab);
            if(response.status === 200) {
                const payload = {
                    title: "New Job Order created!",
                    msg: "You have created a new job order successfully...",
                };
                this.$store.commit("showAlert", payload);
                location.href = '/job_orders';
            }
        }).catch(error => {
            console.log(error);
        }).finally(() => {
            this.overlay = false;
            this.loading = false;
        });
    },
    openDestinationSelectDialog: function() {
        this.overlay = true;
        this.loading = true;

        this.axios.post(
            '/api/get_damages',
            {
                transaction_id: this.currentTransactionId,
                survey_id: this.currentSurveyId,
            }
        ).then(response => {
            if( response.data.length === 0 ) {
                alert('You have no damage record...need to encode at least one');
                return false;
            } else {
                if( !confirm('Are you going to update Destination?')) {
                    return false;
                } else {
                    // ここでDestination更新モーダル開く。
                    this.destinationSelectDialog = true;
                    // this.updateStatusesOfAllPictures();
                }
            }
        }).catch(error => {
            console.log(error);
        }).finally(() => {
            this.overlay = false;
            this.loading = false;
        });
    },
    updateStatusesOfAllPictures: function() {
        if( !confirm('Is it ok to update statuses of all the pictures?') ) {
            return false;
        }
        this.damageDialog = false;
                this.axios.post(
                    '/api/update_statuses_of_all_pictures',
                    {
                        transaction_id: this.currentTransactionId,
                        survey_id: this.currentSurveyId,
                    }
                ).then(response => {
                    // console.log(response.data);
                    if( response.status === 200 ) {
                        this.destinationSelectDialog = false;
                        this.getPicturesByTransactionId(this.currentTransactionId);
                        this.getSurveys(this.tab);
                    }
                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                    this.overlay = false;
                    this.loading = false;
                });
    },
    loadDestinations: function() {
        this.overlay = true;
        this.axios.post(
            '/api/get_destinations'
        ).then(response => {
            this.destinations = response.data;
        }).catch(error => {
            console.log(error);
        }).finally(() => {
            this.overlay = false;
        });
    },
  },
  filters: {
    formatDate: function (date) {
        return date !== null ? moment(date).format("MM-DD-YYYY hh:mm:ss A") : '';
    },
  },
  watch: {
        tab: function() {
            this.getSurveys(this.tab);
        }
    }
}
</script>
