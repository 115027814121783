<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <h3>Change Password</h3>
    {{ $route.params.email }}
    <v-form ref="form">
      <v-row>
        <v-col cols="4">
          <v-text-field v-model="pin" label="PIN" :rules="rules"></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="password"
            label="Password"
            :rules="rules"
            :type="show1 ? 'text' : 'password'"
            :append-icon="show1 ? 'visibility' : 'visibility_off'"
            @click:append="() => (show1 = !show1)"
          ></v-text-field> </v-col
        ><v-col cols="4">
          <v-text-field
            v-model="password_confirmation"
            label="Password Confirmation"
            :rules="rules"
            :type="show2 ? 'text' : 'password'"
            :append-icon="show2 ? 'visibility' : 'visibility_off'"
            @click:append="() => (show2 = !show2)"
          ></v-text-field>
        </v-col>
      </v-row>
    <v-btn color="red" dark @click="checkPin">Verify</v-btn>
  </v-form>
  </div>
</template>
<script>
export default {
  data() {
    return {
      overlay: false,
      show1: false,
      show2: false,
      pin: null,
      password: null,
      password_confirmation: null,
      rules: [(v) => !!v || "Required"],
    };
  },
  methods: {
    checkPin: function () {
      if (!this.$refs.form.validate()) {
        return false;
      }
      if (this.password !== this.password_confirmation) {
        const payload = {
          title: "Password are not matched",
          msg: "Please provide correct and same passwords again...",
        };
        this.$store.commit("showAlert", payload);
        this.password = null;
        this.password_confirmation = null;
        return false;
      }
      this.overlay = true;
      this.axios
        .post("/api/change_password", {
          email: this.$route.params.email,
          password: this.password,
          pin: this.pin,
        })
        .then((response) => {
          // console.log(response.data);
          if (response.data == "done") {
            this.overlay = false;
            const payload = {
              title: "Password changed",
              msg: "You have changed your password successfully. Please login by using the new password...",
            };
            this.$store.commit("showAlert", payload);
            this.$router.push("/login");
          } else {
            this.overlay = false;
            const payload = {
              title: "Failed to change password",
              msg: "Your OTP seems to be wrong or already expired...please process it again...",
            };
            this.$store.commit("showAlert", payload);
            this.$router.push("/login");
          }
        });
    },
  },
};
</script>
