<template>
    <div>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-card flat>
            <v-container fluid>
                <v-toolbar flat dark class="indigo lighten-3">
                    <v-toolbar-title
                    >Encode Job Order Services: {{ containerNumber }}</v-toolbar-title
                    >
                </v-toolbar>
                <v-card-actions>
                    <v-btn
                        block
                        color="green"
                        dark
                        @click="allDoneForThisDamage(currentDamage)"
                        v-if="jobOrderServices.length > 0"
                    >
                        <v-icon>mdi-check-outline</v-icon> All Done For This Damage
                    </v-btn>
                </v-card-actions>
                <v-card-text>
                    <canvas ref="canvas" style="display: none;"></canvas>
                    <v-simple-table dense>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-center">AreaCode</th>
                                    <th class="text-center">DamageCode</th>
                                    <th class="text-center">LocationCode</th>
                                    <th class="text-center">ToLocationCode</th>
                                    <th class="text-center">StatusCode</th>
                                    <th class="text-center">Download Picture</th>
                                    <th class="text-center">Download Damage Picture</th>
                                </tr>
                            </thead>
                            <tbody>
                                <td class="text-center">{{ areaCode }}</td>
                                <td class="text-center">{{ damageCode }}</td>
                                <td class="text-center">{{ locationCode }}</td>
                                <td class="text-center">{{ toLocationCode }}</td>
                                <td class="text-center">{{ statusCode }}</td>
                                <td class="text-center">
                                    <v-icon
                                        color="green"
                                        size="30"
                                        @click="downloadPicture()"
                                    >mdi-image</v-icon>
                                </td>
                                <td class="text-center">
                                    <v-badge
                                        color="red"
                                        :content="damagePictures.length"
                                        overlap
                                        v-if="damagePictures.length > 0"
                                    >
                                        <v-icon
                                            color="blue"
                                            size="30"
                                            @click="openDamagePicturesDialog(currentDamage)"
                                        >mdi-image</v-icon>
                                    </v-badge>
                                </td>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
                <v-card-text>
                    <v-autocomplete
                        label="Part Name"
                        dense
                        outlined
                        chips
                        small-chips
                        v-model="partName"
                        :items="part_names"
                        @change="getDescriptions"
                    ></v-autocomplete>
                    <v-autocomplete
                        label="Description"
                        dense
                        outlined
                        chips
                        small-chips
                        v-model="description"
                        :items="descriptions"
                        @change="getLocations"
                    ></v-autocomplete>
                    <v-autocomplete
                        label="Location"
                        dense
                        outlined
                        chips
                        small-chips
                        v-model="location"
                        :items="locations"
                        @change="getWayOfRepairs"
                    ></v-autocomplete>
                    <v-divider></v-divider>
                    <v-card-title>Way of Repair</v-card-title>
                    <v-chip-group
                        active-class="primary--text"
                        column
                        v-model="wayOfRepair"
                    >
                        <v-chip
                        v-for="id, w in way_of_repairs"
                        :key="id"
                        dark
                        @click="getServiceId(id)"
                        >
                        {{ w }}
                        </v-chip>
                    </v-chip-group>
                    <v-btn
                        fab
                        fixed
                        bottom
                        right
                        dark
                        color="red"
                        @click="saveJobOrderServices"
                        :disabled="wayOfRepair === null"
                    >
                        <v-icon>fas fa-save</v-icon>
                    </v-btn>
                    <v-divider></v-divider>
                    <v-data-table
                        :headers="servicesHeaders"
                        :items="jobOrderServices"
                        :items-per-page="-1"
                        :loading="loading"
                    >

                        <template v-slot:[`item.post_service_pictures`]="{ item }">
                            <div v-if="item.post_service_pictures.length > 0">
                                <v-badge
                                    color="red"
                                    :content="item.post_service_pictures.length"
                                    overlap
                                >
                                    <v-btn
                                        color="orange"
                                        small
                                        dark
                                        @click="openPostServicePicturesDialog(item)"
                                    >
                                        <v-icon>mdi-image</v-icon>
                                        Post Service Pictures
                                    </v-btn>
                                </v-badge>
                            </div>
                            <div v-else>
                                <v-btn
                                    color="orange"
                                    small
                                    dark
                                    @click="openPostServicePicturesDialog(item)"
                                >
                                    <v-icon>mdi-image</v-icon>
                                    Post Service Pictures
                                </v-btn>
                            </div>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon
                                    color="red"
                                    v-on="on"
                                    @click="deleteJobOrderService(item)"
                                    :disabled="item.post_service_pictures.length > 0"
                                >mdi-delete</v-icon>
                            </template>
                            <span>Delete JobOrderService</span>
                            </v-tooltip>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-container>
        </v-card>
        <!-- damagePicturesDialog start -->
        <v-dialog v-model="damagePicturesDialog" fullscreen>
            <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
            <v-card flat>
                <v-container>
                    <v-toolbar flat dark class="indigo lighten-3">
                        <v-toolbar-title
                        >Damage Pictures -- ContainerNumber: {{containerNumber}} --</v-toolbar-title
                        >
                        <v-spacer></v-spacer>
                        <v-btn
                            icon
                            dark
                            @click="damagePicturesDialog = false"
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <v-data-table
                            :headers="damagePictureHeaders"
                            :items="damagePictures"
                            :items-per-page="-1"
                        >
                            <template v-slot:[`item.download_damage_picture`]="{ item }">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon
                                            color="green"
                                            @click="downloadDamagePicture(item)"
                                            large
                                            v-on="on"
                                        >mdi-image</v-icon>
                                    </template>
                                    <span>View Picture</span>
                                </v-tooltip>
                            </template>
                            <template v-slot:[`item.damage_pictures`]="{ item }">
                                <div v-if="item.damage_pictures.length > 0">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-badge
                                                color="red"
                                                overlap
                                                :content="item.damage_pictures.length"
                                            >
                                                <v-icon
                                                    color="blue"
                                                    @click="openDamagePicturesDialog(item)"
                                                    large
                                                    v-on="on"
                                                >mdi-image</v-icon>
                                            </v-badge>
                                        </template>
                                        <span>View Damage Picture</span>
                                    </v-tooltip>
                                </div>
                            </template>
                            <template v-slot:[`item.created_at`]="{ item }">
                                {{ item.created_at | formatDate }}
                            </template>>
                        </v-data-table>
                    </v-card-text>
                </v-container>
            </v-card>
        </v-dialog>
        <!--damagePicturesDialog end -->
        <!-- postServicePicturesDialog start -->
        <v-dialog v-model="postServicePicturesDialog" fullscreen>
            <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
            <v-card flat>
                <v-container>
                    <v-toolbar flat dark class="indigo lighten-3">
                        <v-toolbar-title
                        >Post Service Pictures -- ContainerNumber: {{containerNumber}} --</v-toolbar-title
                        >
                        <v-spacer></v-spacer>
                        <v-btn
                            icon
                            dark
                            @click="postServicePicturesDialog = false"
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <v-file-input
                            dense
                            outlined
                            small-chips
                            multiple
                            v-model="userFiles"
                            prepend-icon="mdi-camera"
                            placeholder="Post-Service Pictures"
                            capture="camera"
                            accept="image/*"
                            @change="addFiles()"
                        ></v-file-input>
                        <!-- <v-progress-linear
                            height="15"
                            :value="progressLinear"
                            dark
                            stream
                            class="my-2"
                        ></v-progress-linear> -->
                    </v-card-text>
                    <v-card-text>
                        <v-data-table
                            :headers="postServicePictureHeaders"
                            :items="postServicePictures"
                            :loading="loading"
                            :search="search"
                        >
                            <template v-slot:[`item.actions`]="{item}">
                                <v-icon
                                    color="green"
                                    @click="viewPostServicePicture(item)"
                                >mdi-eye</v-icon>
                                <v-icon
                                    color="red"
                                    @click="deletePostServicePicture(item)"
                                >mdi-delete</v-icon>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-container>
            </v-card>
        </v-dialog>
        <!--postServicePicturesDialog end -->
    </div>
</template>
<script>
export default {
    data() {
        return {
            overlay: false,
            loading: false,
            transactionId: null,
            containerNumber: null,
            surveyId: null,
            currentDamage: null,
            containerNumber: null,
            jobOrderId: null,
            damageId: null,
            currentDamage: null,
            jobOrderServices: [],
            part_names: [],
            partName: [],
            descriptions: [],
            description: null,
            locations: [],
            location: null,
            way_of_repairs: [],
            wayOfRepair: null,
            serviceId: null,
            areaCode: null,
            damageCode: null,
            locationCode: null,
            toLocationCode: null,
            statusCode: null,
            damagePictures: [],
            pictureId: null,
            pictureFileName: null,
            servicesHeaders: [
                {
                    text: "JobOrderServiceId",
                    value: "job_order_service_id",
                    sortable: true,
                },
                {
                    text: "ServiceId",
                    value: "service_id",
                    sortable: true,
                },
                {
                    text: "PartName",
                    value: "service.part_name",
                    sortable: true,
                },
                {
                    text: "Description",
                    value: "service.description",
                    sortable: true,
                },
                {
                    text: "Loacation",
                    value: "service.location",
                    sortable: true,
                },
                {
                    text: "WayOfRepair",
                    value: "service.way_of_repair",
                    sortable: true,
                },
                {
                    text: "Hour",
                    value: "service.hour",
                    sortable: true,
                },
                {
                    text: "MaterialCost",
                    value: "service.material_cost",
                    sortable: true,
                },
                {
                    text: "LaborCost",
                    value: "service.labor_cost",
                    sortable: true,
                },
                {
                    text: "Post-Service Pictures",
                    value: "post_service_pictures",
                    sortable: false,
                },
                {
                    text: "Actions",
                    value: "actions",
                    sortable: false,
                }
            ],
            damagePicturesDialog: false,
            damagePictureHeaders: [
                {
                    text: "FileName",
                    value: "file_name",
                    sortable: true,
                },
                {
                    text: "Download Damage Picture",
                    value: "download_damage_picture",
                    sortable: true,
                },
                {
                    text: "CreatedAt",
                    value: "created_at",
                    sortable: true,
                },
            ],

            postServicePicturesDialog: false,
            userFiles: [],

            postServicePictureHeaders: [
                {
                    text: 'JobOrderServiceId',
                    value: 'job_order_service_id',
                    sortable: true,
                },
                {
                    text: 'FileName',
                    value: 'file_name',
                    sortable: true,
                },
                {
                    text: 'Actions',
                    value: 'actions',
                    sortable: false,
                }
            ],
            postServicePictures: [],
            search: null,
        }
    },
    mounted() {
        this.transactionId = this.$route.params.transaction_id;
        this.containerNumber = this.$route.params.container_number;
        this.surveyId = this.$route.params.survey_id;
        this.jobOrderId = this.$route.params.job_order_id;
        this.damageId = this.$route.params.damage_id;
        this.getDamage();
        this.getPartNames();
        this.getJobOrderServicesByDamageId(this.jobOrderId, this.damageId);
    },
    methods: {
        getDamage: function() {
            this.axios.post(
                '/api/get_damage',
                {
                    damage_id: this.damageId
                }
            ).then(response => {
            // console.log(response.data)
            if(response.status === 200) {
                this.currentDamage = response.data;
                this.areaCode = this.currentDamage.area_code;
                this.damageCode = this.currentDamage.damage_code;
                this.pictureId = this.currentDamage.picture_id;
                this.locationCode = this.currentDamage.location_code;
                this.toLocationCode = this.currentDamage.to_location_code;
                this.statusCode = this.currentDamage.status_code;
                this.getPicture();
                this.getDamagePictures();
            }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },
        getPicture: function() {
            this.axios.post(
                '/api/get_picture',
                {
                    picture_id: this.pictureId,
                }
            ).then(response => {
                if(response.status === 200) {
                    this.pictureFileName = response.data[0].file_name;
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },
        getPartNames: function() {
            this.axios.post('/api/get_part_names')
                .then(response => {
                    // console.log(response.data)
                    if(response.status === 200) {
                        this.part_names = response.data;
                    }
                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                    this.overlay = false;
                    this.loading = false;
                });
        },
        getDescriptions: function() {
            this.locations = [];
            this.way_of_repairs = [];
            this.description = null;
            this.axios.post(
                '/api/get_descriptions',
                {
                    partName: this.partName,
                }
            ).then(response => {
                // console.log(response.data);
                this.descriptions = response.data;
            }).catch(error => {
                console.log(error);
            });
        },
        getLocations: function() {
            this.way_of_repairs = [];
            this.axios.post(
                '/api/get_locations',
                {
                    partName: this.partName,
                    description: this.description
                }
            ).then(response => {
                // console.log(response.data);
                if(response.status === 200) {
                    this.locations = response.data;
                }
            }).catch(error => {
                console.log(error);
            });
        },
        getWayOfRepairs: function(location) {
            this.location = location;
            this.axios.post(
                '/api/get_way_of_repairs',
                {
                    partName: this.partName,
                    description: this.description,
                    location: location,
                }
            ).then(response => {
                // console.log(response.data);
                if(response.status === 200) {
                    this.way_of_repairs = response.data;
                }
            }).catch(error => {
                console.log(error);
            });
        },
        getDamagePictures: function() {
            this.axios.post(
                '/api/get_damage_pictures',
                {
                    picture_id: this.pictureId,
                }
            ).then(response => {
                // console.log(pictureId);
                if(response.status === 200) {
                    this.damagePictures = response.data;
                }
            }).catch(error => {
                console.log(error);
            });
        },
        saveJobOrderServices: function() {
            // if(!confirm('Is it ok to save the data?')) {
            //     return false;
            // }

            this.overlay = true;
            this.loading = true;
            this.axios.post(
                '/api/save_job_order_services',
                {
                    jobOrderId: this.jobOrderId,
                    damage: this.currentDamage,
                    partName: this.partName,
                    destination: this.destination,
                    location: this.location,
                    serviceId: this.serviceId,
                    user_id: this.$store.state.user.id,
                    transaction_id: this.transactionId
                }
            ).then(response => {
                // console.log(response.data);
                if(response.status === 200) {
                    this.getJobOrderServicesByDamageId(this.jobOrderId, this.damageId);
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },
        getServiceId: function(id) {
            this.serviceId = id;
        },
        deleteJobOrderService: function(item) {
            if(!confirm('Is it ok to delete the JobOrderService?')) {
                return false;
            }
            this.overlay = true;
            this.loading = true;
            this.axios.post(
                '/api/delete_job_order_service',
                {
                    id: item.id,
                    job_order_id: item.job_order_id,
                    damage_id: item.damage_id,
                }
            ).then(response => {
                if(response.status === 200) {
                    this.getJobOrderServicesByDamageId(this.jobOrderId, this.damageId);
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },
        allDoneForThisDamage: function(currentDamage) {

            const josWithoutPostServicePicture = this.jobOrderServices.filter(function(value) {
                return value.post_service_pictures.length == 0;
            });


            if(josWithoutPostServicePicture.length > 0) {
                alert('You need to upload at least one post service picture for each job order service...');
                return false;
            }

            this.overlay = true;
            this.loading = true;
            this.axios.post(
                '/api/update_damage_status_code',
                {
                    survey_id: currentDamage.survey_id,
                    damage_id: currentDamage.damage_id,
                    user_id: this.$store.state.user.id,
                }
            ).then(response => {
                // this.getDamage();
                // this.getJobOrders(this.tab);
                this.$router.push('/damage_list/' + this.transactionId + '/' + this.containerNumber + '/' + this.surveyId + '/' + this.jobOrderId);
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },
        getPostServicePictures: function(job_order_service_id) {
            this.overlay = true;
            this.loading = true;
            this.axios.post(
                '/api/get_post_service_pictures',
                {
                    job_order_service_id: job_order_service_id,
                }
            ).then(response => {
                // console.log(response.data)
                if(response.status === 200) {
                    this.postServicePictures = response.data;

                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },
        deletePostServicePicture: function(item) {
            if(!confirm('Is it ok to delete the picture?')) {
                return false;
            }
            this.overlay = true;
            this.loading = true;
            this.axios.post(
                '/api/delete_post_service_picture',
                {
                    id: item.id,
                    container_number: item.container_number,
                    transaction_id: item.transaction_id,
                    file_name: item.file_name,
                }
            ).then(response => {
                // console.log(response.data);
                if(response.status === 200) {
                    const payload = {
                        title: "Picture deleted",
                        msg: "The picture data has been successfully deleted...",
                    };
                    this.$store.commit("showAlert", payload);
                    this.getPostServicePictures(this.currentJobOrderServiceId);
                    this.getJobOrderServicesByDamageId(this.jobOrderId, this.damageId);
                    this.getDamage();
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },
        viewPostServicePicture: function(item) {
            this.overlay = true;
            this.downloading = true;
            axios
                .post(
                    "/api/view_post_service_picture",
                    {
                        transaction_id: this.transactionId,
                        container_number: this.containerNumber,
                        file_name: item.file_name,
                    },
                    {
                        responseType: "blob",
                    }
                )
                .then(response => {
                    const fileURL = window.URL.createObjectURL(
                        new Blob([response.data], { type: "image/png" })
                    );
                    const fileLink = document.createElement("a");
                    fileLink.href = fileURL;
                    fileLink.target = "_blank";
                    document.body.appendChild(fileLink);
                    fileLink.click();
                    document.body.removeChild(fileLink);
                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                    this.downloading = false;
                    this.overlay = false;
                });
        },
        getJobOrderServicesByDamageId: function(jobOrderId, damageId) {
            this.overlay = true;
            this.loading = true;
            this.axios.post(
                '/api/get_job_order_services_by_damage_id',
                {
                    job_order_id: jobOrderId,
                    damage_id: damageId,
                }
            ).then(response => {
                // console.log(response.data);
                this.jobOrderServices = response.data;
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },
        downloadPicture: function () {
            this.overlay = true;
                axios
                    .post(
                    "/api/download_picture",
                    {
                        container_number: this.containerNumber,
                        transaction_id: this.transactionId,
                        name: this.pictureFileName,
                    },
                    {
                        responseType: "blob",
                    }
                    )
                    .then((response) => {
                    this.overlay = false;
                    const fileURL = window.URL.createObjectURL(
                        new Blob([response.data], { type: "image/png" })
                    );
                    const fileLink = document.createElement("a");
                    fileLink.href = fileURL;
                    fileLink.target = "_blank";
                    document.body.appendChild(fileLink);
                    fileLink.click();
                    document.body.removeChild(fileLink);
                });
        },
        openDamagePicturesDialog: function(item) {
            this.damagePicturesDialog = true;
        },
        downloadDamagePicture: function (item) {
            // console.log(item)
        this.overlay = true;
            axios
                .post(
                "/api/download_damage_picture",
                {
                    container_number: this.containerNumber,
                    transaction_id: this.transactionId,
                    name: item.file_name,
                },
                {
                    responseType: "blob",
                }
                )
                .then((response) => {
                this.overlay = false;
                const fileURL = window.URL.createObjectURL(
                    new Blob([response.data], { type: "image/png" })
                );
                const fileLink = document.createElement("a");
                fileLink.href = fileURL;
                fileLink.target = "_blank";
                document.body.appendChild(fileLink);
                fileLink.click();
                document.body.removeChild(fileLink);
            });
        },
        openPostServicePicturesDialog: function(item) {
            // console.log(item)
            this.currentJobOrderServiceId = item.job_order_service_id;
            this.postServicePicturesDialog = true;
            this.getPostServicePictures(this.currentJobOrderServiceId);
        },
        addFiles: function() {
            this.formData = new FormData();

            if( typeof this.userFiles === 'undefined' || this.userFiles.length === 0 ) {
                return false;
            }

            for(var file of this.userFiles) {
                if( typeof file === 'undefined' ) {
                    return false;
                }
                if (!file.name.match(/png|jpg$/i)) {
                    const payload = {
                        title: "Invalid file chosen..",
                        msg: "You tried to upload invalid type of file...you can upload png/jpg only..",
                    };
                    this.$store.commit("showAlert", payload);
                    return false;
                } else {
                    // this.formData.append("file", file);
                    const canvas = this.$refs.canvas;
                    const ctx = canvas.getContext('2d');
                    const img = new Image();
                    const self = this;
                    let NEW_SIZE = 800;

                    img.onload = function() {
                        const aspectRatio = img.naturalHeight / img.naturalWidth;
                        canvas.width = NEW_SIZE;
                        canvas.height = NEW_SIZE * aspectRatio;
                        ctx.drawImage(img, 0, 0, NEW_SIZE, NEW_SIZE * aspectRatio);
                        const imageData = canvas.toDataURL('image/jpeg');
                        imageData.replace('data:image/jpeg;base64,', '');
                        let blobToFile = self.dataURItoBlob(imageData);
                        const cfile = new File([blobToFile], "test.jpeg",
                            {
                                type: "image/jpeg",
                                lastModified: Date.now()
                            }
                        );
                        self.formData.append('file', cfile);
                        self.formData.append("user_id", self.$store.state.user.id);
                        self.formData.append('container_number', self.containerNumber);
                        self.formData.append('transaction_id', self.transactionId);
                        self.formData.append('picture_id', self.currentDamage.picture_id);
                        self.formData.append('filename', self.currentDamage.picture.file_name);
                        self.formData.append('job_order_service_id', self.currentJobOrderServiceId);
                        self.formData.append('seq_no', self.postServicePictures.length + 1);

                        self.overlay = true;
                        self.loading = true;

                        self.axios
                            .post(
                            "/api/save_post_service_file",
                            self.formData,
                            {
                                onUploadProgress: this.onUpload,
                            },
                            {
                                headers: {
                                "Content-Type": "multipart/form-data",
                                },
                            }
                            )
                            .then((response) => {
                            if (response.status === 200) {
                                // this.areas.splice(this.areas.indexOf(a), 1);
                                // this.userFiles.splice(i, 1);
                                // this.progressLinears.splice(i, 1);
                                self.userFiles = [];
                                self.getPostServicePictures(self.currentJobOrderServiceId);
                                self.getJobOrderServicesByDamageId(self.jobOrderId, self.damageId);
                                self.getDamage();
                            }
                        }).catch(error => {
                            console.log(error);
                        }).finally(() => {
                            self.overlay = false;
                            self.loading = false;
                        });
                    }
                    img.src = URL.createObjectURL(file);
                }
            }
        },
        dataURItoBlob: function(dataURI) {
            var byteString = atob(dataURI.split(',')[1]);
            var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
            var arrayBuffer = new ArrayBuffer(byteString.length);
            var _ia = new Uint8Array(arrayBuffer);
            for (var i = 0; i < byteString.length; i++) {
                _ia[i] = byteString.charCodeAt(i);
            }
            var dataView = new DataView(arrayBuffer);
            var blob = new Blob([dataView], {type: mimeString});
            return blob;
        },
    },
    filters: {
        formatDate: function (date) {
            return date !== null ? moment(date).format("MM-DD-YYYY hh:mm:ss A") : '';
        },
    },
}
</script>
