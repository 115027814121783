<template>
    <v-container>
        <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-card class="mx-auto px-2 pt-2">
            <v-toolbar flat dark class="indigo lighten-3">
                <v-toolbar-title>Job Order Services</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <v-data-table
                :headers="headers"
                :items="items"
                :loading="loading"
                :search="search"
                >
                    <template v-slot:top>
                        <v-text-field
                            outlined
                            dense
                            prepend-inner-icon="mdi-magnify"
                            label="Search by text..."
                            v-model="search"
                        ></v-text-field>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
export default {
    data() {
        return {
            overlay: false,
            loading: false,
            headers: [
                {
                    text: "JobOrderServiceId",
                    value: "job_order_service_id",
                    sortable: true,
                },
                {
                    text: "JobOrderId",
                    value: "job_order_id",
                    sortable: true,
                },
                {
                    text: "ServiceId",
                    value: "service_id",
                    sortable: true,
                },
                {
                    text: "quantity",
                    value: "quantity",
                    sortable: true,
                },
            ],
            items: [],
            search: null,
        }
    },
    mounted() {
        if(this.$store.state.user.role === 'admin') {
            location.href = '/transactions';
        }

        this.overlay = true;
        this.loading = true;

        this.axios.post('/api/get_job_order_services')
            .then(response => {
                if(response.status === 200) {
                    this.items = response.data;
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
    }
}
</script>
