<template>
    <div>
        <!-- <v-row>
            <v-col>
                <v-select
                    class="my-2"
                    dense
                    outlined
                    label="QR Size"
                    placeholder="QR Size"
                    :items="sizes"
                    v-model="size"
                    @change="changeQrSize()"
                ></v-select>
            </v-col>
        </v-row> -->
         <!-- <v-row>
            <v-col>
                <v-select
                    class="my-2"
                    dense
                    outlined
                    label="Action"
                    placeholder="Action"
                    :items="actions"
                    v-model="action"
                    @change="changeQrStatus()"
                ></v-select>
            </v-col>
         </v-row> -->
        <v-row>
            <v-col align="center" justify="center">
                {{ name }}
            </v-col>
        </v-row>
        <v-row>
            <v-col align="center" justify="center">
                EmployeeCode: {{ employee_code }}
            </v-col>
        </v-row>
        <v-row>
            <v-col align="center" justify="center">
                Email: {{ email }}
            </v-col>
        </v-row>
        <v-row>
            <v-col align="center" justify="center">
                <span v-html="qr" style="height: 20px;"></span>
            </v-col>
        </v-row>
    </div>
</template>
<script>
export default {
    data() {
        return {
            qr: null,
            size: '200',
            sizes: [100, 200, 300],
            user: null,
            name: '',
            email: '',
            employee_code: '',
            actions: [
                'In / Out',
                'Early Out',
                'OT In',
                'OT Out'
            ],
            action: ''
        }
    },
    mounted() {
        this.name = this.$store.state.user.name;
        this.email = this.$store.state.user.email;
        this.employee_code = this.$store.state.user.id;
        this.changeQrStatus();
    },
    methods: {
        changeQrStatus: function() {
            axios.post(
                '/api/generate_qr_code',
                {
                    size: this.size,
                    employee_code: this.employee_code,
                }
            ).then(response => {
                this.qr = response.data;
                console.log('test');
            }).catch(error => {
                console.log(error);
            });
        }
    }
}
</script>
