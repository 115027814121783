<template>
    <v-container>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-card class="mx-auto px-2 pt-2">
            <v-toolbar flat dark class="indigo lighten-3">
                <v-toolbar-title>Survey</v-toolbar-title>
            </v-toolbar>
            <v-card-text>

                <v-data-table
                    :headers="headers"
                    :items="transactions"
                    :loading="loading"
                    hide-default-footer
                >
                    <template v-slot:top>
                        <v-tabs
                            v-model="tab"
                            center-active
                            dark
                        >
                            <v-tabs-slider color="yellow"></v-tabs-slider>
                            <v-tab
                                v-for="(status, i) in surveyStatuses"
                                :key="i"
                            >
                                {{status}}
                            </v-tab>
                        </v-tabs>
                        <v-text-field
                            class="my-2"
                            prepend-inner-icon="mdi-magnify"
                            v-model="search"
                            label="Search Container#..."
                            placeholder="Search Container#..."
                            outlined
                            dense
                            @keyup="searchByKeyword()"
                        ></v-text-field>
                        <!--Data Picker start-->
                        <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-if="$store.state.user.role == 'su' || $store.state.user.role == 'surveyor' || $store.state.user.role == 'admin'"
                                    v-model="dateRangeText"
                                    label="Date Rnage"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="dates = []"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="dates"
                                range
                            ></v-date-picker>
                        </v-menu>
                        <!--Data Picker finish-->
                        <v-btn
                            block
                            dark
                            color="blue"
                            @click="openSurveyScoreboardDialog()"
                            class="my-2"
                            max-width="100%"
                        >
                            <v-icon>mdi-list-box</v-icon>
                            survey scoreboard
                        </v-btn> 

                        <p># of records: <span style="color: red; font-weight: bold;">{{totalNumberOfRecords}}</span></p>
                    </template>
                    <!-- <template v-slot:[`item.progress`]="{ item }">
                        <v-badge
                            color="red"
                            :content="item.not_yet_started ? item.not_yet_started : '0'"
                            overlap
                            bordered
                        >
                        <v-chip color="red" dark x-small>Not Yet Started</v-chip>
                        </v-badge>
                        <v-badge
                            color="red"
                            :content="item.ongoing ? item.ongoing : '0'"
                            overlap
                            bordered
                        >
                        <v-chip color="blue" dark x-small>Ongoing</v-chip>
                        </v-badge>
                        <v-badge
                            color="red"
                            :content="item.finished ? item.finished : '0'"
                            overlap
                            bordered
                        >
                        <v-chip color="green" dark x-small>Finished</v-chip>
                        </v-badge>
                    </template> -->
                    <template v-slot:[`item.repair_estimate`]="{ item }">
                        <v-btn
                            color="blue"
                            dark
                            small
                            v-if="item.survey_id"
                            @click="openRepairEstimateDialog(item)"
                        >
                            <v-icon>mdi-image</v-icon>
                            Repair Estimate
                        </v-btn>
                    </template>
                    <template v-slot:[`item.created_at`]="{ item }">
                        {{ item.created_at | formatDate }}
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-btn
                            v-if="tab != 3 && (item.remark_id !== 'R-02' || item.remark_id !== 'R-03')"
                            color="orange"
                            dark
                            small
                            :to="`/take_picture2/${item.container_number}/${item.transaction_id}`"
                        >
                            <v-icon>mdi-image</v-icon> <v-icon>mdi-magnify-expand</v-icon>
                        </v-btn>
                        <v-btn
                            v-if="tab == 3 && (item.remark_id !== 'R-02' || item.remark_id !== 'R-03')"
                            color="blue"
                            dark
                            small
                            :disabled="item.job_order != undefined"
                            @click="backToSurvey(item)"
                        >
                            <v-icon>mdi-undo</v-icon>
                            Back to survey
                        </v-btn>
                    </template>
                    <template v-slot:[`item.create_job_order`]="{ item }">
                        <div v-if="!item.job_order_id">
                            <v-btn
                                v-if="tab == 3"
                                color="orange"
                                dark
                                small
                                @click="createJobOrder(item)"
                            >
                                <v-icon>mdi-hammer-wrench</v-icon> Job Order
                            </v-btn>
                        </div>
                        <div v-else>
                            {{item.job_order_id}}
                        </div>
                    </template>
                </v-data-table>
                <div class="text-center pt-2">
                    <v-pagination
                        v-model="page"
                        :length="pageCount"
                        total-visible="10"
                        dark
                        @input="changePage()"
                    ></v-pagination>
                </div>
            </v-card-text>
        </v-card>

        <!-- imagesDialog start -->
        <v-dialog v-model="imagesDialog" fullscreen>
            <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
            <v-card flat>
                <v-container>
                    <v-toolbar flat dark class="indigo lighten-3">
                        <v-toolbar-title
                        >Pictures -- ContainerNumber: {{currentContainerNumber}} --</v-toolbar-title
                        >
                        <v-spacer></v-spacer>
                        <v-btn
                            icon
                            dark
                            @click="imagesDialog = false"
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <v-data-table
                            :headers="pictureHeaders"
                            :items="pictures"
                            :items-per-page="-1"
                        >
                            <template v-slot:[`item.actions`]="{ item }">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon
                                            color="green"
                                            @click="downloadPicture(item)"
                                            large
                                            v-on="on"
                                        >mdi-image</v-icon>
                                    </template>
                                    <span>View Picture</span>
                                </v-tooltip>
                            </template>
                            <template v-slot:[`item.created_at`]="{ item }">
                                {{ item.created_at | formatDate }}
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-container>
            </v-card>
        </v-dialog>
        <!--imagesDialog end -->
        <!-- repairEstimateDialog start -->
        <v-dialog v-model="repairEstimateDialog" fullscreen>
            <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
            <v-card flat>
                <v-container>
                    <v-toolbar flat dark class="indigo lighten-3">
                        <v-toolbar-title
                        >Repair Estimate -- ContainerNumber: {{currentContainerNumber}} --</v-toolbar-title
                        >
                        <v-spacer></v-spacer>
                        <v-btn
                            icon
                            dark
                            @click="repairEstimateDialog = false"
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <v-btn
                            block
                            dark
                            color="green"
                            @click="spreadSheetGenerationTest()"
                            class="my-2"
                        >
                            <v-icon>mdi-microsoft-excel</v-icon>
                            download repair estimate
                        </v-btn>                         
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th>Date & Time</th>
                                    <th>Container#</th>
                                    <th>Size</th>
                                    <th>Type</th>
                                    <th>Class</th>
                                    <th>Mfg. Date</th>
                                    <th>EIR No.</th>
                                    <th>Vessel & Voyage</th>
                                    <th>CEDEX Code</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td></td>
                                    <td>{{ currentContainerNumber }}</td>
                                    <td>{{ currentContainerSize }}</td>
                                    <td>{{ currentContainerType }}</td>
                                    <td>{{ currentContainerClass }}</td>
                                    <td>{{ currentManufacturingDate }}</td>
                                    <td>{{ currentTransactionId }}</td>
                                    <td></td>
                                    <td>{{ currentCedexCode }}</td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                        <v-divider class="my-5"></v-divider>
                        <v-simple-table>
                            <thead>
                                <tr>
                                    <th>ServiceId</th>
                                    <th>PartName</th>
                                    <th>Desc.</th>
                                    <th>Location</th>
                                    <th>WayOfRepair</th>
                                    <th>Hour</th>
                                    <th>MaterialCost</th>
                                    <th>LaborCost</th>
                                    <th>TotalAmount</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(jos, i) in currentJobOrderServices" :key="i">
                                    <td>{{ jos.service.service_id }}</td>
                                    <td>{{ jos.service.part_name }}</td>
                                    <td>{{ jos.service.description }}</td>
                                    <td>{{ jos.service.location }}</td>
                                    <td>{{ jos.service.way_of_repair }}</td>
                                    <td>{{ jos.service.hour }}</td>
                                    <td>{{ jos.service.material_cost }}</td>
                                    <td>{{ jos.service.labor_cost }}</td>
                                    <td>{{ jos.service.material_cost + jos.service.labor_cost }}</td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                        <v-divider class="my-5"></v-divider>
                        <v-toolbar flat dark class="indigo lighten-3">
                            <v-toolbar-title
                            >Service Picture(s)</v-toolbar-title
                            >
                        </v-toolbar>     
                        <v-divider class="my-5"></v-divider>                   
                        <v-row>
                            <v-col
                            v-for="(url, i) in pictureTempUrls"
                            :key="i"
                            class="d-flex child-flex"
                            cols="4"
                            >
                                <a :href="url" target="_blank">
                                    <v-img
                                        :src="url"

                                        aspect-ratio="1"
                                        class="grey lighten-2"
                                    >
                                        <template v-slot:placeholder>
                                        <v-row
                                            class="fill-height ma-0"
                                            align="center"
                                            justify="center"
                                        >
                                            <v-progress-circular
                                            indeterminate
                                            color="grey lighten-5"
                                            ></v-progress-circular>
                                        </v-row>
                                        </template>
                                    </v-img>
                                </a>
                            </v-col>
                        </v-row>
                        <v-divider class="my-5"></v-divider>   
                        <v-toolbar flat dark class="indigo lighten-3">
                            <v-toolbar-title
                            >Damage Picture(s)</v-toolbar-title
                            >
                        </v-toolbar>     
                        <v-divider class="my-5"></v-divider>     
                        <v-row>
                            <v-col
                            v-for="(url, i) in damagePictureTempUrls"
                            :key="i"
                            class="d-flex child-flex"
                            cols="4"
                            >
                                <a :href="url" target="_blank">
                                    <v-img
                                        :src="url"

                                        aspect-ratio="1"
                                        class="grey lighten-2"
                                    >
                                        <template v-slot:placeholder>
                                        <v-row
                                            class="fill-height ma-0"
                                            align="center"
                                            justify="center"
                                        >
                                            <v-progress-circular
                                            indeterminate
                                            color="grey lighten-5"
                                            ></v-progress-circular>
                                        </v-row>
                                        </template>
                                    </v-img>
                                </a>
                            </v-col>
                        </v-row>                                             
                    </v-card-text>
                </v-container>
            </v-card>
        </v-dialog>
        <!--repairEstimateDialog end-->
        <!-- surveyScoreboardDialog start -->
        <v-dialog v-model="surveyScoreboardDialog" fullscreen>
            <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
            <v-card flat>
                <v-container>
                    <v-toolbar flat dark class="indigo lighten-3">
                        <v-toolbar-title>
                            Survey Scorboard - {{ dates[0] + ' ~ ' + dates[1] }} - 
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-btn
                            icon
                            dark
                            @click="surveyScoreboardDialog = false"
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <v-btn
                            block
                            dark
                            color="green"
                            @click="downloadSurveyScoreboardExcel()"
                            class="my-2"
                            max-width="100%"
                        >
                            <v-icon>mdi-microsoft-excel</v-icon>
                            Download Excel
                        </v-btn> 
                        <v-data-table
                            :headers="surveyScoreboardHeaders"
                            :items="surveyScoreboardData"
                        ></v-data-table>
                    </v-card-text>
                </v-container>
            </v-card>
        </v-dialog>
        <!-- surveyScoreboardDialog end -->        
    </v-container>
</template>
<script>
import moment from "moment";

export default {
    data() {
        return {
            search: null,
            overlay: false,
            loading: false,
            imagesDialog: false,
            tab: 0,
            pageCount: 0,
            page: 1,
            itemsPerPage: 30,
            totalNumberOfRecords: null,
            surveyStatuses: [
                'Not Yet Submitted',
                'Not Yet Started',
                'Ongoing',
                'Finished'
            ],
            menu: false,
            dates: [
                `${moment().format('YYYY')}-${moment().format('MM')}-01`,
                `${moment().format('YYYY')}-${moment().format('MM')}-${moment().daysInMonth()}`
            ],
            headers: [
                {
                    text: "transaction_id",
                    value: "transaction_id",
                    sortable: false,
                },
                {
                    text: "survey_id",
                    value: "survey_id",
                    sortable: false,
                },
                {
                    text: "RepairEstimate",
                    value: "repair_estimate",
                    sortable: false,
                },
                {
                    text: "container#",
                    value: "container_number",
                    sortable: false,
                },
                {
                    text: "SurveyStatus",
                    value: "survey.status_code",
                    sortable: false,
                },
                // {
                //     text: "SurveyProgress",
                //     value: "progress",
                //     sortable: true,
                // },
                {
                    text: "created_by",
                    value: "created_by",
                    sortable: false,
                },
                {
                    text: "created_at",
                    value: "created_at",
                    sortable: false,
                },
                // {
                //     text: "pictures",
                //     value: "pictures",
                //     sortable: false,
                // },
                {
                    text: "Take Photos & Survey Damage",
                    value: "actions",
                    sortable: false,
                },
                {
                    text: "Create Job Order",
                    value: "create_job_order",
                    sortable: false,
                },
            ],
            transactions: [],
            currentItem: null,
            currentContainerNumber: null,
            currentContainerSize: null,
            currentContainerType: null,
            currentContainerClass: null,
            currentManufacturingDate: null,
            currentTransactionId: null,
            currentCedexCode: null,
            currentJobOrderServices: [],
            pictureHeaders: [
                {
                    text: "FileName",
                    value: "file_name",
                    sortable: true,
                },
                {
                    text: "Status",
                    value: "status",
                    sortable: true,
                },
                {
                    text: "CreatedAt",
                    value: "created_at",
                    sortable: true,
                },
                {
                    text: "Actions",
                    value: "actions",
                    sortable: true,
                }
            ],
            pictures: [],
            statuses: [],
            remarks: ['For Taking Photos', 'PASS', 'REJECTED', 'EMPTYPOOL'],
            repairEstimateDialog: false,
            pictureTempUrls: null,
            damagePictureTempUrls: null,
            surveyScoreboardDialog: false,
            surveyScoreboardData: [],
            surveyScoreboardHeaders: [
                {
                    text: "Company",
                    value: "COMPANY",
                    sortable: true,
                },  
                {
                    text: "PASS",
                    value: "PASS",
                    sortable: true,
                },  
                {
                    text: "REJECTED",
                    value: "REJECTED",
                    sortable: true,
                },  
                {
                    text: "SURVEY ONLY",
                    value: "SURVEY ONLY",
                    sortable: true,
                },  
                {
                    text: "Total",
                    value: "TOTAL",
                    sortable: true,
                },  

            ]
        }
    },
    mounted() {


        // return false;
        if( this.$store.state.user.role == 'mrstaff' || this.$store.state.user.role == 'user' || this.$store.state.user.role == 'qronly' || this.$store.state.user.role == 'hrassistant'  ) {
            alert('You have not access right to enter the area...');
            this.$router.push('/');
        }
        if( this.$route.params.transaction_id && this.$route.params.tab ) {
            this.search = this.$route.params.transaction_id;
            this.tab = parseInt(this.$route.params.tab);
        } else {
            this.search = null;
            this.tab = 0;
        }

        // return false;

        this.loadTransactions(this.tab);
        this.getStatuses();
        // this.getRemarks();
    },
    methods: {
        loadTransactions: function(tab) {
            this.overlay = true;
            this.loading = true;
            this.axios.post(
                '/api/get_transactions_for_taking_photos',
                // '/api/get_surveys',
                {
                    user: this.$store.state.user,
                    tab: this.tab,
                    page: this.page,
                    itemsPerPage: this.itemsPerPage,
                    search: this.search,
                    dates: this.dates,
                }
            ).then(response => {
                    // console.log(response.data);
                    if(response.status === 200) {
                        if(response.status === 200) {
                            this.transactions = response.data.data;
                            this.totalNumberOfRecords = response.data.total;
                            this.pageCount = Math.ceil(response.data.total / this.itemsPerPage);
                        }
                    } else {
                        this.$store.commit('logOut');
                    }

                }).catch(error => {
                    console.log(error);
                    this.$store.commit('logOut');
                }).finally(() => {
                    this.overlay = false;
                    this.loading = false;
                });
        },
        getStatuses: function() {
            this.axios.post('/api/get_statuses')
                .then(response => {
                    this.statuses = response.data;
                }).catch(error => {
                    console.log(error);
                });
        },
        // getRemarks: function() {
        //     this.axios.post('/api/get_remarks')
        //         .then(response => {
        //             this.remarks = response.data;
        //         }).catch(error => {
        //             console.log(error);
        //         });
        // },
        openImagesDialog: function(item) {
            this.imagesDialog = true;
            this.currentItem = item;
            this.currentContainerNumber = this.currentItem.container_number;
            this.pictures = this.currentItem.pictures;
        },
        downloadPicture: function (item) {
        this.overlay = true;
        axios
            .post(
            "/api/download_picture",
            {
                container_number: item.container_number,
                transaction_id: item.transaction_id,
                name: item.file_name,
            },
            {
                responseType: "blob",
            }
            )
            .then((response) => {
            this.overlay = false;
            const fileURL = window.URL.createObjectURL(
                new Blob([response.data], { type: "image/png" })
            );
            const fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.target = "_blank";
            document.body.appendChild(fileLink);
            fileLink.click();
            document.body.removeChild(fileLink);
            });
        },
        backToSurvey: function(item) {
            this.overlay = true;
            this.loading = true;
            this.axios.post(
                '/api/back_to_survey',
                {
                    survey_id: item.survey_id,
                }
            ).then(response => {
                if(response.status === 200) {
                    // console.log(response.data);
                    // this.$router.push('/survey/2/' + item.transaction_id);
                    this.tab = 2;
                }
                }).catch(error => {
                    console.log(error);
                    this.$store.commit('logOut');
                }).finally(() => {
                    this.overlay = false;
                    this.loading = false;
                });
        },
        createJobOrder: function(item) {
            if(!confirm('Is it ok to create a job order for the survey result?')) {
                    return false;
                }
            this.overlay = true;
            this.loading = true;

            this.axios.post(
                '/api/create_job_order',
                {
                    transaction_id: item.transaction_id,
                    survey_id: item.survey_id,
                    container_number: item.container_number,
                    user_id: this.$store.state.user.id,
                }
            ).then(response => {
                console.log(response.data);
                if(response.status === 200) {
                    const payload = {
                        title: "New Job Order created!",
                        msg: "You have created a new job order successfully...",
                    };
                    this.$store.commit("showAlert", payload);
                    location.href = '/job_orders';
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },
        openRepairEstimateDialog: function(item) {
            this.repairEstimateDialog = true;
            this.currentContainerNumber = item.container_number;
            this.currentContainerNumber = item.container_number;
            this.currentContainerSize = item.size.size;
            this.currentContainerType = item.type.type;
            this.currentContainerClass = item.class.class;
            this.currentManufacturingDate = item.manufacturing_date;
            this.currentTransactionId = item.transaction_id;
            this.currentCedexCode = item.cedex_code;
            if(item.job_order) {
                // console.log(item)
                this.currentJobOrderServices = item.job_order.job_order_services;
            }
            this.getPictureTempUrls();
            this.getDamagePictureTempUrls();
        },
        getPictureTempUrls: function() {
            this.overlay = true;
            this.loading = true;
            this.axios.post(
                '/api/get_picture_temp_urls',
                {
                    container_number: this.currentContainerNumber,
                    transaction_id: this.currentTransactionId,
                },
                // {
                //     responseType: "blob",
                // }
            ).then(response => {
                // console.log(response.data[0])
                this.pictureTempUrls = response.data;
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },
        getDamagePictureTempUrls: function() {
            this.overlay = true;
            this.loading = true;
            this.axios.post(
                '/api/get_damage_picture_temp_urls',
                {
                    container_number: this.currentContainerNumber,
                    transaction_id: this.currentTransactionId,
                },
                // {
                //     responseType: "blob",
                // }
            ).then(response => {
                // console.log(response.data)
                this.damagePictureTempUrls = response.data;
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },
        changePage: function() {
            this.loadTransactions(this.tab);
        },
        searchByKeyword: function() {
            this.loadTransactions(this.tab);
        },
        spreadSheetGenerationTest: function() {
            this.overlay = true;
            this.loading = true;
            axios.post(
                '/api/spreadsheet_generation_test',
                {
                    container_number: this.currentContainerNumber,
                    transaction_id: this.currentTransactionId,
                    currentContainerNumber: this.currentContainerNumber,
                    currentContainerClass: this.currentContainerClass,
                    currentContainerType: this.currentContainerType,
                    currentContainerSize: this.currentContainerSize,
                    currentManufacturingDate: this.currentManufacturingDate,
                    currentTransactionId: this.currentTransactionId,
                    currentCedexCode: this.currentCedexCode,
                    currentJobOrderServices: this.currentJobOrderServices
                }
            ).then(response => {
                // console.log(response.data['file'])
                // console.log(this.dates)
                // return false;
                this.downloading = false;
                this.overlay = false;
                const fileLink = document.createElement("a");
                fileLink.href = response.data['file'];
                fileLink.download = 'RepairEstimate_' + moment().format('YYYYMMDD') + '.xlsx';
                fileLink.target = "_blank";
                document.body.appendChild(fileLink);
                fileLink.click();
                document.body.removeChild(fileLink);
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },
        downloadSurveyScoreboardExcel: function() {
            axios.post(
                '/api/download_survey_scoreboard_excel',
                {
                    dates: this.dates
                }
            ).then(response => {
                this.downloading = false;
                this.overlay = false;
                const fileLink = document.createElement("a");
                fileLink.href = response.data['file'];
                fileLink.download = 'SurveyScoreboard_' + moment().format('YYYYMMDD') + '.xlsx';
                fileLink.target = "_blank";
                document.body.appendChild(fileLink);
                fileLink.click();
                document.body.removeChild(fileLink);
            }).catch(error => {
                console.log(error);
            });
        },
        getSurveyScoreboardData: function() {
            axios.post(
                '/api/get_survey_scoreboard_data',
                {
                    dates: this.dates
                }
            ).then(response => {
                this.downloading = false;
                this.overlay = false;
                this.surveyScoreboardData = response.data;
                // console.log(response.data);
            }).catch(error => {
                console.log(error);
            });
        },
        openSurveyScoreboardDialog: function() {
            this.surveyScoreboardDialog = true;
            this.getSurveyScoreboardData();
        }
    },
    filters: {
        formatDate: function (date) {
            return date !== null ? moment(date).format("MM-DD-YYYY hh:mm:ss A") : '';
        },
    },
    watch: {
        tab: function(oldVal, newVal) {
            // console.log(this.tab)
            this.loadTransactions(this.tab);
            this.$router.push('/survey/' + this.tab);
        },
        dates: function() {
            if (this.dates.length == 0 && this.dates.length == 1) {
                return false;
            } else if (this.dates.length == 2) {
                if ( this.dates[0] > this.dates[1] ) {
                    alert('Invalid date selection');
                    this.dates = [];
                    return false;
                } else {
                    this.page = 1;
                    this.loadTransactions(this.tab);
                }
            }
        }
    },
    computed: {
        dateRangeText() {
            return this.dates.join(' ~ ');
        }
    },
}
</script>
