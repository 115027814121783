<template>
    <div>
        <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-card flat>
            <v-container>
                <v-toolbar flat dark class="indigo lighten-3">
                    <v-toolbar-title> Picture Uploading Form: {{ containerNumber }} </v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-list>
                            <v-toolbar flat dark color="green lighten-3 my-2">
                                <v-toolbar-title>Encode Container details: {{ containerNumber }}</v-toolbar-title>
                            </v-toolbar>
                            <v-list-item>
                                <v-list-item-content>

                                    <v-menu
                                        v-model="menu"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="manufacturingDate"
                                            label="ManufacturingDate"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            outlined
                                            dense
                                            @input="updateContainerDetails()"
                                        ></v-text-field>
                                        </template>
                                        <v-date-picker
                                        v-model="manufacturingDate"
                                        @input="menu = false"
                                        ></v-date-picker>
                                    </v-menu>
                                    <!-- <v-text-field
                                        outlined
                                        dense
                                        v-model="manufacturingDate"
                                        label="ManufacturingDate"
                                        @blur="updateContainerDetails()"
                                    ></v-text-field> -->
                                    <v-text-field
                                        outlined
                                        dense
                                        v-model="tareWeight"
                                        label="TareWeight"
                                        @blur="updateContainerDetails()"
                                    ></v-text-field>
                                    <v-text-field
                                        outlined
                                        dense
                                        v-model="massGrossWeight"
                                        label="MassGrossWeight"
                                        @blur="updateContainerDetails()"
                                    ></v-text-field>
                                    <v-text-field
                                        outlined
                                        dense
                                        v-model="brokerName"
                                        label="BrokerName"
                                        @blur="updateContainerDetails()"
                                    ></v-text-field>
                                    <v-text-field
                                        outlined
                                        dense
                                        v-model="plateNumber"
                                        label="PlateNumber"
                                        @blur="updateContainerDetails()"
                                    ></v-text-field>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" v-if="showPictureUploadingSlots">
                        <v-list>
                            <v-toolbar flat dark color="indigo lighten-3 my-2">
                                <v-toolbar-title>Picture(s) Uploaded: {{ containerNumber }}</v-toolbar-title>
                            </v-toolbar>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-data-table
                                        :headers="headers"
                                        :items="areas"
                                        :items-per-page="-1"
                                        :loading="loading"
                                    >

                                        <template v-slot:[`item.overall_image`]="{ item }">
                                            <v-file-input
                                                dense
                                                outlined
                                                small-chips
                                                v-model="overallImage"
                                                prepend-icon="mdi-camera"
                                                :placeholder="`${item.area_type}-${item.area_name}`"
                                                capture="camera"
                                                accept="image/*"
                                                @change="addFiles(item, 0)"
                                                hide-input
                                            ></v-file-input>

                                            <v-icon color="blue">mdi-image</v-icon>
                                        </template>
                                        <template v-slot:[`item.damage_images`]="{ item }">
                                            <v-file-input
                                                dense
                                                outlined
                                                small-chips
                                                v-model="damageImages"
                                                prepend-icon="mdi-camera"
                                                :placeholder="`${item.area_type}-${item.area_name}`"
                                                capture="camera"
                                                accept="image/*"
                                                @change="addFiles(item, 0)"
                                                hide-input
                                            ></v-file-input>
                                            <v-icon color="blue">mdi-image</v-icon>
                                        </template>
                                            <template v-slot:[`item.actions`]="{ item }">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-icon
                                                        color="green"
                                                        @click="viewPicture(item)"
                                                        v-on="on"
                                                        v-if="!item.skipped"
                                                    >mdi-eye</v-icon>
                                                </template>
                                                <span>View Picture</span>
                                            </v-tooltip>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-icon
                                                        color="red"
                                                        @click="deletePicture(item)"
                                                        v-on="on"
                                                    >mdi-delete</v-icon>
                                                </template>
                                                <span>Delete Picture</span>
                                            </v-tooltip>
                                        </template>
                                    </v-data-table>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-col>
                </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-select
                        v-if="areas.length === 0"
                        label="Status"
                        :items="remarks"
                        item-text="remark_name"
                        item-value="remark_id"
                        outlined
                        dense
                        v-model="remark_id"
                    ></v-select>
                </v-card-actions>
                <v-card-actions>
                    <v-text-field
                        v-if="areas.length === 0"
                        outlined
                        dense
                        label="CEDEX Code"
                        v-model="cedexCode"
                    ></v-text-field>
                </v-card-actions>
                <v-card-actions>
                    <v-select
                        v-if="areas.length === 0 && remark_id === 'R-01'"
                        label="Repair Destination"
                        :items="destinations"
                        item-text="destination_type"
                        item-value="destination_id"
                        outlined
                        dense
                        v-model="destination"
                    ></v-select>
                </v-card-actions>
                <v-card-actions>
                    <v-btn
                        v-if="areas.length === 0 && remark_id !== null && cedexCode !== null"
                        color="green"
                        block
                        dark
                        @click="submitPictures"
                    >
                        <v-icon>mdi-image-move</v-icon>submit pictures
                    </v-btn>
                </v-card-actions>
            </v-container>
        </v-card>
    </div>
</template>
<script>
export default {
    data() {
        return {
            overlay: false,
            loading: false,
            menu: false,
            areas: [],
            userFiles: [],
            progressLinears: [],
            file_id: null,
            formData: null,
            remark_id: null,
            destinations: [],
            destination: null,
            cedexCode: null,
            remarks: [],
            headers: [
                {
                    text: "AreaType",
                    value: "area_type",
                    sortable: true,
                },
                {
                    text: "AreaName",
                    value: "area_name",
                    sortable: true,
                },
                {
                    text: "OverallImage",
                    value: "overall_image",
                    sortable: true,
                },
                {
                    text: "DamageImages",
                    value: "damage_images",
                    sortable: true,
                }
            ],
            pictures: [],
            containerNumber: null,
            transactionId: null,
            // email: null,
            manufacturingDate: null,
            tareWeight: null,
            massGrossWeight: null,
            brokerName: null,
            plateNumber: null,
            transactions: [],
            showPictureUploadingSlots: false,
            overallImage: null,
            damageImages: null,
        }
    },
    mounted() {
        alert('Under Construction...')
        this.containerNumber = this.$route.params.container_number;
        this.transactionId = this.$route.params.transaction_id;
        // this.email = this.$route.params.email;

        this.loadAreas();
        this.loadDestinations();
        this.loadPictures();
        this.loadRemarks();
        this.getTransaction();
    },
    methods: {
        getTransaction: function() {
            this.overlay = true;
            this.axios.post(
                '/api/get_transaction',
                {
                    container_number: this.containerNumber,
                    transaction_id: this.transactionId,
                }
            ).then(response => {
                if(response.status === 200) {
                    this.transactions = response.data;
                    // console.log(this.transactions);
                    this.manufacturingDate = this.transactions[0]['manufacturing_date'];
                    this.tareWeight = this.transactions[0]['tare_weight'];
                    this.massGrossWeight = this.transactions[0]['mass_gross_weight'];
                    this.brokerName = this.transactions[0]['broker_name'];
                    this.plateNumber = this.transactions[0]['plate_number'];

                    if(
                        this.manufacturingDate
                        &&
                        this.tareWeight
                        &&
                        this.massGrossWeight
                        &&
                        this.brokerName
                        &&
                        this.plateNumber
                    ) {
                        this.showPictureUploadingSlots = true;
                    } else {
                        this.showPictureUploadingSlots = false;
                    }
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
            });
        },
        loadAreas: function() {
            this.overlay = true;
            this.axios.post(
                '/api/get_areas',
                {
                    container_number: this.containerNumber,
                    transaction_id: this.transactionId,
                }
            ).then(response => {
                this.areas = response.data;
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
            });
        },
        loadDestinations: function() {
            this.overlay = true;
            this.axios.post(
                '/api/get_destinations'
            ).then(response => {
                this.destinations = response.data;
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
            });
        },
        loadPictures: function() {
            this.overlay = true;
            this.loading = true;

            this.axios.post(
            '/api/get_pictures_by_transaction_id',
            {
                transaction_id: this.$route.params.transaction_id
            }
        ).then(response => {
            this.pictures = response.data;
        }).catch(error => {
            console.log(error);
        }).finally(() => {
            this.overlay = false;
            this.loading = false;
        });
        },
        loadRemarks: function() {
            this.overlay = true;
            this.axios.post(
                '/api/get_remarks',
            ).then(response => {
                this.remarks = response.data;
                // console.log(this.remarks)
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
            });
        },
        addFiles: function (a, i) {


            this.file_id = i;
            this.formData = new FormData();

            if( typeof this.userFiles[i] === 'undefined' ) {
                return false;
            }

            // for(var file of this.userFiles[i]) {
                var file = this.overallImage;
                console.log(file)
                if( typeof file === 'undefined' ) {
                    return false;
                }
                if (!file.name.match(/png|jpg$/i)) {
                    const payload = {
                        title: "Invalid file chosen..",
                        msg: "You tried to upload invalid type of file...you can upload png/jpg only..",
                    };
                    this.$store.commit("showAlert", payload);
                    return false;
                } else {
                    this.formData.append("file", file);
                }
            // }

            this.formData.append("user_id", this.$store.state.user.id);
            this.formData.append('container_number', this.containerNumber);
            this.formData.append('transaction_id', this.transactionId);
            this.formData.append('area_code', a.area_code);
            this.formData.append('area_type', a.area_type);
            this.formData.append('area_name', a.area_name);

            this.overlay = true;
            this.loading = true;

            this.axios
                .post(
                "/api/save_file",
                this.formData,
                {
                    onUploadProgress: this.onUpload,
                },
                {
                    headers: {
                    "Content-Type": "multipart/form-data",
                    },
                }
                )
                .then((response) => {
                if (response.status === 200) {
                    // this.areas.splice(this.areas.indexOf(a), 1);
                    // this.userFiles.splice(i, 1);
                    // this.progressLinears.splice(i, 1);
                    console.log('test')
                    this.loadPictures();
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },

        onUpload: function (e) {
            this.$set(
                this.progressLinears,
                this.file_id,
                Math.floor((e.loaded / e.total) * 100)
            );
        },

        deletePicture: function(item) {
            if(!confirm('Is it ok to delete the picture?')) {
                return false;
            }
            this.overlay = true;
            this.loading = true;
            this.axios.post(
                '/api/delete_picture',
                {
                    id: item.id,
                    container_number: item.container_number,
                    transaction_id: item.transaction_id,
                    file_name: item.file_name,
                }
            ).then(response => {
                // console.log(response.data);
                if(response.status === 200) {
                    const payload = {
                        title: "Picture deleted",
                        msg: "The picture data has been successfully deleted...",
                    };
                    this.$store.commit("showAlert", payload);
                    this.loadAreas();
                    this.loadPictures();
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },

        viewPicture: function(item) {
            this.overlay = true;
            this.downloading = true;
            axios
                .post(
                    "/api/view_picture",
                    {
                        transaction_id: item.transaction_id,
                        container_number: item.container_number,
                        file_name: item.file_name,
                    },
                    {
                        responseType: "blob",
                    }
                )
                .then(response => {
                    const fileURL = window.URL.createObjectURL(
                        new Blob([response.data], { type: "image/png" })
                    );
                    const fileLink = document.createElement("a");
                    fileLink.href = fileURL;
                    fileLink.target = "_blank";
                    document.body.appendChild(fileLink);
                    fileLink.click();
                    document.body.removeChild(fileLink);
                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                    this.downloading = false;
                    this.overlay = false;
                });
        },

        submitPictures: function() {
            if( this.remark_id === 'R-01' && this.destination === null ) {
                alert('Select any destination!');
                return false;
            }

            this.overlay = true;
            this.loading = true;

            this.axios.post(
                '/api/submit_pictures',
                {
                    transaction_id: this.$route.params.transaction_id,
                    container_number: this.$route.params.container_number,
                    user_id: this.$store.state.user.id,
                    remark_id: this.remark_id,
                    destination_id: this.destination,
                    cedexCode: this.cedexCode,
                }
            ).then(response => {
                if(response.status === 200) {
                    const payload = {
                        title: "Pictures Submitted!",
                        msg: "The pictures have been successfully submitted...",
                    };
                    this.$store.commit("showAlert", payload);
                    location.href = '/surveys'
                    // console.log(response.data);
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },
        updateContainerDetails: function() {
            this.overlay = true;
            this.loading = true;

            this.axios.post(
                '/api/update_container_details',
                {
                    transaction_id: this.$route.params.transaction_id,
                    container_number: this.$route.params.container_number,
                    user_id: this.$store.state.user.id,
                    manufacturing_date: this.manufacturingDate,
                    tare_weight: this.tareWeight,
                    mass_gross_weight: this.massGrossWeight,
                    broker_name: this.brokerName,
                    plate_number: this.plateNumber,
                }
            ).then(response => {
                if(response.status === 200) {
                    // console.log(response.data);
                    this.getTransaction();
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },
        skipUploading: function(a, i) {

            this.formData = new FormData();
            this.formData.append("user_id", this.$store.state.user.id);
            this.formData.append('container_number', this.containerNumber);
            this.formData.append('transaction_id', this.transactionId);
            this.formData.append('area_code', a.area_code);
            this.formData.append('area_type', a.area_type);
            this.formData.append('area_name', a.area_name);

            this.overlay = true;
            this.axios
                .post(
                "/api/skip_file",
                this.formData,
                {
                    onUploadProgress: this.onUpload,
                },
                {
                    headers: {
                    "Content-Type": "multipart/form-data",
                    },
                }
                )
                .then((response) => {
                if (response.status === 200) {

                    this.areas.splice(this.areas.indexOf(a), 1);
                    this.userFiles.splice(i, 1);
                    this.progressLinears.splice(i, 1);

                    this.loadPictures();
                    this.overlay = false;
                }
                });
        }
    }
}
</script>
