<template>
    <v-container>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-card class="mx-auto px-2 pt-2">
            <!-- <v-btn @click="test">test</v-btn> -->
            <v-toolbar flat dark class="indigo lighten-3">
                <v-toolbar-title>TimeIn / TimeOut *WFH</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <div class="text-center pt-2">
                    <v-pagination
                        v-model="page"
                        :length="pageCount"
                        total-visible="10"
                        dark
                        @input="changePage()"
                    ></v-pagination>
                </div>   
                <v-btn 
                    class="lighten-2 my-2" 
                    color="red" 
                    block 
                    dark
                    @click="insertNewTimeInRecordForWfh()"
                >
                    <v-icon>mdi-login-variant</v-icon>
                    time in *Insert new record
                </v-btn>

                <v-data-table
                    :headers="headers"
                    :items="attendanceData"
                    :loading="loading"
                    :search="search"
                    hide-default-footer
                >
                    <template v-slot:top>                     
                        <v-text-field
                            outlined
                            dense
                            prepend-inner-icon="mdi-magnify"
                            label="Search by text..."
                            v-model="search"
                        ></v-text-field>
                    </template>           
                    <template v-slot:[`item.day`]="{ item }">
                        {{ item.in | getDay }}
                    </template>                           
                    <template v-slot:[`item.out`]="{ item }">
                        <div v-if="!item.out">
                            <div v-if="item.in">
                                <v-btn 
                                    small
                                    dark
                                    @click="updateOut(item)"
                                >
                                    <v-icon>mdi-exit-run</v-icon>
                                    Out
                                </v-btn>    
                            </div>                    
                        </div>
                        <div>
                            {{ item.out }}
                        </div>
                    </template>                                           
                    <template v-slot:[`item.actions`]="{ item }">
                        <v-icon
                            color="red"
                            @click="deleteRecord(item)"
                        >
                            mdi-delete
                        </v-icon>
                    </template>                       
                </v-data-table>
                <div class="text-center pt-2">
                    <v-pagination
                        v-model="page"
                        :length="pageCount"
                        total-visible="10"
                        dark
                        @input="changePage()"
                    ></v-pagination>
                </div>                  
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
export default {
    data() {
        return {
            overlay: false,
            loading: false,
            page: 1,
            pageCount: 0,
            itemsPerPage: 5,
            headers: [
            {
                    text: "Id",
                    value: "id",
                    sortable: false,
                },
                {
                    text: "Date",
                    value: "date",
                    sortable: false,
                },
                {
                    text: "Day",
                    value: "day",
                    sortable: false,
                },
                {
                    text: "UserName",
                    value: "user.name",
                    sortable: false,
                },
                {
                    text: "In",
                    value: "in",
                    sortable: false,
                },
                {
                    text: "Out",
                    value: "out",
                    sortable: false,
                },
                {
                    text: "Hrs Work",
                    value: "hrs_work",
                    sortable: false,
                },
                {
                    text: "Actions",
                    value: "actions",
                    sortable: false,
                },                
            ],
            attendanceData: [],
            search: null,
        }
    },
    mounted() {
        if( this.$store.state.user.is_wfh == 0 ) {
            alert('You have no access right to this page...');
            this.$router.push('/transactions');
        }        
        this.loadAttendanceDataForWfh();
    },
    methods: {
        loadAttendanceDataForWfh: function() {
            this.overlay = true;
            this.loading = true;
            this.axios.post(
                '/api/get_attendance_data_for_wfh',
                {
                    employee_code: this.$store.state.user.id,
                    page: this.page,
                    itemsPerPage: this.itemsPerPage,
                    search: this.search,
                }
            ).then(response => {
                // console.log(response.data);
                if(response.status === 200) {
                    this.attendanceData = response.data.data;
                    this.totalNumberOfRecords = response.data.total;
                    this.pageCount = Math.ceil(response.data.total / this.itemsPerPage);
                } else {
                    this.$store.commit('logOut');
                }

            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },
        changePage: function() {

        },
        insertNewTimeInRecordForWfh: function() {
            this.overlay = true;
            this.loading = true;
            this.axios.post(
                '/api/insert_new_time_in_record_for_wfh',
                {
                    employee_code: this.$store.state.user.id,
                }
            ).then(response => {
                    // console.log(response.data);
                    if(response.status === 200) {
                        this.loadAttendanceDataForWfh();
                    } else {
                        alert('error');
                    }

                }).catch(error => {
                    // console.log(error);
                    if( error.response.data.message.match(/Integrity constraint violation/) ) {
                        alert('You can not insert the multiple records redundantly for the same date...');
                    }
                }).finally(() => {
                    this.overlay = false;
                    this.loading = false;
                });
        },
        deleteRecord: function(item) {
            if(!confirm('Is it ok to delete the record: ' + item.id + '?')) {
                return false;
            }
            this.overlay = true;
            this.loading = true;
            this.axios.post(
                '/api/delete_record',
                {
                    id: item.id,
                }
            ).then(response => {
                    // console.log(response.data);
                    if(response.status === 200) {
                        this.loadAttendanceDataForWfh();
                    } else {

                    }

                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                    this.overlay = false;
                    this.loading = false;
                });            
        },           
        updateOut: function(item) {
            if(!confirm('Is it ok to update of Out for the record: ' + item.id + '?')) {
                return false;
            }
            this.overlay = true;
            this.loading = true;
            this.axios.post(
                '/api/update_out',
                {
                    id: item.id,
                }
            ).then(response => {
                    console.log(response.data);
                    if(response.status === 200) {
                        this.loadAttendanceDataForWfh();
                    } else {

                    }

                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                    this.overlay = false;
                    this.loading = false;
                });            
        },        
    },
    filters: {
        getDay: function(value) {
            const date = new Date(value);
            // console.log(date)
            const dayOfWeek = date.getDay() * 1;
            return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][dayOfWeek];
        }
    }    
}
</script>