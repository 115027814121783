<template>
    <v-app-bar
        app
        dark
        class="indigo lighten-3"
        clipped-left
    >
        <v-app-bar-nav-icon
            @click="$store.commit('toggleDrawer')"
            v-if="$store.state.isLogged"
        ></v-app-bar-nav-icon>
        <v-toolbar-title>MCMSPH *For Trial</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
            <template v-slot:activator="{ on }">
                <v-icon
                    @click="logout"
                    v-show="$store.state.isLogged"
                    v-on="on"
                >mdi-logout</v-icon>
            </template>
            <span>Logout</span>
        </v-tooltip>
    </v-app-bar>
</template>
<script>
export default {
    methods: {
        logout: function() {
            location.href = '/login';
            this.$store.commit('logOut');
        }
    }
}
</script>
