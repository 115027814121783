<template>
    <div>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-container>
                        <v-card-title>
                            {{ name }}
                        </v-card-title>
                        <v-card-subtitle>
                            [Id]<br/> {{ id }}
                            <v-divider></v-divider>
                            [Email]<br/> {{ email }}
                            <v-divider></v-divider>
                            [CompanyId]<br/> {{ company_id }}
                            <v-divider></v-divider>
                            [Status]<br/>
                            <v-data-table
                                :headers="headers"
                                :items="items"
                            ></v-data-table>
                            <v-divider></v-divider>
                            <v-row>
                                <v-col
                                    cols="10"
                                    offset="1"
                                >
                                    <vue-qr-reader
                                        v-on:code-scanned="codeArrived"
                                        :responsive="true"
                                    />
                                </v-col>
                            </v-row>
                        </v-card-subtitle>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import VueQrReader from 'vue-qr-reader/dist/lib/vue-qr-reader.umd.js';

export default {
    components: {
        VueQrReader
    },
    data() {
        return {
            overlay: false,
            loading: false,
            qr_code: null,
            id: null,
            name: null,
            email: null,
            company_id: null,
            headers: [
                {
                    text: "Name",
                    value: "employee_code",
                    sortable: false,
                },
                {
                    text: "Date",
                    value: "date",
                    sortable: false,
                },
                {
                    text: "In",
                    value: "in",
                    sortable: false,
                },
                {
                    text: "Out",
                    value: "out",
                    sortable: false,
                },
                {
                    text: "In2",
                    value: "in2",
                    sortable: false,
                },
                {
                    text: "Out2",
                    value: "out2",
                    sortable: false,
                },
            ],
            items: [],
        }
    },
    mounted() {
        if( this.$store.state.user.role !== 'swipe2' ) {
            alert('You have no access right to this page...');
            this.$router.push('/transactions');
        }

        this.id = this.$store.state.currentUser.id;
        this.name = this.$store.state.currentUser.name;
        this.email = this.$store.state.currentUser.email;
        this.company_id = this.$store.state.currentUser.company_id;
        this.qr_code = null;
        this.getLatestNewAttendanceData();

    },
    methods: {
        codeArrived: function(value) {
            this.qr_code = value;
            if( this.qr_code ) {
                this.getCurrentUser();
            }
        },
        getCurrentUser: function() {
            this.overlay = true;
            this.loading = true;
            axios.post(
                '/api/get_current_user',
                {
                    id: this.qr_code ? JSON.parse(this.qr_code).employee_code : null,
                }
            ).then(response => {
                // console.log(response.data);
                this.$store.commit('assignCurrentUserInfo', response.data);
                this.id = this.$store.state.currentUser.id;
                this.name = this.$store.state.currentUser.name;
                this.email = this.$store.state.currentUser.email;
                this.company_id = this.$store.state.currentUser.company_id;
                this.getLatestNewAttendanceData();
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },
        getLatestNewAttendanceData: function() {
            this.overlay = true;
            this.loading = true;
            axios.post(
                '/api/get_latest_new_attendance_data',
                {
                    id: this.id,
                }
            ).then(response => {
                // console.log(response.data);
                this.items = [];

                if( 
                    (
                        response.data 
                        && 
                        (moment().format('YYYY-MM-DD') == response.data.date)
                    ) 
                    ||
                    (
                        response.data 
                        && 
                        (moment().subtract(1, "days").format('YYYY-MM-DD') == response.data.date )
                        && 
                        ! response.data.out2
                    )
                ) {
                // 今は今日の日付のレコードを掴んでくるようになっているが、追加で前日のレコードも条件付きで掴んでくるように設定必要ありか
                    this.items.push(response.data);
                    if( this.qr_code ) {
                        if ( 
                            this.items[0].in
                            &&
                            ! this.items[0].out
                            &&
                            ! this.items[0].in2
                            &&
                            ! this.items[0].out2
                        ) {
                            const timeIn = moment(this.items[0].in, "YYYY-MM-DD HH:mm:ss");
                            const currentTime = moment().format('YYYY-MM-DD HH:mm:ss');
                            const diffInHours = Math.abs(timeIn.diff(currentTime, 'hours'));
                            console.log(diffInHours)
                            const isOverEightHours = diffInHours > 8;
                            console.log(isOverEightHours);

                            if( isOverEightHours ) {
                                this.updateOut2(this.items[0]);
                            } else {
                                this.updateOutForNewAttendanceData(this.items[0]);
                            }
                        } else if(
                            this.items[0].in
                            &&
                            this.items[0].out
                            &&
                            ! this.items[0].in2
                            &&
                            ! this.items[0].out2                            
                        ) {
                            if( confirm('Are you going to time-in again?') ) {
                                this.updateIn2(this.items[0]);
                            } else {
                                if ( confirm('Are you going home?')) {
                                    this.updateOut2(this.items[0]);
                                } else {
                                    this.$router.go({path: '/swipe_screen', force: true});
                                }
                            }
                        } else if(
                            this.items[0].in
                            &&
                            this.items[0].out
                            &&
                            this.items[0].in2
                            &&
                            ! this.items[0].out2
                        ) {
                            this.updateOut2(this.items[0]);
                        } else {
                            alert('You can not update the record any more...');
                            this.$router.go({path: '/swipe_screen', force: true});
                        }

                    }
                } else {
                    // TimeIn
                    this.insertNewTimeInRecordForNewAttendanceData();
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
                this.qr_code = null;
            });
        },
        updateIn2: function(item) {
            if(!confirm('Is it ok to update of In2　?')) {
                this.$router.go({path: '/swipe_screen', force: true});
                return false;
            }
            
            this.overlay = true;
            this.loading = true;
            this.axios.post(
                '/api/update_in2',
                {
                    id: item.id,
                }
            ).then(response => {
                    // console.log(response.data);
                    if(response.status === 200) {
                        this.getLatestAttendanceData();
                    }
                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                    this.overlay = false;
                    this.loading = false;
                    this.$router.go({path: '/swipe_screen', force: true});
                });
        },
        updateOutForNewAttendanceData: function(item) {
            if(!confirm('Is it ok to update of Out　?')) {
                this.$router.go({path: '/swipe_screen', force: true});
                return false;
            }
            
            this.overlay = true;
            this.loading = true;
            this.axios.post(
                '/api/update_out_for_new_attendance_data',
                {
                    id: item.id,
                }
            ).then(response => {
                    // console.log(response.data);
                    if(response.status === 200) {
                        this.getLatestAttendanceData();
                    }
                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                    this.overlay = false;
                    this.loading = false;
                    this.$router.go({path: '/swipe_screen', force: true});
                });
        },
        updateOut2: function(item) {
            if(!confirm('Is it ok to update of Out2　?')) {
                this.$router.go({path: '/swipe_screen', force: true});
                return false;
            }
            this.overlay = true;
            this.loading = true;
            this.axios.post(
                '/api/update_out2',
                {
                    id: item.id,
                }
            ).then(response => {
                    // console.log(response.data);
                    if(response.status === 200) {
                        this.getLatestAttendanceData();
                    }
                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                    this.overlay = false;
                    this.loading = false;
                    this.$router.go({path: '/swipe_screen', force: true});
                });
        },
        insertNewTimeInRecordForNewAttendanceData: function() {
            this.overlay = true;
            this.loading = true;
            this.axios.post(
                '/api/insert_new_time_in_record_for_new_attendance_data',
                {
                    employee_code: JSON.parse(this.qr_code).employee_code,
                }
            ).then(response => {
                    if(response.status === 200) {
                        this.items = response.data;
                    }
                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                    this.overlay = false;
                    this.loading = false;
                    this.$router.go({path: '/swipe_screen', force: true});
                });
        },
    },
}
</script>
