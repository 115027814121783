<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <h3>Verify Account</h3>
    {{ $route.params.email }}
    <v-text-field v-model="pin" label="PIN"></v-text-field>
    <v-btn color="red" dark @click="checkPin">Verify</v-btn>
  </div>
</template>
<script>
export default {
  data() {
    return {
      overlay: false,
      pin: null,
    };
  },
  methods: {
    checkPin: function () {
      this.overlay = true;
      this.axios
        .post("/api/verify_account", {
          email: this.$route.params.email,
          pin: this.pin,
        })
        .then((response) => {
          if (response.data[0]) {
            // this.$store.commit("assignUserInfo", response.data[0]);
            // this.$store.commit("loggedIn");
            const payload = {
              title: "You have been resistered in our database successfully!",
              msg: "Please provide your email address and password in the login form to go to main page...",
            };
            this.$store.commit("showAlert", payload);
            location.href = "/login";
          } else {
            const payload = {
              title: "Invalid email address or PIN...",
              msg: "Please try again with correct email address or PIN...",
            };
            this.$store.commit("showAlert", payload);
            this.overlay = false;
            location.href = "/login";
          }
        });
    },
  },
};
</script>
