<template>
    <div>
        <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-card flat>
            <v-container>
                <v-toolbar flat dark class="indigo lighten-3">
                    <v-toolbar-title> Picture Uploading Form: {{ containerNumber }} </v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                <v-row>
                    <v-col cols="12">
                        <v-list>
                            <v-toolbar flat dark color="indigo lighten-3 my-2">
                                <v-toolbar-title>Encode Container details: {{ containerNumber }}</v-toolbar-title>
                            </v-toolbar>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-form ref="form" lazy-validation>
                                    <v-menu
                                        v-model="menu"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="manufacturingDate"
                                            label="ManufacturingDate"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            outlined
                                            dense
                                            :rules="rules"
                                            @input="updateContainerDetails()"
                                        ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="manufacturingDate"
                                            @input="menu = false"
                                        ></v-date-picker>
                                    </v-menu>
                                    <!-- <v-text-field
                                        outlined
                                        dense
                                        v-model="manufacturingDate"
                                        label="ManufacturingDate"
                                        @blur="updateContainerDetails()"
                                    ></v-text-field> -->
                                    <v-text-field
                                        outlined
                                        dense
                                        v-model="tareWeight"
                                        label="TareWeight"
                                        :rules="rules"
                                        @blur="updateContainerDetails()"
                                    ></v-text-field>
                                    <v-text-field
                                        outlined
                                        dense
                                        v-model="massGrossWeight"
                                        label="MassGrossWeight"
                                        :rules="rules"
                                        @blur="updateContainerDetails()"
                                    ></v-text-field>
                                    <v-text-field
                                        outlined
                                        dense
                                        v-model="brokerName"
                                        label="BrokerName"
                                        :rules="rules"
                                        @blur="updateContainerDetails()"
                                    ></v-text-field>
                                    <v-text-field
                                        outlined
                                        dense
                                        v-model="plateNumber"
                                        label="PlateNumber"
                                        :rules="rules"
                                        @blur="updateContainerDetails()"
                                    ></v-text-field>
                                    <v-btn
                                        color="red"
                                        class="lighten-2"
                                        block
                                        dark
                                        @click="saveContainerDetails()"
                                    >
                                    <v-icon>mdi-content-save-all</v-icon>
                                    register in database
                                    </v-btn>
                                    </v-form>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-col>
                    <v-col cols="12" v-if="showPictureUploadingSlots">
                        <v-list>
                            <v-toolbar flat dark color="indigo lighten-3 my-2">
                                <v-toolbar-title>Picture(s) To Be Uploaded: {{ containerNumber }}</v-toolbar-title>
                            </v-toolbar>
                            <v-list-item v-for="(a, i) in areas" :key="`file${i}`">
                                <v-list-item-content>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-file-input
                                                dense
                                                outlined
                                                small-chips
                                                multiple
                                                v-model="userFiles[i]"
                                                prepend-icon="mdi-camera"
                                                :placeholder="`${a.area_type}-${a.area_name}`"
                                                capture="camera"
                                                accept="image/*"
                                                @change="addFiles(a, i)"
                                            ></v-file-input>
                                        </v-col>

                                        <v-col cols="12">
                                            <v-btn
                                                @click="skipUploading(a, i)"
                                                dark
                                                class="red lighten-3"
                                                v-show="a.area_name === 'UnderStructure'"
                                                block
                                            >
                                                <v-icon>mdi-close-octagon</v-icon>skip
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                    <v-progress-linear
                                        height="15"
                                        :value="progressLinears[i]"
                                        dark
                                        stream
                                        class="my-2"
                                    ></v-progress-linear>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-card-actions style="background-color: bisque;" v-if="currentSurveyId != null">
                            <h3>*Pictures Submitted!</h3>
                        </v-card-actions>
                        <v-card-actions>
                            <v-select
                                v-if="areas.length === 0 && currentSurveyId == null"
                                label="Status"
                                :items="remarks"
                                item-text="remark_name"
                                item-value="remark_id"
                                outlined
                                dense
                                v-model="remark_id"
                            ></v-select>
                        </v-card-actions>
                        <v-card-actions>
                            <v-textarea
                                v-if="areas.length === 0 && currentSurveyId == null"
                                outlined
                                dense
                                label="CEDEX Code"
                                v-model="cedexCode"
                            ></v-textarea>
                        </v-card-actions>
                        <v-card-actions>
                            <v-select
                                v-if="areas.length === 0 && remark_id === 'R-01' && currentSurveyId == null"
                                label="Repair Destination"
                                :items="destinations"
                                item-text="destination_type"
                                item-value="destination_id"
                                outlined
                                dense
                                v-model="destination"
                            ></v-select>
                        </v-card-actions>
                        <v-card-actions>
                            <v-btn
                                v-if="areas.length === 0 && remark_id !== null && cedexCode !== null && currentSurveyId == null"
                                color="green"
                                block
                                dark
                                @click="submitPictures"
                            >
                                <v-icon>mdi-image-move</v-icon>submit pictures
                            </v-btn>
                        </v-card-actions>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" v-if="showPictureUploadingSlots">
                        <v-list>
                            <v-toolbar flat dark color="indigo lighten-3 my-2">
                                <v-toolbar-title>Encode Damage: {{ containerNumber }}</v-toolbar-title>
                            </v-toolbar>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-data-table
                                        :headers="headers"
                                        :items="pictures"
                                        :items-per-page="-1"
                                        :loading="loading"
                                    >
                                        <template v-slot:top>
                                            <v-btn
                                                block
                                                color="green"
                                                dark
                                                @click="updateStatusesOfAllPictures()"
                                                >
                                                    <v-icon>mdi-update</v-icon>
                                                    Update Status of Pictures
                                                </v-btn>
                                        </template>

                                        <template v-slot:[`item.damage_pictures`]="{ item }">
                                            <v-file-input
                                                dense
                                                outlined
                                                hide-input
                                                v-model="damagePicture"
                                                prepend-icon="mdi-camera"
                                                capture="camera"
                                                accept="image/*"
                                                @change="addDamagePicture(item)"
                                                v-if="!item.skipped"
                                            ></v-file-input>
                                            <v-badge
                                                color="red"
                                                :content="item.damage_pictures.length"
                                                overlap
                                                v-if="item.damage_pictures.length > 0 && !item.skipped"
                                            >
                                                <v-icon
                                                    color="blue"
                                                    size="30"
                                                    @click="openDamagePictureDialog(item)"
                                                    v-if="!item.skipped"
                                                >mdi-image</v-icon>
                                            </v-badge>
                                        </template>
                                        <template v-slot:[`item.encode_damage_code`]="{ item }">
                                            <div v-if="item.damages.length > 0">
                                                <v-badge
                                                    bordered
                                                    color="red"
                                                    :content="item.damages.length"
                                                    offset-x="10"
                                                    offset-y="10"
                                                    v-if="item.damages.length > 0"
                                                >
                                                    <v-btn
                                                        color="orange"
                                                        dark
                                                        small
                                                        v-if="!item.skipped"
                                                        @click="openEncodeDamageCodeDialog(item)"
                                                    ><v-icon>mdi-toolbox</v-icon>Encode</v-btn>
                                                </v-badge>
                                            </div>
                                            <div v-else>
                                                <v-btn
                                                    color="orange"
                                                    dark
                                                    small
                                                    v-if="!item.skipped"
                                                    @click="openEncodeDamageCodeDialog(item)"
                                                ><v-icon>mdi-toolbox</v-icon>Encode</v-btn>
                                            </div>
                                        </template>
                                        <template v-slot:[`item.status`]="{ item }">
                                            <v-menu :disabled="disableStatusSelection">
                                                <template v-slot:activator="{ on }">
                                                    <v-icon
                                                        :color="statusIcons[item.status].color"
                                                        large
                                                        v-on="on"
                                                    >
                                                        {{statusIcons[item.status].icon}}
                                                    </v-icon>{{ item.status }}
                                                </template>
                                                <v-list>
                                                    <v-list-item
                                                        v-for="(s, i) in statusIcons"
                                                        :key="i"
                                                        link
                                                        @click="updateStatus(item, i)"
                                                    >
                                                        <v-tooltip bottom>
                                                            <template v-slot:activator="{ on }">
                                                                <v-icon
                                                                    :color="s.color"
                                                                    v-on="on"
                                                                >
                                                                    {{s.icon}}
                                                                </v-icon>
                                                            </template>
                                                            <span>{{i}}</span>
                                                        </v-tooltip>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu>
                                        </template>
                                        <template v-slot:[`item.actions`]="{ item }">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-icon
                                                        color="green"
                                                        @click="viewPicture(item)"
                                                        v-on="on"
                                                        v-if="!item.skipped"
                                                    >mdi-eye</v-icon>
                                                </template>
                                                <span>View Picture</span>
                                            </v-tooltip>
                                            <div v-if="item.damages.length == 0 && item.damage_pictures.length == 0">
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon
                                                            color="red"
                                                            @click="deletePicture(item)"
                                                            v-on="on"
                                                            :disabled="item.status == 'Finished'"
                                                        >mdi-delete</v-icon>
                                                    </template>
                                                    <span>Delete Picture</span>
                                                </v-tooltip>
                                            </div>

                                        </template>
                                    </v-data-table>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-col>
                </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        color="red"
                        class="lighten-2"
                        dark
                        block
                        @click="surveyFinished()"
                        v-if="allThePictureStatusAreFinished && surveyStatusCode != 'Finished'"
                    >
                        Survey Finished
                    </v-btn>
                </v-card-actions>
            </v-container>
        </v-card>
        <!-- damagePictureDialog start -->
        <v-dialog v-model="damagePictureDialog" fullscreen>
            <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
            <v-card flat>
                <v-container>
                    <v-toolbar flat dark class="indigo lighten-3">
                        <v-toolbar-title
                        >Pictures -- ContainerNumber: {{containerNumber}} --</v-toolbar-title
                        >
                        <v-spacer></v-spacer>
                        <v-btn
                            icon
                            dark
                            @click="damagePictureDialog = false"
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <v-data-table
                            :headers="damagePictureHeaders"
                            :items="damagePictures"
                        >
                            <template v-slot:[`item.actions`]="{ item }">
                                <v-icon
                                    color="green"
                                    @click="viewDamagePicture(item)"
                                >mdi-eye</v-icon>
                                <v-icon
                                    color="red"
                                    @click="deleteDamagePicture(item)"
                                >mdi-delete</v-icon>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-container>
            </v-card>
        </v-dialog>
        <!--damagePictureDialog end -->
        <!--encodeDamageCodeDialog start -->
        <v-dialog v-model="encodeDamageCodeDialog" fullscreen>
            <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
            <v-card flat>
                <v-container fluid>
                    <v-toolbar flat dark class="indigo lighten-3">
                        <v-toolbar-title
                        >Encode DamageCode: {{ currentPictureFileName }}({{ containerNumber }})</v-toolbar-title
                        >
                        <v-spacer></v-spacer>
                        <v-btn
                            icon
                            dark
                            @click="encodeDamageCodeDialog = false"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-form ref="form">
                    <v-card-text>
                  <v-row>
                    <v-col>
                      <v-img
                        :src="`/images/locations/loc-${currentContainerSize}-${currentAreaCode}.png`"
                        width="400"
                      ></v-img>
                      </v-col>
                      <v-col>
                        <a :href="pictureTempUrl" target="_blank">
                            <v-img
                                :src="pictureTempUrl"
                                width="400"
                            ></v-img>
                        </a>
                    </v-col>
                      <v-col v-for="(pic, i) in damagePictureTempUrls" :key="i">
                        <a :href="pic" target="_blank">
                            <v-img
                                :src="pic"
                                width="400"
                            ></v-img>
                        </a>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4">
                      <v-select
                        outlined
                        dense
                        v-model="currentLocationCode"
                        label="FromLocation"
                        :items="locationCodes"
                        :rules="rules"
                        :disabled="currentPictureStatus == 'Finished'"
                      ></v-select>
                    </v-col>
                    <v-col cols="4">
                      <v-select
                        outlined
                        dense
                        v-model="currentToLocationCode"
                        label="ToLocation"
                        :items="toLocationCodes"
                        :rules="rules"
                        :disabled="currentPictureStatus == 'Finished'"
                      ></v-select>
                    </v-col>
                    <v-col cols="4">
                      <v-select
                        outlined
                        dense
                        v-model="currentDamageCode"
                        :items="damageCodes"
                        item-text="damage_name"
                        item-value="damate_code_id"
                        label="DamageCode"
                        :rules="rules"
                        :disabled="currentPictureStatus == 'Finished'"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    color="red"
                    class="lighten-2"
                    dark
                    block
                    @click="addDamage()"
                    :disabled="currentPictureStatus == 'Finished'"
                  >
                    <v-icon>mdi-plus</v-icon> ADD
                  </v-btn>
                </v-card-actions>
                </v-form>
                <v-card-text>
                    <v-data-table
                        :headers="damagesHeaders"
                        :items="damages"
                    >
                        <template v-slot:top>
                            <v-toolbar flat dark class="indigo lighten-3">
                            <v-toolbar-title>Damages to be registered</v-toolbar-title>
                            </v-toolbar>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-icon
                            color="red"
                            @click="deleteDamage(item)"
                            :disabled="currentPictureStatus == 'Finished'"
                            >mdi-delete</v-icon>
                        </template>
                        <template v-slot:[`item.encode_job_order_services`]="{ item }">

                            <v-btn
                                color="orange"
                                dark
                                small
                                v-if="jobOrderId"
                                :to="`/encode_job_order_services/${item.transaction_id}/${item.container_number}/${item.survey_id}/${jobOrderId}/${item.damage_id}`"
                            ><v-icon>mdi-toolbox</v-icon>Encode</v-btn>
                            <!-- <a v-if="jobOrderId" :href="`/encode_job_order_services/${item.transaction_id}/${item.container_number}/${item.survey_id}/${jobOrderId}/${item.damage_id}`">encode
                            </a> -->
                        </template>
                    </v-data-table>
                </v-card-text>
                </v-container>
            </v-card>
        </v-dialog>
        <!--encodeDamageCodeDialog end -->
    </div>
</template>
<script>
export default {
    data() {
        return {
            overlay: false,
            loading: false,
            rules: [(v) => !!v || "Required!"],
            menu: false,
            areas: [],
            userFiles: [],
            progressLinears: [],
            file_id: null,
            formData: null,
            remark_id: null,
            destinations: [],
            destination: null,
            cedexCode: null,
            remarks: [],
            headers: [
                // {
                //     text: "ContainerNumber",
                //     value: "container_number",
                //     sortable: true,
                // },
                // {
                //     text: "TransactionId",
                //     value: "transaction_id",
                //     sortable: true,
                // },
                // {
                //     text: "AreaType",
                //     value: "area_type",
                //     sortable: true,
                // },
                // {
                //     text: "AreaName",
                //     value: "area_name",
                //     sortable: true,
                // },
                {
                    text: "FileName",
                    value: "file_name",
                    sortable: true,
                },
                {
                    text: "Skipped",
                    value: "skipped",
                    sortable: true,
                },
                {
                    text: "DamagePicture(s)",
                    value: "damage_pictures",
                    sortable: false,
                },
                {
                    text: "Encode DamageCode",
                    value: "encode_damage_code",
                    sortable: false,
                },
                {
                    text: "Status",
                    value: "status",
                    sortable: false,
                },
                {
                    text: "Actions",
                    value: "actions",
                    sortable: false,
                },
            ],
            pictures: [],
            containerNumber: null,
            transactionId: null,
            // email: null,
            manufacturingDate: null,
            tareWeight: null,
            massGrossWeight: null,
            brokerName: null,
            plateNumber: null,
            transactions: [],
            showPictureUploadingSlots: false,
            damagePicture: null,
            damagePictureDialog: false,
            damagePictures: [],
            damagePictureHeaders: [
                {
                    text: 'FileName',
                    value: 'file_name',
                    sortable: true,
                },
                {
                    text: 'Actions',
                    value: 'actions',
                    sortable: true,
                },
                {
                    text: 'CreatedAt',
                    value: 'created_at',
                    sortable: true,
                }
            ],
            currentSurveyId: null,
            currentPictureItem: null,
            encodeDamageCodeDialog: false,
            currentPictureFileName: null,
            currentContainerSize: null,
            currentAreaCode: null,
            currentPictureStatus: null,
            currentLocationCode: null,
            currentToLocationCode: null,
            currentDamageCode: null,
            currentPictureItem: null,
            imgData: null,
            locationCodes: [],
            toLocationCodes: [],
            damageCodes: [],
            allThePictureStatusAreFinished: false,
            damagesHeaders: [
                {
                    text: "AreaCode",
                    value: "area_code",
                    sortable: true,
                },
                {
                    text: "FromLocation",
                    value: "location_code",
                    sortable: true,
                },
                {
                    text: "ToLocation",
                    value: "to_location_code",
                    sortable: true,
                },
                {
                    text: "DamageCode",
                    value: "damage_code",
                    sortable: true,
                },
                {
                    text: "EncodeJobOrderServices",
                    value: "encode_job_order_services",
                    sortable: false,
                },
                {
                    text: "Actions",
                    value: "actions",
                    sortable: true,
                },
            ],
            damages: [],
            disableStatusSelection: false,
            statusIcons: {
                'Not Yet Started': {
                    icon: 'mdi-stop',
                    color: 'red'
                },
                'Ongoing': {
                    icon: 'mdi-play',
                    color: 'blue'
                },
                'Finished': {
                    icon: 'mdi-check',
                    color: 'green'
                }
            },
            surveyStatusCode: null,
            pictureTempUrl: null,
            damagePictureTempUrls: null,
            jobOrderId: null,
        }
    },
    mounted() {

        if(this.$store.state.user.role === 'admin') {
            location.href = '/transactions';
        }
        this.containerNumber = this.$route.params.container_number;
        this.transactionId = this.$route.params.transaction_id;
        // this.email = this.$route.params.email;

        this.loadAreas();
        this.loadDestinations();
        this.loadPictures();
        this.loadRemarks();
        this.getTransaction();
    },
    methods: {
        getTransaction: function() {
            this.overlay = true;
            this.axios.post(
                '/api/get_transaction',
                {
                    container_number: this.containerNumber,
                    transaction_id: this.transactionId,
                }
            ).then(response => {
                if(response.status === 200) {
                    this.transactions = response.data;
                    if( this.transactions[0].survey ) {
                        this.surveyStatusCode = this.transactions[0].survey.status_code;
                    }
                    this.currentContainerSize = this.transactions[0]['size']['size'];
                    this.manufacturingDate = this.transactions[0]['manufacturing_date'];
                    this.currentSurveyId = this.transactions[0]['survey_id'];
                    this.tareWeight = this.transactions[0]['tare_weight'];
                    this.massGrossWeight = this.transactions[0]['mass_gross_weight'];
                    this.brokerName = this.transactions[0]['broker_name'];
                    this.plateNumber = this.transactions[0]['plate_number'];

                    this.createJobOrder();

                    if(
                        this.manufacturingDate
                        &&
                        this.tareWeight
                        &&
                        this.massGrossWeight
                        &&
                        this.brokerName
                        &&
                        this.plateNumber
                    ) {
                        this.showPictureUploadingSlots = true;
                    } else {
                        this.showPictureUploadingSlots = false;
                    }
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
            });
        },
        loadAreas: function() {
            this.overlay = true;
            this.axios.post(
                '/api/get_areas',
                {
                    container_number: this.containerNumber,
                    transaction_id: this.transactionId,
                }
            ).then(response => {
                this.areas = response.data;
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
            });
        },
        loadDestinations: function() {
            this.overlay = true;
            this.axios.post(
                '/api/get_destinations'
            ).then(response => {
                this.destinations = response.data;
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
            });
        },
        loadPictures: function() {
            this.overlay = true;
            this.loading = true;

            this.axios.post(
            '/api/get_pictures_by_transaction_id',
            {
                transaction_id: this.$route.params.transaction_id
            }
        ).then(response => {
            this.pictures = response.data;
            // console.log(this.pictures)
            const notYetFinishedPictures = this.pictures.filter( function(value) {
                return value.status != "Finished";
            });
            if(this.pictures.length > 0) {
                if(notYetFinishedPictures.length === 0) {
                this.allThePictureStatusAreFinished = true;
            } else {
                this.allThePictureStatusAreFinished = false;
            }
            } else {
                this.allThePictureStatusAreFinished = false;
            }

        }).catch(error => {
            console.log(error);
        }).finally(() => {
            this.overlay = false;
            this.loading = false;
        });
        },
        loadRemarks: function() {
            this.overlay = true;
            this.axios.post(
                '/api/get_remarks',
            ).then(response => {
                this.remarks = response.data;
                // console.log(this.remarks)
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
            });
        },
        addFiles: function (a, i) {

            this.file_id = i;
            this.formData = new FormData();

            if( typeof this.userFiles[i] === 'undefined' ) {
                return false;
            }

            for(var file of this.userFiles[i]) {
                if( typeof file === 'undefined' ) {
                    return false;
                }
                if (!file.name.match(/png|jpg$/i)) {
                    const payload = {
                        title: "Invalid file chosen..",
                        msg: "You tried to upload invalid type of file...you can upload png/jpg only..",
                    };
                    this.$store.commit("showAlert", payload);
                    return false;
                } else {
                    // this.formData.append("file", file);
                    const canvas = this.$refs.canvas;
                    const ctx = canvas.getContext('2d');
                    const img = new Image();
                    const self = this;
                    let NEW_SIZE = 0;

                    img.onload = function() {
                        // switch(self.imageWidthCond) {
                        //     case 0:
                        //         NEW_SIZE = 800;
                        //         break;
                        //     case 1:
                        //         NEW_SIZE = 600;
                        //         break;
                        //     case 2:
                        //         NEW_SIZE = 400;
                        //         break;
                        //     case 3:
                        //         NEW_SIZE = img.naturalWidth;
                        //         break;
                        // }
                        NEW_SIZE = 800;
                        const aspectRatio = img.naturalHeight / img.naturalWidth;
                        canvas.width = NEW_SIZE;
                        canvas.height = NEW_SIZE * aspectRatio;
                        ctx.drawImage(img, 0, 0, NEW_SIZE, NEW_SIZE * aspectRatio);
                        const imageData = canvas.toDataURL('image/jpeg');
                        imageData.replace('data:image/jpeg;base64,', '');
                        let blobToFile = self.dataURItoBlob(imageData);
                        const cfile = new File([blobToFile], "test.jpeg",
                            {
                                type: "image/jpeg",
                                lastModified: Date.now()
                            }
                        );
                        self.formData.append('file', cfile);
                        self.formData.append("user_id", self.$store.state.user.id);
                        self.formData.append('container_number', self.containerNumber);
                        self.formData.append('transaction_id', self.transactionId);
                        self.formData.append('area_code', a.area_code);
                        self.formData.append('area_type', a.area_type);
                        self.formData.append('area_name', a.area_name);

                        self.overlay = true;
                        self.loading = true;

                        self.axios
                            .post(
                                "/api/save_file",
                                self.formData,
                                {
                                    onUploadProgress: self.onUpload,
                                },
                                {
                                    headers: {
                                    "Content-Type": "multipart/form-data",
                                    },
                                }
                            )
                            .then((response) => {
                            if (response.status === 200) {
                                self.areas.splice(self.areas.indexOf(a), 1);
                                self.userFiles.splice(i, 1);
                                self.progressLinears.splice(i, 1);

                                self.loadPictures();
                            }
                        }).catch(error => {
                            console.log(error);
                        }).finally(() => {
                            self.overlay = false;
                            self.loading = false;
                        });
                    };
                    img.src = URL.createObjectURL(file);
                }
            }

            this.formData.append("user_id", this.$store.state.user.id);
            this.formData.append('container_number', this.containerNumber);
            this.formData.append('transaction_id', this.transactionId);
            this.formData.append('area_code', a.area_code);
            this.formData.append('area_type', a.area_type);
            this.formData.append('area_name', a.area_name);

            this.overlay = true;
            this.loading = true;

            this.axios
                .post(
                "/api/save_file",
                this.formData,
                {
                    onUploadProgress: this.onUpload,
                },
                {
                    headers: {
                    "Content-Type": "multipart/form-data",
                    },
                }
                )
                .then((response) => {
                if (response.status === 200) {
                    this.areas.splice(this.areas.indexOf(a), 1);
                    this.userFiles.splice(i, 1);
                    this.progressLinears.splice(i, 1);

                    this.loadPictures();
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },

        onUpload: function (e) {
            this.$set(
                this.progressLinears,
                this.file_id,
                Math.floor((e.loaded / e.total) * 100)
            );
        },

        deletePicture: function(item) {
            if(!confirm('Is it ok to delete the picture?')) {
                return false;
            }
            this.overlay = true;
            this.loading = true;
            this.axios.post(
                '/api/delete_picture',
                {
                    id: item.id,
                    container_number: item.container_number,
                    transaction_id: item.transaction_id,
                    file_name: item.file_name,
                }
            ).then(response => {
                // console.log(response.data);
                if(response.status === 200) {
                    const payload = {
                        title: "Picture deleted",
                        msg: "The picture data has been successfully deleted...",
                    };
                    this.$store.commit("showAlert", payload);
                    this.loadAreas();
                    this.loadPictures();
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },

        viewPicture: function(item) {
            this.overlay = true;
            this.downloading = true;
            axios
                .post(
                    "/api/view_picture",
                    {
                        transaction_id: item.transaction_id,
                        container_number: item.container_number,
                        file_name: item.file_name,
                    },
                    {
                        responseType: "blob",
                    }
                )
                .then(response => {
                    const fileURL = window.URL.createObjectURL(
                        new Blob([response.data], { type: "image/png" })
                    );
                    const fileLink = document.createElement("a");
                    fileLink.href = fileURL;
                    fileLink.target = "_blank";
                    document.body.appendChild(fileLink);
                    fileLink.click();
                    document.body.removeChild(fileLink);
                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                    this.downloading = false;
                    this.overlay = false;
                });
        },

        submitPictures: function() {

            if( this.remark_id === 'R-01' && this.destination === null ) {
                alert('Select any destination!');
                return false;
            }

            this.overlay = true;
            this.loading = true;

            this.axios.post(
                '/api/submit_pictures',
                {
                    transaction_id: this.$route.params.transaction_id,
                    container_number: this.$route.params.container_number,
                    user_id: this.$store.state.user.id,
                    remark_id: this.remark_id,
                    destination_id: this.destination,
                    cedexCode: this.cedexCode,
                }
            ).then(response => {
                if(response.status === 200) {
                    const payload = {
                        title: "Pictures Submitted!",
                        msg: "The pictures have been successfully submitted...",
                    };
                    this.$store.commit("showAlert", payload);
                    // location.href = '/transactions'
                    // console.log(response.data);
                    this.getTransaction();
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },
        updateContainerDetails: function() {
            this.overlay = true;
            this.loading = true;

            this.axios.post(
                '/api/update_container_details',
                {
                    transaction_id: this.$route.params.transaction_id,
                    container_number: this.$route.params.container_number,
                    user_id: this.$store.state.user.id,
                    manufacturing_date: this.manufacturingDate,
                    tare_weight: this.tareWeight,
                    mass_gross_weight: this.massGrossWeight,
                    broker_name: this.brokerName,
                    plate_number: this.plateNumber,
                }
            ).then(response => {
                if(response.status === 200) {
                    // console.log(response.data);
                    this.getTransaction();
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },
        skipUploading: function(a, i) {

            this.formData = new FormData();
            this.formData.append("user_id", this.$store.state.user.id);
            this.formData.append('container_number', this.containerNumber);
            this.formData.append('transaction_id', this.transactionId);
            this.formData.append('area_code', a.area_code);
            this.formData.append('area_type', a.area_type);
            this.formData.append('area_name', a.area_name);

            this.overlay = true;
            this.axios
                .post(
                "/api/skip_file",
                this.formData,
                {
                    onUploadProgress: this.onUpload,
                },
                {
                    headers: {
                    "Content-Type": "multipart/form-data",
                    },
                }
                )
                .then((response) => {
                if (response.status === 200) {

                    this.areas.splice(this.areas.indexOf(a), 1);
                    this.userFiles.splice(i, 1);
                    this.progressLinears.splice(i, 1);

                    this.loadPictures();
                    this.overlay = false;
                }
                });
        },
        addDamagePicture: function(item) {

            // console.log(item);
            // return false;
            this.formData = new FormData();

            if( typeof this.damagePicture === 'undefined' ) {
                return false;
            }

            if( typeof this.damagePicture === 'undefined' ) {
                return false;
            }
            if (!this.damagePicture.name.match(/png|jpg$/i)) {
                const payload = {
                    title: "Invalid file chosen..",
                    msg: "You tried to upload invalid type of file...you can upload png/jpg only..",
                };
                this.$store.commit("showAlert", payload);
                return false;
            } else {
                this.formData.append("file", this.damagePicture);
            }

            this.formData.append("user_id", this.$store.state.user.id);
            this.formData.append('container_number', this.containerNumber);
            this.formData.append('transaction_id', this.transactionId);
            this.formData.append('picture_id', item.id);
            this.formData.append('area_type', item.area_type);
            this.formData.append('area_name', item.area_name);
            this.formData.append('seq_no', item.damage_pictures.length);

            this.overlay = true;
            this.loading = true;

            this.axios
                .post(
                "/api/save_damage_picture",
                this.formData,
                {
                    onUploadProgress: this.onUpload,
                },
                {
                    headers: {
                    "Content-Type": "multipart/form-data",
                    },
                }
                )
                .then((response) => {
                if (response.status === 200) {
                    this.loadPictures();
                    this.damagePicture = null;
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },
        openDamagePictureDialog: function(item)
        {
            // console.log(item)
            this.currentPictureItem = item;
            this.damagePictureDialog = true;
            this.overlay = true;
            this.loading = true;

            this.getDamagePictures(this.currentPictureItem);
        },
        getDamagePictures: function(item) {
            this.axios
                .post(
                "/api/get_damage_pictures",
                {
                    picture_id: item.id,
                }
                ).then((response) => {
                if (response.status === 200) {
                    this.damagePictures = response.data;
                }
                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                    this.overlay = false;
                    this.loading = false;
                });
        },
        viewDamagePicture: function(item) {
            // console.log(item)
            this.overlay = true;
            this.downloading = true;
            axios
                .post(
                    "/api/view_damage_picture",
                    {
                        transaction_id: this.transactionId,
                        container_number: this.containerNumber,
                        file_name: item.file_name,
                    },
                    {
                        responseType: "blob",
                    }
                )
                .then(response => {
                    const fileURL = window.URL.createObjectURL(
                        new Blob([response.data], { type: "image/png" })
                    );
                    const fileLink = document.createElement("a");
                    fileLink.href = fileURL;
                    fileLink.target = "_blank";
                    document.body.appendChild(fileLink);
                    fileLink.click();
                    document.body.removeChild(fileLink);
                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                    this.downloading = false;
                    this.overlay = false;
                });
        },
        deleteDamagePicture: function(item) {
            if(!confirm('Is it ok to delete the picture?')) {
                return false;
            }
            this.overlay = true;
            this.loading = true;
            this.axios.post(
                '/api/delete_damage_picture',
                {
                    id: item.id,
                    container_number: this.containerNumber,
                    transaction_id: this.transactionId,
                    file_name: item.file_name,
                }
            ).then(response => {
                // console.log(response.data);
                if(response.status === 200) {
                    const payload = {
                        title: "Damage Picture deleted",
                        msg: "The damage picture data has been successfully deleted...",
                    };
                    this.$store.commit("showAlert", payload);
                    this.loadAreas();
                    this.getDamagePictures(this.currentPictureItem);
                    this.loadPictures();
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },
        saveContainerDetails: function() {
            if (!this.$refs.form.validate()) {
                return false;
            }

            this.updateContainerDetails();
        },
        openEncodeDamageCodeDialog: function(item) {
            // console.log(item)
            this.encodeDamageCodeDialog = true;
            this.currentPictureFileName = item.file_name;
            this.currentAreaCode = item.area_code;
            this.currentPictureStatus = item.status;
            this.currentLocationCode = null;
            this.currentToLocationCode = null;
            this.currentDamageCode = null;
            this.currentPictureItem = item;
            this.imgData = null;
            this.getDamages(item);
            // console.log(item)
            this.getPictureTempUrl();
            this.getDamagePictureTempUrls();
            this.getJobOrderId();

            this.axios.post(
                '/api/get_area_configuration',
                {
                container_size: this.currentContainerSize,
                area_code: this.currentAreaCode,
                }
            ).then(response => {
                this.locationCodes = [];
                this.toLocationCodes = [];
                const config = JSON.parse(response.data[0].config);
                for(var n = config[0][1]; n <= config[0][2]; n++) {
                this.locationCodes.push(
                        config[0][0] + n
                    );
                }
                // console.log(this.locationCodes)
                for(var n = config[1][1]; n <= config[1][2]; n++) {
                    this.toLocationCodes.push(
                        config[1][0] + n
                    );
                }
                // console.log(this.toLocationCodes);
            }).catch(error => {
                console.log(error);
            });

            this.axios.post('/api/get_damage_codes')
                .then(response => {
                this.damageCodes = response.data;
                }).catch(error => {
                console.log(error);
                });
        },
        addDamage: function() {
            if (!this.$refs.form.validate()) {
                return false;
            }
            if(!this.currentSurveyId) {
                alert('You need to submit pictures first!');
                return false;
            }
// console.log(this.curentSurveyId)
            this.overlay = true;
            this.loading = true;
            // console.log(this.containerNumber)
            this.axios.post(
                '/api/add_damage',
                {
                    container_number: this.containerNumber,
                    transaction_id: this.transactionId,
                    survey_id: this.currentSurveyId,
                    area_code: this.currentAreaCode,
                    picture_id: this.currentPictureItem.id,
                    location_code: this.currentLocationCode,
                    to_location_code: this.currentToLocationCode,
                    damage_code: this.currentDamageCode,
                    user_id: this.$store.state.user.id,
                }
            ).then(response => {
                // console.log(response.data);
                this.damages = response.data;
                this.getPicturesByTransactionId(this.transactionId);
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },
        deleteDamage: function(item) {
            if(!confirm('Is it ok to delete the damage information?')) {
                return false;
            }

            this.overlay = true;
            this.loading = true;

            this.axios.post(
                '/api/delete_damage',
                    {
                    id: item.id,
                    transaction_id: this.transactionId,
                    survey_id: this.currentSurveyId,
                    container_number: this.containerNumber,
                    area_code: this.currentAreaCode,
                    }
                ).then(response => {
                    if(response.status === 200) {
                    this.getDamages(item);
                    this.getPicturesByTransactionId(this.transactionId);
                    }
                }).catch(error => {
                    console.log(error);
                }).finally(() => {
                    this.overlay = false;
                    this.loading = false;
                });
        },
        getDamages: function(item) {
            this.axios.post(
                '/api/get_damages',
                {
                transaction_id: item.transaction_id,
                survey_id: this.currentSurveyId,
                container_number: item.container_number,
                area_code: this.currentAreaCode,

                }
            ).then(response => {
                this.damages = response.data;
            }).catch(error => {
                console.log(error);
            });
        },
        getPicturesByTransactionId: function(transaction_id) {
            this.axios.post(
                '/api/get_pictures_by_transaction_id',
                {
                transaction_id: transaction_id,
                }
            ).then(response => {
                // console.log(response.data);
                this.pictures = response.data;
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },
        updateStatus: function(item, i) {
            // console.log(item)
            if(item.damage_pictures.length == 0 && i == 'Finished') {
                alert("Upload the Damage Picture(s) first!");
                return false;
            }
            this.overlay = true;
            this.loading = true;
            this.axios.post(
                '/api/update_status',
                {
                    id: item.id,
                    user_id: this.$store.state.user.id,
                    status: i,
                }
            ).then(response => {
                if(response.status === 200) {
                    const index = this.pictures.indexOf(item);
                    this.$set(this.pictures[index], 'status', i);

                    const notYetFinishedPictures = this.pictures.filter( function(value) {
                        return value.status != "Finished";
                    });

                    // console.log(notYetFinishedPictures.length);

                    if(notYetFinishedPictures.length === 0) {
                        this.allThePictureStatusAreFinished = true;
                    } else {
                        this.allThePictureStatusAreFinished = false;
                    }

                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },
        updateStatusesOfAllPictures: function() {
            // console.log(this.pictures)

            const damagePicturesUploaded = this.pictures.filter(function(value) {
                return value.damage_pictures.length > 0;
            });

            const activePictures = this.pictures.filter(function(value) {
                return value.skipped != 1;
            });

            if( damagePicturesUploaded.length != activePictures.length ) {
                alert('Upload damage pictures first!');
                return false;
            }

            if( !confirm('Is it ok to update statuses of all the pictures as "Finished"?') ) {
                return false;
            }
            this.overlay = true;
            this.loading = true;
            this.axios.post(
                '/api/update_statuses_of_all_pictures',
                {
                    transaction_id: this.transactionId,
                    container_number: this.containerNumber,
                    survey_id: this.currentSurveyId,
                    user_id: this.$store.state.user.id,
                }
            ).then(response => {
                // console.log(response.data);
                if( response.status === 200 ) {
                    this.getPicturesByTransactionId(this.transactionId);
                    this.allThePictureStatusAreFinished = true;
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },
        surveyFinished: function() {
            this.overlay = true;
            this.loading = true;
            this.axios.post(
                '/api/survey_finished',
                {
                    survey_id: this.currentSurveyId,
                }
            ).then(response => {
                // console.log(response.data);
                if( response.status === 200 ) {
                    this.$router.push('/survey/' + '3');
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },
        getPictureTempUrl: function() {
            this.overlay = true;
            this.loading = true;
            this.axios.post(
                '/api/get_picture_temp_url',
                {
                    container_number: this.containerNumber,
                    transaction_id: this.transactionId,
                    file_name: this.currentPictureItem.file_name,
                },
                // {
                //     responseType: "blob",
                // }
            ).then(response => {

                this.pictureTempUrl = response.data;

            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },
        getDamagePictureTempUrls: function() {
            // console.log(this.currentPictureItem);
            this.overlay = true;
            this.loading = true;
            this.axios.post(
                '/api/get_damage_picture_temp_urls',
                {
                    container_number: this.containerNumber,
                    transaction_id: this.transactionId,
                    picture_id: this.currentPictureItem.id,
                },
                // {
                //     responseType: "blob",
                // }
            ).then(response => {

                this.damagePictureTempUrls = response.data;

            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },
        getJobOrderId() {
            this.overlay = true;
            this.loading = true;
            this.axios.post(
                '/api/get_job_order_id',
                {
                    container_number: this.containerNumber,
                    transaction_id: this.transactionId,
                    survey_id: this.currentSurveyId,
                },
            ).then(response => {
                this.jobOrderId = response.data.job_order_id;
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        },
        createJobOrder: function() {
            this.axios.post(
                '/api/create_job_order',
                {
                    transaction_id: this.transactionId,
                    survey_id: this.currentSurveyId,
                    container_number: this.containerNumber,
                    user_id: this.$store.state.user.id,
                }
            ).then(response => {
                // console.log(response.data);
                if(response.status === 200) {
                    // const payload = {
                    //     title: "New Job Order created!",
                    //     msg: "You have created a new job order successfully...",
                    // };
                    // this.$store.commit("showAlert", payload);
                    // location.href = '/job_orders';
                }
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
                this.loading = false;
            });
        }
    }
}
</script>
