<template>
  <v-app app>
    <v-main>
        <app-drawer v-if="$store.state.user.role != 'swipe' && $store.state.user.role != 'swipe2'"></app-drawer>
        <app-drawer-for-swipe v-if="$store.state.user.role == 'swipe'"></app-drawer-for-swipe>
        <app-drawer-for-swipe2 v-if="$store.state.user.role == 'swipe2'"></app-drawer-for-swipe2>
        <app-header></app-header>
        <app-footer></app-footer>
        <app-alert></app-alert>
        <router-view></router-view>
    </v-main>
  </v-app>
</template>
<script>
import Header from './components/Header.vue';
import Drawer from './components/Drawer.vue';
import DrawerForSwipe from './components/DrawerForSwipe.vue';
import DrawerForSwipe2 from './components/DrawerForSwipe2.vue';
import Footer from './components/Footer.vue';
import Alert from './components/Alert.vue';

export default {
    components: {
        'app-header': Header,
        'app-drawer': Drawer,
        'app-drawer-for-swipe': DrawerForSwipe,
        'app-drawer-for-swipe2': DrawerForSwipe2,
        'app-footer': Footer,
        'app-alert': Alert,
    }
}
</script>
