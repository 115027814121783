<template>
    <v-container>
        <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-card class="mx-auto px-2 pt-2">
            <v-toolbar flat dark class="indigo lighten-3">
                <v-toolbar-title>Holidays -- {{ title }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <v-sheet
                    tile
                    height="54"
                    class="d-flex"
                >
                    <v-btn
                    icon
                    class="ma-2"
                    @click="$refs.calendar.prev()"
                    >
                    <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                    icon
                    class="ma-2"
                    @click="$refs.calendar.next()"
                    >
                    <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                </v-sheet>
                <v-sheet height="600">
                    <v-calendar
                    ref="calendar"
                    v-model="value"
                    :weekdays="weekday"
                    :type="type"
                    :events="holidays"
                    :event-overlap-mode="mode"
                    :event-overlap-threshold="30"
                    @change="getEvents"
                    @mousedown:day="openEditHolidayDialog"
                    ></v-calendar>
                </v-sheet>
            </v-card-text>
        </v-card>
        <!-- editHolidayDialog start -->
        <v-dialog v-model="editHolidayDialog" fullscreen>
            <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
            <v-card flat>
                <v-container>
                    <v-toolbar flat dark class="indigo lighten-3">
                        <v-toolbar-title
                        >Edit Holidy -- {{ currentDate }}</v-toolbar-title
                        >
                        <v-spacer></v-spacer>
                        <v-btn
                            icon
                            dark
                            @click="closeEditHolidayDialog"
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <v-text-field
                            outlined
                            dense
                            label="Name"
                            placeholder="Name"
                            v-model="holidayName"
                        ></v-text-field>
                        <v-select
                            :items="holiday_kinds"
                            item-text="kind"
                            item-value="id"
                            outlined
                            dense
                            label="Holiday Kind"
                            placeholder="Holiday Kind"
                            v-model="holidayKind"
                        ></v-select>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="green"
                            dark
                            v-if="holidayName && holidayKind"
                            @click="saveHoliday"
                        >
                            <v-icon>mdi-content-save</v-icon>
                            Save
                        </v-btn>
                        <v-btn
                            color="red"
                            dark
                            v-if="holidayName && holidayKind"
                            @click="deleteHoliday"
                        >
                            <v-icon>mdi-delete</v-icon>
                            Delete
                        </v-btn>
                    </v-card-actions>
                </v-container>
            </v-card>
        </v-dialog>
        <!--editHolidayDialog end -->        
      </v-container>
  </template>
  <script>
  export default {
    data: () => ({
      overlay: false,
      editHolidayDialog: false,
      type: 'month',
      mode: 'stack',
      weekday: [0, 1, 2, 3, 4, 5, 6],
      value: '',
      holidays: [],
      currentDate: null,
      holidayName: null,
      title: null,
      holiday_kinds: [],
      holidayKind: null,
    }),
    mounted() {
        this.getHolidayKinds();
    },
    methods: {
      getEvents () {
        this.title = this.$refs.calendar.title;
        this.getHolidays();
      },
      getHolidays () {
        this.overlay = true;
          axios.post('/api/get_holidays')
            .then(response => {
                this.holidays = response.data;
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
            });
      },
      getHolidayKinds () {
        axios.post('/api/get_holiday_kinds')
            .then(response => {
                this.holiday_kinds = response.data;
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = false;
            });
      },
      getHoliday () {
        axios.post(
            '/api/get_holiday',
            {
                date: this.currentDate,
            }
        ).then(response => {
            this.holidayName = response.data.name;
            this.holidayKind = response.data.kind;
            // console.log(response.data)
        }).catch(error => {
            console.log(error);
        }).finally(() => {

        });
      },
      openEditHolidayDialog (e) {
        // console.log(e)
        this.editHolidayDialog = true;
        this.holidayName = null;
        this.holidayKind = null;
        this.currentDate = e.date;
        this.getHoliday();
      },
      closeEditHolidayDialog () {
        this.editHolidayDialog = false;
        this.holidayName = null;
      },
      saveHoliday () {
        if( !confirm('Is it ok to save the holiday?') ) {
            return false;
        }

        this.overlay = true;
        axios.post(
            '/api/save_holiday',
            {
                date: this.currentDate,
                name: this.holidayName,
                kind: this.holidayKind,
            }
        ).then(response => {
            // this.holidayName = response.data;
            // console.log(response.data)
        }).catch(error => {
            console.log(error);
        }).finally(() => {
            this.overlay = false;
            this.getHolidays();
            this.editHolidayDialog = false;
        });        
      },
      deleteHoliday () {
        if( !confirm('Is it ok to delete the holiday?') ) {
            return false;
        }
        this.overlay = true;
        axios.post(
            '/api/delete_holiday',
            {
                date: this.currentDate,
            }
        ).then(response => {
            // this.holidayName = response.data;
            // console.log(response.data)
        }).catch(error => {
            console.log(error);
        }).finally(() => {
            this.overlay = false;
            this.getHolidays();
            this.editHolidayDialog = false;
        });        
      }
    },
  }
</script>