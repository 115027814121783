<template>
    <v-container>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-card class="mx-auto px-2 pt-2">
            <v-toolbar flat dark class="indigo lighten-3">
                <v-toolbar-title>Transactions</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <v-data-table
                    :headers="headers"
                    :items="transactions"
                    :loading="loading"
                    hide-default-footer
                >
                    <template v-slot:top>
                        <v-tabs
                            v-model="tab"
                            center-active
                            dark
                        >
                            <v-tabs-slider color="yellow"></v-tabs-slider>
                            <v-tab
                                v-for="(remark, i) in remarks"
                                :key="i"
                            >
                            {{remark}}
                        </v-tab>
                        </v-tabs>
                        <v-text-field
                            class="my-2"
                            label="Search by transaction_id or container_number..."
                            prepend-inner-icon="mdi-magnify"
                            v-model="search"
                            outlined
                            dense
                            @keyup="searchByKeyword()"
                        ></v-text-field>
                        <!--Data Picker start-->
                        <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-if="$store.state.user.role == 'su' || $store.state.user.role == 'surveyor' || $store.state.user.role == 'admin'"
                                    v-model="dateRangeText"
                                    label="Date Rnage"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="dates = []"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="dates"
                                range
                            ></v-date-picker>
                        </v-menu>
                        <!--Data Picker finish-->
                        <p># of records: <span style="color: red; font-weight: bold;">{{totalNumberOfRecords}}</span></p>
                    </template>
                    <template v-slot:[`item.job_order_status`]="{ item }">
                        {{ jobOrderStatus(item) }}
                    </template>
                    <template v-slot:[`item.gate_in`]="{ item }">
                        <div v-if="item.job_order">
                            <div v-if="!item.job_order.gate_in_at">
                                <v-btn
                                    color="orange"
                                    dark
                                    small
                                    @click="gateIn(item)"
                                >
                                    <v-icon>mdi-boom-gate-arrow-up</v-icon> Gate In
                                </v-btn>
                            </div>
                            <div v-else>
                                {{item.job_order.gate_in_at}}
                            </div>
                        </div>
                    </template>
                    <template v-slot:[`item.pictures`]="{ item }">
                        <v-badge
                            color="red"
                            overlap
                            :content="item.pictures.length"
                        >
                        <v-icon
                            color="green"
                            size="35"
                            @click="openImagesDialog(item)"
                        >mdi-image</v-icon>
                        </v-badge>
                    </template>
                    <template v-slot:[`item.created_at`]="{ item }">
                        {{ item.created_at | formatDate }}
                    </template>
                </v-data-table>
                <div class="text-center pt-2">
                    <v-pagination
                        v-model="page"
                        :length="pageCount"
                        total-visible="10"
                        dark
                        @input="changePage()"
                    ></v-pagination>
                </div>
            </v-card-text>
        </v-card>

        <!-- imagesDialog start -->
        <v-dialog v-model="imagesDialog" fullscreen>
            <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
            <v-card flat>
                <v-container>
                    <v-toolbar flat dark class="indigo lighten-3">
                        <v-toolbar-title
                        >Pictures -- ContainerNumber: {{currentContainerNumber}} --</v-toolbar-title
                        >
                        <v-spacer></v-spacer>
                        <v-btn
                            icon
                            dark
                            @click="imagesDialog = false"
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <v-data-table
                            :headers="pictureHeaders"
                            :items="pictures"
                            :items-per-page="-1"
                        >
                            <template v-slot:[`item.view_pictures`]="{ item }">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon
                                            color="green"
                                            @click="downloadPicture(item)"
                                            large
                                            v-on="on"
                                        >mdi-image</v-icon>
                                    </template>
                                    <span>View Picture</span>
                                </v-tooltip>
                            </template>
                            <template v-slot:[`item.damage_pictures`]="{ item }">
                                <div v-if="item.damage_pictures.length > 0">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-badge
                                                color="red"
                                                overlap
                                                :content="item.damage_pictures.length"
                                            >
                                                <v-icon
                                                    color="blue"
                                                    @click="openDamagePicturesDialog(item)"
                                                    large
                                                    v-on="on"
                                                >mdi-image</v-icon>
                                            </v-badge>
                                        </template>
                                        <span>View Damage Picture</span>
                                    </v-tooltip>
                                </div>
                            </template>
                            <template v-slot:[`item.post_service_pictures`]="{ item }">
                                <div v-if="item.post_service_pictures.length > 0">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-badge
                                                color="red"
                                                overlap
                                                :content="item.post_service_pictures.length"
                                            >
                                                <v-icon
                                                    color="blue"
                                                    @click="openPostServicePicturesDialog(item)"
                                                    large
                                                    v-on="on"
                                                >mdi-image</v-icon>
                                            </v-badge>
                                        </template>
                                        <span>View Post Service Picture</span>
                                    </v-tooltip>
                                </div>
                            </template>
                            <template v-slot:[`item.created_at`]="{ item }">
                                {{ item.created_at | formatDate }}
                            </template>>
                        </v-data-table>
                    </v-card-text>
                </v-container>
            </v-card>
        </v-dialog>
        <!--imagesDialog end -->
        <!-- damagePicturesDialog start -->
        <v-dialog v-model="damagePicturesDialog" fullscreen>
            <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
            <v-card flat>
                <v-container>
                    <v-toolbar flat dark class="indigo lighten-3">
                        <v-toolbar-title
                        >Damage Pictures -- ContainerNumber: {{currentContainerNumber}} --</v-toolbar-title
                        >
                        <v-spacer></v-spacer>
                        <v-btn
                            icon
                            dark
                            @click="damagePicturesDialog = false"
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <v-data-table
                            :headers="damagePictureHeaders"
                            :items="damagePictures"
                            :items-per-page="-1"
                        >
                            <template v-slot:[`item.download_damage_picture`]="{ item }">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon
                                            color="green"
                                            @click="downloadDamagePicture(item)"
                                            large
                                            v-on="on"
                                        >mdi-image</v-icon>
                                    </template>
                                    <span>View Picture</span>
                                </v-tooltip>
                            </template>
                            <template v-slot:[`item.damage_pictures`]="{ item }">
                                <div v-if="item.damage_pictures.length > 0">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-badge
                                                color="red"
                                                overlap
                                                :content="item.damage_pictures.length"
                                            >
                                                <v-icon
                                                    color="blue"
                                                    @click="openDamagePicturesDialog(item)"
                                                    large
                                                    v-on="on"
                                                >mdi-image</v-icon>
                                            </v-badge>
                                        </template>
                                        <span>View Damage Picture</span>
                                    </v-tooltip>
                                </div>
                            </template>
                            <template v-slot:[`item.created_at`]="{ item }">
                                {{ item.created_at | formatDate }}
                            </template>>
                        </v-data-table>
                    </v-card-text>
                </v-container>
            </v-card>
        </v-dialog>
        <!--damagePicturesDialog end -->
        <!-- postServicePicturesDialog start -->
        <v-dialog v-model="postServicePicturesDialog" fullscreen>
            <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
            <v-card flat>
                <v-container>
                    <v-toolbar flat dark class="indigo lighten-3">
                        <v-toolbar-title
                        >Post Service Pictures -- ContainerNumber: {{currentContainerNumber}} --</v-toolbar-title
                        >
                        <v-spacer></v-spacer>
                        <v-btn
                            icon
                            dark
                            @click="postServicePicturesDialog = false"
                          >
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                    </v-toolbar>
                    <v-card-text>
                        <v-data-table
                            :headers="postServicePictureHeaders"
                            :items="postServicePictures"
                            :items-per-page="-1"
                        >
                            <template v-slot:[`item.download_post_service_picture`]="{ item }">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon
                                            color="green"
                                            @click="downloadPostServicePicture(item)"
                                            large
                                            v-on="on"
                                        >mdi-image</v-icon>
                                    </template>
                                    <span>View Picture</span>
                                </v-tooltip>
                            </template>
                            <template v-slot:[`item.created_at`]="{ item }">
                                {{ item.created_at | formatDate }}
                            </template>>
                        </v-data-table>
                    </v-card-text>
                </v-container>
            </v-card>
        </v-dialog>
        <!--damagePicturesDialog end -->
    </v-container>
</template>
<script>
import moment from "moment";

export default {
    data() {
        return {
            tab: 0,
            search: null,
            overlay: false,
            loading: false,
            imagesDialog: false,
            pageCount: 0,
            page: 1,
            itemsPerPage: 30,
            totalNumberOfRecords: null,
            menu: false,
            dates: [
                `${moment().format('YYYY')}-${moment().format('MM')}-01`,
                `${moment().format('YYYY')}-${moment().format('MM')}-${moment().daysInMonth()}`
            ],
            headers: [
                {
                    text: "TransactionId",
                    value: "transaction_id",
                    sortable: false,
                },
                {
                    text: "Container#",
                    value: "container_number",
                    sortable: false,
                },
                {
                    text: "CompanyName",
                    value: "company.name",
                    sortable: false,
                },
                {
                    text: "Remark",
                    value: "remark.remark_name",
                    sortable: false,
                },
                {
                    text: "Destination",
                    value: "destination.destination_type",
                    sortable: false,
                },
                {
                    text: "SurveyStatus",
                    value: "survey.status_code",
                    sortable: false,
                },
                {
                    text: "JobOrderStatus",
                    value: "job_order_status",
                    sortable: false,
                },
                {
                    text: "Pictures",
                    value: "pictures",
                    sortable: false,
                },
                {
                    text: "CreatedBy",
                    value: "user.name",
                    sortable: false,
                },
                {
                    text: "CreatedAt",
                    value: "created_at",
                    sortable: false,
                },
            ],
            headersForNonAdmin: [
                {
                    text: "TransactionId",
                    value: "transaction_id",
                    sortable: false,
                },
                {
                    text: "Container#",
                    value: "container_number",
                    sortable: false,
                },
                {
                    text: "CompanyName",
                    value: "company.name",
                    sortable: false,
                },
                {
                    text: "Remark",
                    value: "remark.remark_name",
                    sortable: false,
                },
                {
                    text: "Destination",
                    value: "destination.destination_type",
                    sortable: false,
                },
                {
                    text: "SurveyStatus",
                    value: "survey.status_code",
                    sortable: false,
                },
                {
                    text: "JobOrderStatus",
                    value: "job_order_status",
                    sortable: false,
                },
                {
                    text: "GateIn",
                    value: "gate_in",
                    sortable: false,
                },
                {
                    text: "Pictures",
                    value: "pictures",
                    sortable: false,
                },
                {
                    text: "CreatedBy",
                    value: "user.name",
                    sortable: false,
                },
                {
                    text: "CreatedAt",
                    value: "created_at",
                    sortable: false,
                },
            ],
            transactions: [],
            currentItem: null,
            currentContainerNumber: null,
            currentTransactionId: null,
            pictureHeaders: [
                {
                    text: "FileName",
                    value: "file_name",
                    sortable: true,
                },
                {
                    text: "Status",
                    value: "status",
                    sortable: true,
                },
                {
                    text: "CreatedAt",
                    value: "created_at",
                    sortable: true,
                },
                {
                    text: "ViewPictures",
                    value: "view_pictures",
                    sortable: true,
                },
                {
                    text: "DamagePictures",
                    value: "damage_pictures",
                    sortable: true,
                },
                {
                    text: "PostServicePictures",
                    value: "post_service_pictures",
                    sortable: true,
                },
            ],
            pictures: [],
            statuses: [],
            remarks: ['PASS', 'REJECTED', 'EMPTYPOOL'],
            damagePicturesDialog: false,
            damagePictureHeaders: [
                {
                    text: "FileName",
                    value: "file_name",
                    sortable: true,
                },
                {
                    text: "Download Damage Picture",
                    value: "download_damage_picture",
                    sortable: true,
                },
                {
                    text: "CreatedAt",
                    value: "created_at",
                    sortable: true,
                },
            ],
            damagePictures: [],
            postServicePictures: [],
            postServicePicturesDialog: false,
            postServicePictureHeaders: [
                {
                    text: "FileName",
                    value: "file_name",
                    sortable: true,
                },
                {
                    text: "Download Post Service Picture",
                    value: "download_post_service_picture",
                    sortable: true,
                },
                {
                    text: "CreatedAt",
                    value: "created_at",
                    sortable: true,
                },
            ],
        }
    },
    mounted() {
        if( this.$store.state.user.role == 'surveyor' || this.$store.state.user.role == 'user'|| this.$store.state.user.role == 'qronly' || this.$store.state.user.role == 'hrassistant'  ) {
            alert('You have not access right to enter the area...');
            this.$router.push('/');
        }
        if( this.$route.params.transaction_id && this.$route.params.tab ) {
            this.search = this.$route.params.transaction_id;
            this.tab = parseInt(this.$route.params.tab);
        } else {
            this.search = null;
            this.tab = 0;
        }

        if( this.$store.state.user.role !== 'admin' ) {
            this.headers = this.headersForNonAdmin;
        }

        this.loadTransactions(this.tab);
        this.getStatuses();
        // this.getRemarks();


    },
    methods: {
        loadTransactions: function(tab) {
            this.overlay = true;
            this.loading = true;
            this.axios.post(
                '/api/get_transactions',
                {
                    user: this.$store.state.user,
                    page: this.page,
                    itemsPerPage: this.itemsPerPage,
                    search: this.search,
                    tab: tab,
                    dates: this.dates,
                }
            ).then(response => {
                    // console.log(response.data);
                    if(response.status === 200) {
                        this.transactions = response.data.data;
                        this.totalNumberOfRecords = response.data.total;
                        this.pageCount = Math.ceil(response.data.total / this.itemsPerPage);
                    } else {
                        this.$store.commit('logOut');
                    }

                }).catch(error => {
                    console.log(error);
                    this.$store.commit('logOut');
                }).finally(() => {
                    this.overlay = false;
                    this.loading = false;
                });
        },
        getStatuses: function() {
            this.axios.post('/api/get_statuses')
                .then(response => {
                    this.statuses = response.data;
                }).catch(error => {
                    console.log(error);
                });
        },
        gateIn: function(item) {
            this.overlay = true;
            this.loading = true;

            this.axios.post(
                '/api/gate_in',
                {
                    job_order_id: item.job_order_id
                }
            ).then(response => {
                this.loadTransactions(this.tab);
            }).catch(error => {
                console.log(error);
            }).finally(() => {
                this.overlay = true;
                this.loading = true;
            });
        },
        // getRemarks: function() {
        //     this.axios.post('/api/get_remarks')
        //         .then(response => {
        //             this.remarks = response.data;
        //         }).catch(error => {
        //             console.log(error);
        //         });
        // },
        openImagesDialog: function(item) {
            // console.log(item)
            this.imagesDialog = true;
            this.currentItem = item;
            this.currentContainerNumber = this.currentItem.container_number;
            this.currentTransactionId = this.currentItem.transaction_id;
            this.pictures = this.currentItem.pictures;
        },
        downloadPicture: function (item) {
        this.overlay = true;
        axios
            .post(
            "/api/download_picture",
            {
                container_number: item.container_number,
                transaction_id: item.transaction_id,
                name: item.file_name,
            },
            {
                responseType: "blob",
            }
            )
            .then((response) => {
            this.overlay = false;
            const fileURL = window.URL.createObjectURL(
                new Blob([response.data], { type: "image/png" })
            );
            const fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.target = "_blank";
            document.body.appendChild(fileLink);
            fileLink.click();
            document.body.removeChild(fileLink);
            });
        },
        downloadDamagePicture: function (item) {
        this.overlay = true;
        axios
            .post(
            "/api/download_damage_picture",
            {
                container_number: this.currentContainerNumber,
                transaction_id: this.currentTransactionId,
                name: item.file_name,
            },
            {
                responseType: "blob",
            }
            )
            .then((response) => {
            this.overlay = false;
            const fileURL = window.URL.createObjectURL(
                new Blob([response.data], { type: "image/png" })
            );
            const fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.target = "_blank";
            document.body.appendChild(fileLink);
            fileLink.click();
            document.body.removeChild(fileLink);
            });
        },
        downloadPostServicePicture: function (item) {
        this.overlay = true;
        axios
            .post(
            "/api/download_post_service_picture",
            {
                container_number: this.currentContainerNumber,
                transaction_id: this.currentTransactionId,
                name: item.file_name,
            },
            {
                responseType: "blob",
            }
            )
            .then((response) => {
            this.overlay = false;
            const fileURL = window.URL.createObjectURL(
                new Blob([response.data], { type: "image/png" })
            );
            const fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.target = "_blank";
            document.body.appendChild(fileLink);
            fileLink.click();
            document.body.removeChild(fileLink);
            });
        },
        openDamagePicturesDialog: function(item) {
            // console.log(item)
            this.damagePicturesDialog = true;
            this.damagePictures = item.damage_pictures;
            this.postServicePictures = item.post_service_pictures;
        },
        openPostServicePicturesDialog: function(item) {
            // console.log(item)
            this.postServicePicturesDialog = true;
            this.postServicePictures = item.post_service_pictures;
        },
        jobOrderStatus: function(item) {
            if(item.job_order) {


                if(
                    !item.job_order.gate_in_at
                    &&
                    !item.job_order.job_started_at
                    &&
                    !item.job_order.job_closed_at
                    &&
                    !item.job_order.gate_out_at
                ) {
                    return '-';
                } else if(
                    item.job_order.gate_in_at
                    &&
                    !item.job_order.job_started_at
                    &&
                    !item.job_order.job_closed_at
                    &&
                    !item.job_order.gate_out_at
                ) {
                    return 'Gate In';
                } else if(
                    item.job_order.gate_in_at
                    &&
                    item.job_order.job_started_at
                    &&
                    !item.job_order.job_closed_at
                    &&
                    !item.job_order.gate_out_at
                ) {
                    return 'Job Started';
                } else if(
                    item.job_order.gate_in_at
                    &&
                    item.job_order.job_started_at
                    &&
                    item.job_order.job_closed_at
                    &&
                    !item.job_order.gate_out_at
                ) {
                    return 'Job Closed';
                } else if(
                    item.job_order.gate_in_at
                    &&
                    item.job_order.job_started_at
                    &&
                    item.job_order.job_closed_at
                    &&
                    item.job_order.gate_out_at
                ) {
                    return 'Gate Out';
                }
            } else {
                return '-';
            }
        },
        changePage: function() {
            this.loadTransactions(this.tab);
        },
        searchByKeyword: function() {
            this.page = 1;
            this.loadTransactions(this.tab);
        },
    },
    filters: {
        formatDate: function (date) {
            return date !== null ? moment(date).format("MM-DD-YYYY hh:mm:ss A") : '';
        },
    },
    watch: {
        tab: function() {
            this.loadTransactions(this.tab);
        },
        dates: function() {
            if (this.dates.length == 0 && this.dates.length == 1) {
                return false;
            } else if (this.dates.length == 2) {
                if ( this.dates[0] > this.dates[1] ) {
                    alert('Invalid date selection');
                    this.dates = [];
                    return false;
                } else {
                    this.page = 1;
                    this.loadTransactions(this.tab);
                }
            }
        }
    },
    computed: {
        dateRangeText() {
            return this.dates.join(' ~ ');
        }
    },

}
</script>
