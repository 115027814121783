import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex);

export default new Vuex.Store({
    plugins: [
        createPersistedState({
            key: 'example',
            storage: localStorage
        })],
    state: {
        isLogged: false,
        drawer: false,
        access_token: null,
        user: {
            id: null,
            company_id: null,
            name: null,
            email: null,
            role: null,
            is_wfh: null,
        },
        currentUser: {
            id: null,
            company_id: null,
            name: null,
            email: null,
            role: null,
            is_wfh: null,
        },
        currentAttendanceDataItems: null,
        alert: {
            dialog: false,
            title: '',
            msg: ''
        },
    },
    mutations: {
        toggleDrawer(state) {
            state.drawer = !state.drawer;
        },
        assignAccessToken(state, token) {
            state.access_token = token;
        },
        logIn(state) {
            state.isLogged = true;
        },
        logOut(state) {
            state.isLogged = false;
            state.access_token = null;
            state.user.id = null;
            state.user.company_id = null;
            state.user.email = null;
            state.user.name = null;
            state.user.role = null;
        },
        showAlert(state, payload) {
            state.alert.dialog = true;
            state.alert.title = payload.title;
            state.alert.msg = payload.msg;
        },
        hideAlert(state) {
            state.alert.dialog = false;
        },
        assignUserInfo(state, user) {
            state.user = user;
        },
        assignCurrentUserInfo(state, currentUser) {
            state.currentUser = currentUser;
        },
        assignCurrentAttendanceDataItems(state, items) {
            state.currentAttendanceDataItems = items;
        }
    }
});
